import EventHelper from '../../Core/helper/EventHelper.js';
import EventResize from '../../SchedulerPro/feature/EventResize.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import TransactionalFeature from '../../Scheduler/feature/mixin/TransactionalFeature.js';
/**
 * @module Gantt/feature/TaskResize
 */
/**
 * An object containing data related to task resize.
 * @typedef TaskResizeData
 * @property {Gantt.model.TaskModel} taskRecord Task record being resized
 * @property {Date} startDate New startDate (changed if resizing start side)
 * @property {Date} endDate New endDate (changed if resizing end side)
 * @property {Date} originalStartDate Start date before resize
 * @property {Date} originalEndDate End date before resize
 * @property {HTMLElement} [element] The task element
 */
/**
 * Feature that allows resizing a task by dragging its end date. Resizing a task by dragging its start date is not allowed.
 *
 * This feature is **enabled** by default
 *
 * This feature updates the event's `endDate` live in order to leverage the
 * rendering pathway to always yield a correct appearance. The changes are done in
 * {@link Core.data.Model#function-beginBatch batched} mode so that changes do not become
 * eligible for data synchronization or propagation until the operation is completed.
 *
 * ## Customizing the resize tooltip
 *
 * To show custom HTML in the tooltip, please see the {@link #config-tooltipTemplate} config. Example:
 *
 * ```javascript
 * taskResize : {
 *     // A minimal end date tooltip
 *     tooltipTemplate : ({ record, endDate }) => {
 *         return DateHelper.format(endDate, 'MMM D');
 *     }
 * }
 * ```
 *
 * @extends SchedulerPro/feature/EventResize
 * @demo Gantt/basic
 * @classtype taskResize
 * @feature
 */
export default class TaskResize extends TransactionalFeature(EventResize) {
    static $name = 'TaskResize';
    static get configurable() {
        return {
            draggingItemCls : 'b-sch-event-resizing',
            resizingItemInnerCls : null,
            /**
             * Gets or sets special key to activate successor pinning behavior. Supported values are:
             * * 'ctrl'
             * * 'shift'
             * * 'alt'
             * * 'meta'
             *
             * Assign false to disable it.
             * @member {Boolean|String} pinSuccessors
             */
            /**
             * Set to `true` to enable resizing task while pinning dependent tasks. By default, this behavior is activated
             * if you hold CTRL key during drag. Alternatively, you may provide key name to use. Supported values are:
             * * 'ctrl'
             * * 'shift'
             * * 'alt'
             * * 'meta'
             *
             * **Note**: Only supported in forward-scheduled project
             *
             * @config {Boolean|String}
             * @default
             */
            pinSuccessors : false
        };
    }
    static get pluginConfig() {
        return {
            chain : ['render', 'onEventDataGenerated', 'isTaskElementDraggable']
        };
    }
    onDragItemMouseMove() {
        // internalUpdateRecord is based on the assumption only taskbar end edge can be resized
        this[`${this.client.rtl ? 'right' : 'left'}Handle`] = false;
        super.onDragItemMouseMove(...arguments);
    }
    changePinSuccessors(value) {
        return EventHelper.toSpecialKey(value);
    }
    //region Events
    /**
     * @hideevents beforeEventResize, eventResizeStart, eventPartialResize, beforeEventResizeFinalize, eventResizeEnd
     */
    /**
     * Fires on the owning Gantt before resizing starts. Return `false` to prevent the operation.
     * @event beforeTaskResize
     * @preventable
     * @on-owner
     * @param {Gantt.model.TaskModel} taskRecord
     * @param {Event} event
     */
    /**
     * Fires on the owning Gantt when task resizing starts
     * @event taskResizeStart
     * @on-owner
     * @param {Gantt.model.TaskModel} taskRecord
     * @param {Event} event
     * @param {TaskResizeData[]} resizeData Resize data for selected tasks. Only available when
     * {@link #config-resizeSelected} is `true`
     */
    /**
     * Fires on the owning Gantt on each resize move event
     * @event taskPartialResize
     * @on-owner
     * @param {TaskResizeData[]} resizeData Data for the tasks that are being resized. Only available when
     * {@link #config-resizeSelected} is `true`. The nested properties are same as `context`
     * @param {Gantt.model.TaskModel} taskRecord Task record being resized
     * @param {Date} startDate New startDate
     * @param {Date} endDate New endDate
     * @param {HTMLElement} element
     */
    /**
     * Fires on the owning Gantt to allow implementer to prevent immediate finalization by setting
     * `data.context.async = true` in the listener, to show a confirmation popup etc.
     *
     * ```javascript
     *  gantt.on('beforeTaskResizeFinalize', ({context}) => {
     *      context.async = true;
     *      setTimeout(() => {
     *          // async code don't forget to call finalize
     *          context.finalize();
     *      }, 1000);
     *  })
     * ```
     *
     * @event beforeTaskResizeFinalize
     * @on-owner
     * @param {Gantt.model.TaskModel} taskRecord
     * @param {Gantt.view.Gantt} source Gantt instance
     * @param {Object} context
     * @param {Date} context.endDate New endDate
     * @param {Date} context.originalEndDate End date before resize
     * @param {TaskResizeData[]} context.resizeData Resize data for selected tasks. Only available when
     * {@link #config-resizeSelected} is `true`.
     * @param {Event} event Browser event
     * @param {Boolean} context.async Set true to handle resize asynchronously (e.g. to wait for user
     * confirmation)
     * @param {Function} context.finalize Call this method to finalize resize. This method accepts one
     * argument: pass true to update records, or false, to ignore changes
    */
    /**
     * Fires on the owning Gantt after the resizing gesture has finished.
     * @event taskResizeEnd
     * @on-owner
     * @param {Boolean} changed
     * @param {Gantt.model.TaskModel} taskRecord
     * @param {TaskResizeData[]} resizeData Resize data for selected tasks. Only available when
     * {@link #config-resizeSelected} is `true`
     */
    //endregion
    //region Gantt specifics
    isTaskElementDraggable(eventElement, eventRecord, el, event) {
        return this.isEventElementDraggable(...arguments);
    }
    checkValidity() {
        // Task resize just does basic validity checks which runs the validatorFn
        return this.basicValidityCheck(...arguments);
    }
    getBeforeResizeParams(context) {
        return {};
    }
    get selectedRecords() {
        return this.client.selectedRecords;
    }
    isRecordSelected(record) {
        return this.client.isSelected(record);
    }
    getSelectedRecordElement(record) {
        return this.client.getElementFromTaskRecord(record);
    }
    // Injects Gantt specific data into the drag context
    setupProductResizeContext(context, event) {
        const
            gantt      = this.client,
            taskRecord = gantt.resolveTaskRecord(context.element);
        Object.assign(context, {
            taskRecord,
            eventRecord     : taskRecord,
            dateConstraints : gantt.getDateConstraints?.(taskRecord)
        });
    }
    adjustRecordBeforeUpdating(context, timespanRecord) {
        const
            { startDate, endDate } = context,
            { toSet }              = context.mainContext,
            data                   = { endDate };
        // Fix the duration according to the Entity's rules.
        context.duration = data.duration = timespanRecord.run('calculateProjectedDuration', startDate, endDate);
        // Fix the dragged date point according to the Entity's rules.
        data[toSet] = context[toSet] = timespanRecord.run('calculateProjectedXDateWithDuration', startDate, true, context.duration);
        // Update the record to its final correct state using *batched changes*
        // These will *not* be propagated, it's just to force the dragged event bar
        // into its corrected shape before the real changes which will propagate are applied below.
        // We MUST do it like this because the final state may not be a net change if the changes
        // got rejected, and in that case, the engine will not end up firing any change events.
        timespanRecord.set(data);
    }
    async internalUpdateRecord(context, timespanRecord) {
        const { generation } = timespanRecord;
        this.cancelEventRecordBatch(timespanRecord);
        if (this.pinSuccessors && context.mainContext.event[this.pinSuccessors]) {
            await timespanRecord.setDurationPinningSuccessors(context);
        }
        else {
            await timespanRecord.setDuration(context.duration);
        }
        timespanRecord.endBatch();
        // If the record has been changed
        return timespanRecord.generation !== generation;
    }
    //endregion
    //#region
    triggerEventResizeStart(eventType, event, context) {
        super.triggerEventResizeStart(eventType, event, context);
        return this.startFeatureTransaction();
    }
    triggerEventResizeEnd(eventType, event) {
        super.triggerEventResizeEnd(eventType, event);
        if (event.changed) {
            this.finishFeatureTransaction();
        }
        else {
            this.rejectFeatureTransaction();
        }
    }
    //#endregion
}
TaskResize._$name = 'TaskResize'; GridFeatureManager.registerFeature(TaskResize, true, 'Gantt');
