/**
 * @module Grid/feature/export/mixin/PrintMixin
 */
/**
 * Mixin implementing print functionality to PdfExport feature.
 * @mixin
 */
export default Target => class PrintMixin extends Target {
    static $name = 'PrintMixin'; ;
    static get configurable() {
        return {
            useBrowserPrint : true,
            exporterType : 'multipagevertical',
            // This is a private config to make print only render content to an iframe for testing purposes
            test : false
        };
    }
    static get pluginConfig() {
        return {
            assign : ['print', 'showPrintDialog']
        };
    }
    /**
     * Shows the {@link Grid.view.export.ExportDialog print dialog}
     * @returns {Promise}
     * @on-owner
     * @category Print
     */
    showPrintDialog() {
        return this.showExportDialog();
    }
    /**
     * Starts the print process. Accepts a config object which overrides any default configs.
     * **NOTE** Component should not be interacted with when print is in progress
     *
     * @param {Object} config
     * @returns {Promise} Promise which resolves when printing is done. Optionally it might return an object with an
     * `error` key in it.
     * @on-owner
     * @category Print
     */
    print(config) {
        return this.export(config);
    }
    /**
     * This method is called when IFrame is loaded with all the HTML/CSS and is about to be printed. Use it to take
     * control over the page contents.
     * @param {HTMLIFrameElement} iframe
     */
    async onPrintIFrameLoad(iframe) {}
    async showBrowserPrintDialog(iframe, resolve) {
        await this.onPrintIFrameLoad(iframe);
        // This one is a private event to block print API in tests
        if (await this.client.trigger('beforeShowPrintDialog', { iframe }) !== false) {
            if (!this.test) {
                const { contentWindow } = iframe;
                // Clean up iframe after print
                contentWindow.onafterprint = () => {
                    iframe.remove();
                    resolve();
                };
                contentWindow.print();
            }
        }
        else {
            iframe.remove();
            resolve();
        }
    }
    doPrint(pages) {
        const me = this;
        return new Promise((resolve, reject) => {
            const iframe = document.createElement('iframe');
            iframe.className = 'b-print-wrapper';
            // We don't need to see this iframe
            iframe.style.visibility = 'hidden';
            iframe.style.height = '0';
            iframe.onload = () => {
                const
                    handle   = iframe.contentWindow,
                    doc      = handle.document,
                    { body } = doc,
                    parser   = new DOMParser();
                let paperHeight;
                pages.forEach(({ html }) => {
                    const
                        fragment = parser.parseFromString(html, 'text/html'),
                        node     = doc.adoptNode(fragment.body.firstChild),
                        pageWrap = doc.createElement('div');
                    fragment.head.querySelectorAll('style,link[rel="stylesheet"],link[as="style"]').forEach(styleEl => {
                        const el = doc.adoptNode(styleEl);
                        doc.head.appendChild(el);
                    });
                    pageWrap.classList.add('b-page-wrap');
                    pageWrap.style.height = fragment.body.parentElement.style.height;
                    pageWrap.style.width = fragment.body.parentElement.style.width;
                    paperHeight = parseFloat(fragment.body.parentElement.style.height);
                    body.appendChild(pageWrap);
                    pageWrap.appendChild(node);
                });
                const { html } = pages[0];
                // DomParser is Salesforce ignores body classes which are required
                body.className = html.match(/<body class="(.+?)"/)?.[1] || '';
                body.classList.add('b-print');
                body.parentElement.classList.add('b-print-root');
                body.parentElement.style.height = `${paperHeight * pages.length}in`;
                Promise.all(Array.from(doc.head.querySelectorAll('link[rel="stylesheet"]')).map(link => {
                    return new Promise((resolve, reject) => {
                        link.onload = resolve;
                        link.onerror = reject;
                    });
                }))
                    // When we added `<link>` elements to the IFrame we may have started loading the font too.
                    // Normally we do, because our theme uses FontAwesome for icons. This promise will always
                    // be resolved at some point: https://drafts.csswg.org/css-font-loading/#font-face-set-ready
                    // So we can be sure that control which we pass to the browser print dialog is not lost.
                    .then(() => doc.fonts.ready)
                    .then(() => me.showBrowserPrintDialog(iframe, resolve))
                    .catch(e => {
                        console.warn(`Failed to load stylesheets ${e.message ? `: ${e.message}` : ''}`);
                        reject(me.L('L{PdfExport.Export failed}'));
                    });
            };
            me.client.element.parentElement.appendChild(iframe);
        });
    }
};
