import GlobalEvents from '../GlobalEvents.js';
import DH from '../helper/DateHelper.js';
import DomSync from '../helper/DomSync.js';
import VersionHelper from '../helper/VersionHelper.js';
import PickerField from './PickerField.js';
import TimePicker from './TimePicker.js';
/**
 * @module Core/widget/TimeField
 */
const
    numbers     = /\d+/,
    delimiterRe = /\d+(\D)\d+/,
    names       = ['hour', 'minute', 'second'],
    magnitudes  = [3600, 60, 1],
    getMethod   = [
        'getHours',
        'getMinutes',
        'getSeconds'
    ],
    styles      = `
        :host {
            display:flex
        }
        * {
            font-size:inherit;
            font-family:inherit;
            color:inherit;
            background-color:inherit
        }
        input,button {
            padding:0;
            margin:0;
            border:0 none;
            outline:0 none;
            background-color:var(--timefield-input-background-color);
            text-align:center
        }
        input {
            width:2ch;
        }
        #am-pm {
            margin-inline:5px
        }
        #am-pm:focus-visible {
            outline:none;
            background-color:var(--timefield-button-background-color)
        }`;
class TimeInput extends HTMLElement {
    static formAssociated = true;
    static observedAttributes = [
        'min', 'max', 'disabled', 'tabindex'
    ];
    constructor() {
        super();
        const
            me   = this,
            root = me._root = me.attachShadow({
                mode           : 'open',
                delegatesFocus : true
            });
        me.internals_ = me.attachInternals();
        me.byRef = Object.create(null);
        root.addEventListener('keydown', me.onKeyDown.bind(me));
        root.addEventListener('focusin', me.onFocusIn.bind(me));
        root.addEventListener('click', me.onClick.bind(me));
        root.addEventListener('input', me.onInput.bind(me));
        root.addEventListener('focusout', me.onFocusOut.bind(me));
    }
    connectedCallback() {
        const me = this;
        // Do not fire a change event
        me.silentChangeValue = true;
        me.owner = me.elementData?.owner;
        me.createStructure(me.owner.format);
        me.updateValue(me._value);
        me.silentChangeValue = false;
    }
    ingestDate(d) {
        // Ingest Dates and Date.toString strings
        let r = new Date(d);
        if (isNaN(r)) {
            r = DH.parse(d, this.owner.format);
        }
        return r;
    }
    get is24Hour() {
        return this.owner.is24Hour;
    }
    attributeChangedCallback(name, oldValue, value) {
        const me = this;
        switch (name) {
            case 'min':
                me.updateMin(value);
                break;
            case 'max':
                me.updateMax(value);
                break;
            case 'label':
                me._label = value;
                me.inputs?.forEach(e => e.setAttribute('aria-label', value));
                break;
            case 'disabled':
                me.disabled = value;
                break;
            case 'tabindex':
                me.tabIndex = Number(value);
                break;
        }
    }
    updateMin(min) {
        const d = min == null ? null : DH.getTime(this.ingestDate(min));
        this.minValue = d;
        this.minHour = d?.getHours() || 0;
    }
    updateMax(max) {
        const d = max == null ? null : DH.getTime(this.ingestDate(max));
        this.maxValue = d;
        this.maxHour = d?.getHours() || (this.is24Hour ? 23 : 11);
    }
    /**
     * TimeFields always focus the first (Hours) subfield when focused
     */
    onFocusIn(event) {
        const { target } = event;
        // Empty subfield becomes selected 00
        if (target.tagName === 'INPUT') {
            target.value = `00${target.value}`.slice(-2);
            target.setSelectionRange?.(0, 2);
        }
        if (!this._root.contains(event.relatedTarget)) {
            // If we TABBED in, always go to the first subfield
            if (!GlobalEvents.isMouseDown()) {
                this.focusField(0);
            }
        }
        event.stopImmediatePropagation();
    }
    onClick(event) {
        const
            { target } = event,
            { owner }  = this;
        // Click on AM/PM button toggles it
        if (target === this.ampmButton) {
            if (!owner.readOnly) {
                target.innerText = target.innerText === DH.amIndicator ? DH.pmIndicator : DH.amIndicator;
                this.onValueMutated();
                event.stopPropagation();
            }
        }
    }
    onFocusOut({ target }) {
        this.onValueMutated();
        // Keep all inut fields left-padded with zero
        if (!this.isCleared && target !== this.ampmButton) {
            target.value = `0${parseInt(target.value)}`.slice(-2);
        };
    }
    onKeyDown(keyEvent) {
        const
            me              = this,
            {
                subfieldIndex
            }               = me,
            {
                editable,
                readOnly
            }               = me.owner,
            {
                amIndicator,
                pmIndicator
            }               = DH,
            { target, key } = keyEvent;
        // We have no actions, and should allow through all control keys (Except shift+TAB and SHIFT+single character keys)
        if (keyEvent.ctrlKey || keyEvent.cmdKey || keyEvent.metaKey || (keyEvent.shiftKey && key !== 'Tab') && key.length !== 1) {
            return;
        }
        switch (key) {
            // Move out on TAB
            case 'Tab':
                me.inputs[keyEvent.shiftKey ? 0 : me.inputs.length - 1].focus();
                break;
            case ' ':
                if (target === me.ampmButton || readOnly) {
                    keyEvent.stopImmediatePropagation();
                }
                break;
            case 'ArrowLeft':           // eslint-disable-line no-fallthrough
                keyEvent.preventDefault();
                keyEvent.stopPropagation();
                if (subfieldIndex > 0 && !keyEvent.altKe) {
                    me.focusField(subfieldIndex - 1);
                }
                break;
            case ':':
                if (target === me.ampmButton) {
                    keyEvent.preventDefault();
                    break;
                }
            case 'ArrowRight':          // eslint-disable-line no-fallthrough
                keyEvent.preventDefault();
                keyEvent.stopPropagation();
                if (subfieldIndex < me.inputs.length - 1) {
                    me.focusField(subfieldIndex + 1);
                }
                break;
            case 'ArrowUp':
                keyEvent.preventDefault();
                keyEvent.stopPropagation();
                if (!readOnly) {
                    me[me.owner.pickerVisible ? 'decrementSubfield' : 'incrementSubfield'](target);
                }
                break;
            case 'ArrowDown':
                keyEvent.preventDefault();
                keyEvent.stopPropagation();
                if (!readOnly) {
                    me[me.owner.pickerVisible ? 'incrementSubfield' : 'decrementSubfield'](target);
                }
                break;
            // Allow numbers through
            case '0':
            case '1':                   // eslint-disable-line no-fallthrough
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if (!editable) {
                    keyEvent.preventDefault();
                }
                break;
            // Allow escape and erase
            case 'Backspace':           // eslint-disable-line no-fallthrough
            case 'Delete':
                if (!editable) {
                    keyEvent.preventDefault();
                }
                break;
            case 'Escape':              // eslint-disable-line no-fallthrough
                break;
            // Type a/A to switch to AM
            case amIndicator?.[0]?.toLocaleLowerCase():            // eslint-disable-line no-fallthrough
            case amIndicator?.[0]?.toLocaleUpperCase():            // eslint-disable-line no-fallthrough
                if (!readOnly && !me.is24Hour) {
                    keyEvent.preventDefault();
                    me.ampmButton.innerText = amIndicator;
                    me.onValueMutated();
                    break;
                }
            // Type p/P to switch to PM
            case pmIndicator?.[0]?.toLocaleLowerCase():            // eslint-disable-line no-fallthrough
            case pmIndicator?.[0]?.toLocaleUpperCase():            // eslint-disable-line no-fallthrough
                keyEvent.preventDefault();
                if (!readOnly && !me.is24Hour) {
                    keyEvent.preventDefault();
                    me.ampmButton.innerText = pmIndicator;
                    me.onValueMutated();
                    break;
                }
            default:                    // eslint-disable-line no-fallthrough
                keyEvent.preventDefault();
        }
    }
    onInput({ target }) {
        const
            me        = this,
            { value } = target;
        // The first numeral typed should not mutate it.
        // The next typed numeral or blur will complete the field value.
        if (value.length === 1 || (value.length === 2 && target.selectionStart === 1 && target.selectionEnd === 1)) {
            target.setSelectionRange(1, 2);
            return;
        }
        // Clear means set to 00
        if (!value) {
            target.value = '00';
            target.setSelectionRange(0, 2);
        }
        // If we just typed into the last character slot, move on
        else if (target.selectionEnd === 2) {
            const { subfieldIndex } = this;
            if (subfieldIndex < me.inputs.length - 1) {
                me.focusField(subfieldIndex + 1);
            }
        }
        // Typing 00 into the hour field of a 12 hour clock implies AM
        if (target === me.hour && value === '00' && !me.is24Hour) {
            me.ampmButton.innerText = DH.amIndicator;
        }
        me.activeElement = target;
        me.onValueMutated();
        me.activeElement = null;
    }
    /**
     * Decrement the focused subfield (Hours, minutes or seconds).
     *
     * If the configured {@link #config-step}'s unit matches the subfield's unit,
     * the step magnitude is used, otherwise -1 is used.
     * @internal
     */
    decrementSubfield(field) {
        const
            {
                subfieldIndex,
                step,
                isPM
            } = this;
        if (field === this.ampmButton) {
            field.innerText = isPM ? DH.amIndicator : DH.pmIndicator;
        }
        else {
            const
                oldValue  = this.getSubFieldValue(subfieldIndex),
                increment = step < magnitudes[subfieldIndex - 1] && step > magnitudes[subfieldIndex + 1] ? step / magnitudes[subfieldIndex] : 1,
                newValue = Math.floor(oldValue / increment) * increment - increment;
            // Crossed into AM, toggle the indicator button
            if (subfieldIndex === 0 && !this.is24Hour && oldValue === 12) {
                this.ampmButton.innerText = DH.amIndicator;
            }
            // Update the current field with the incremented value, eg, set minutes to 60
            field.value = newValue;
        }
        // Sanitize and set the new value from the state of the inputs.
        // For example 13:-5 will become 12:55
        this.onValueMutated();
        // If it is an <input>, select all
        field.setSelectionRange?.(0, 2);
    }
    /**
     * Increment the focused subfield (Hours, minutes or seconds).
     *
     * If the configured {@link #config-step}'s unit matches the subfield's unit,
     * the step magnitude is used, otherwise -1 is used.
     * @internal
     */
    incrementSubfield(field) {
        const
            {
                subfieldIndex,
                step,
                is24Hour,
                isPM
            } = this;
        if (field === this.ampmButton) {
            field.innerText = isPM ? DH.amIndicator : DH.pmIndicator;
        }
        else {
            const
                field     = this.inputs[subfieldIndex],
                oldValue  = this.getSubFieldValue(subfieldIndex),
                increment = step < magnitudes[subfieldIndex - 1] && step > magnitudes[subfieldIndex + 1] ? step / magnitudes[subfieldIndex] : 1,
                newValue  = Math.floor(oldValue / increment) * increment + increment;
            // Crossed into PM, toggle the indicator button
            if (subfieldIndex === 0 && !this.is24Hour && oldValue === 11) {
                this.ampmButton.innerText = DH.pmIndicator;
            }
            // Local subfield level validation
            if (subfieldIndex === 0) {
                const hourOfDay = (isPM && !is24Hour) ? oldValue + 12 : oldValue;
                if (hourOfDay === (this.maxHour || 23)) {
                    return;
                }
            }
            // Update the current field with the incremented value, eg, set minutes to 60
            field.value = newValue;
        }
        // Sanitize and set the new value from the state of the inputs
        // For example 12:60 will become 13:00
        this.onValueMutated();
        // If it is an <input>, select all
        field.setSelectionRange?.(0, 2);
    }
    /**
     * Creates the element structure:
     *
     * ```
     * <span></span><input><span></span><input><span></span><input><span></span>
     * ``
     *
     * Each time token, hours, minutes, seconds has an input field and there may be
     * boilerplate text from the format string at any positoin before, between or after them.
     * @param {String} format The {@link Core.helper.DateHelper} format string for the time field.
     * @returns {String} the shadow root's HTML sting.
     */
    createStructure(format = this.owner.format) {
        const
            me         = this,
            {
                _root,
                tabIndex,
                is24Hour
            }          = me,
            {
                amIndicator
            }          = DH,
            testDate   = new Date(1970, 0, 1, 1, 2, 3),
            timeString = DH.format(testDate, format).trim(),
            delimiter  = me.delimiter = timeString.match(delimiterRe)?.[1],
            parts      = [...timeString.matchAll(new RegExp(`\\d+${delimiter ? `|\\${delimiter}` : ''}|${amIndicator}|[^\\${delimiter}\\d]+`, 'g'))].map(m => m[0]),
            children   = [];
        if (tabIndex != null) {
            me.setAttribute('tabIndex', tabIndex);
        }
        let subfieldIndex = 0, hasAmPmIndicator;
        for (let i = 0, inputCount = 0, { length } = parts; i < length; i++) {
            const
                part = parts[i],
                max  = i ? 59 : is24Hour ? 23 : 11;
            if (part.trim() === amIndicator) {
                hasAmPmIndicator = true;
                children.push({
                    tag       : 'button',
                    reference : 'ampmButton',
                    id        : 'am-pm', 
                    type      : 'button',
                    text      : amIndicator,
                    dataset   : {
                        subfieldIndex : subfieldIndex++
                    }
                });
            }
            // It's a time component
            else if (part.match(numbers)) {
                children.push({
                    tag       : 'input',
                    id        : names[inputCount], 
                    reference : names[inputCount++],
                    size      : 2,
                    maxlength : 2,
                    minlength : 2,
                    dataset   : {
                        subfieldIndex : subfieldIndex++,
                        max
                    }
                });
            }
            else if (part === delimiter) {
                children.push({
                    tag       : 'span',
                    text      : delimiter,
                    reference : `delimiter`
                });
            }
            // non-formated text
            else {
                children.push({
                    reference : 'junk',
                    tag       : 'span',
                    text      : part.replaceAll(' ', '\xa0')
                });
            }
        }
        // No trailing delimiter
        if (children[children.length - 1].reference === 'delimiter') {
            children.length--;
        }
        if (!is24Hour && !hasAmPmIndicator) {
            children.push({
                tag       : 'button',
                reference : 'ampmButton',
                id        : 'am-pm', 
                type      : 'button',
                text      : amIndicator,
                dataset   : {
                    subfieldIndex : subfieldIndex++
                }
            });
        }
        children.push({
            tag  : 'style',
            text : styles
        });
        DomSync.sync({
            targetElement : _root,
            refOwner      : me,
            domConfig     : {
                onlyChildren : true,
                children
            }
        });
        // Cache the input fields
        me.inputs = [..._root.querySelectorAll('input')];
        // If no minutes displayed, assume a step of 1 hour
        if (!me.minute) {
            me.owner.step = '1 h';
        }
        if (!is24Hour) {
            me.inputs.push(me.ampmButton);
        }
        else {
            me.ampmButton = null;
        }
    }
    attachRef(name, el) {
        this.byRef[name] = this[name] = el;
    }
    detachRef(name) {
        this[name] = null;
        delete this.byRef[name];
    }
    getSubFieldValue(index) {
        const { date } = this;
        if (date) {
            return date[getMethod[index]]();
        }
        else {
            return 0;
        }
    }
    setSubFieldValue(field, value) {
        field = typeof field === 'number' ? this.inputs[field] : field;
        // Left pad unless this field is processing its input event.
        // But if the active field is already 2 chars, it means "18" has been converted to 6PM, so still pad that.
        if (value < 10 && (field !== this.activeElement || field.value.length === 2)) {
            value = `0${value}`;
        }
        // Don't break user's typing if they're part way through
        if (this.owner.sycingInputFieldValue || (field !== this.shadowRoot.activeElement || field.selectionStart  || Number(field.value) < 0)) {
            field.value = value;
        }
    }
    set value(value) {
        const { _value : oldValue } = this;
        // Value is a DateTime stamp. We preserve the date part
        value = this._value = value ? this.ingestDate(value) : null;
        if (this.isConnected) {
            this.updateValue(value, oldValue);
        }
    }
    updateValue(value, oldValue = null) {
        // Reject non-changes. null is coerced to zero, so that always means a change.
        if (Number(value) != Number(oldValue)) {
            const
                me             = this,
                { ampmButton } = me;
            if (value) {
                const
                    { hour, minute, second } = me,
                    h                         = value.getHours();
                if (me.is24Hour) {
                    me.setSubFieldValue(hour, h);
                }
                else {
                    ampmButton.innerText = h > 11 ? DH.pmIndicator : DH.amIndicator;
                    me.setSubFieldValue(hour, h > 12 ? h % 12 : h);
                }
                minute && me.setSubFieldValue(minute, value.getMinutes());
                second && me.setSubFieldValue(second, value.getSeconds());
            }
            else {
                me._value = DH.clearTime(oldValue);
                me.inputs.forEach(i => i.value = '');
                ampmButton && (ampmButton.innerText = DH.amIndicator);
            }
            // Only throw event to owner if the owner is not updating us.
            if (!me.silentChangeValue && !me.owner.sycingInputFieldValue) {
                me.dispatchEvent(new InputEvent('change'));
            }
        }
    }
    onValueMutated() {
        const
            {
                date,
                minValue,
                maxValue
            }        = this,
            newValue = date ? new Date(date) : null;
        // If we are not cleared, coerce our time into bounds
        if (date) {
            if (minValue && DH.getTime(newValue) < DH.getTime(minValue)) {
                newValue.setHours(minValue.getHours(), minValue.getMinutes(), minValue.getSeconds());
                this._value = null;
            }
            else if (maxValue && DH.getTime(newValue) > DH.getTime(maxValue)) {
                newValue.setHours(maxValue.getHours(), maxValue.getMinutes(), maxValue.getSeconds());
                this._value = null;
            }
        }
        // All mutations are coerced into bounds then pushed through the value update
        this.value = newValue;
    }
    set disabled(disabled) {
        disabled = Boolean(disabled);
        if (this._disabled !== disabled) {
            this._disabled = disabled;
            if (disabled) {
                this.setAttribute('disabled', true);
            }
            else {
                this.removeAttribute('disabled');
            }
        }
    }
    set tabIndex(tabIndex) {
        this._tabIndex = tabIndex;
    }
    get tabIndex() {
        return this._tabIndex;
    }
    get subfieldIndex() {
        return parseInt(this.shadowRoot.activeElement?.dataset.subfieldIndex);
    }
    get isPM() {
        return this.is24Hour ? parseInt(this.hour.value || 0) > 11 : this.ampmButton.innerText === DH.pmIndicator;
    }
    get value() {
        return this.date;
    }
    get innerText() {
        return DH.format(this.date, this.owner.format) || '';
    }
    get date() {
        const
            me         = this,
            { _value } = me;
        // If not connected, or we are cleared, just return the set value
        if (!me.isConnected) {
            return _value || null;
        }
        if (me.isCleared) {
            return null;
        }
        const
            result  = new Date(me._value),
            h       = parseInt(me.hour?.value || 0),
            minutes = parseInt(me.minute?.value || 0),
            seconds = parseInt(me.second?.value || 0);
        let hours = h;
        if (!me.is24Hour) {
            const { isPM } = me;
            // For 12:?? AM, the hour value is 0
            if (h === 12 && !me.isPM) {
                hours = 0;
            }
            // 1:00 PM means 13 hours
            else if (h < 12 && isPM) {
                hours += 12;
            }
        }
        // Use the initially set Date part. Just set the time portion
        result.setHours(hours, minutes, seconds);
        return result;
    }
    get rawDate() {
        const result  = new Date(this._value);
        // Use the initially set Date part. Just set the time portion
        result.setHours(parseInt(this.hour?.value || 0), parseInt(this.minute?.value || 0), parseInt(this.second?.value || 0));
        return result;
    }
    get isCleared() {
        return Boolean(!this.inputs?.map(i => i.value.trim()).join(''));
    }
    focusField(index) {
        const input = this.inputs[index];
        if (input) {
            input.focus();
            input.setSelectionRange?.(0, 2);
        }
    }
}
if (typeof customElements !== 'undefined' && !customElements.get('bry-time')) {
    customElements.define('bry-time', TimeInput);
}
/**
 * The time field widget is a text input field with a time picker drop down. It shows left/right arrows to increase or
 * decrease time by the {@link #config-step step value}.
 *
 * This field can be used as an {@link Grid.column.Column#config-editor editor} for the {@link Grid.column.Column Column}.
 * It is used as the default editor for the {@link Grid.column.TimeColumn TimeColumn}.
 *
 * ## Configuring the picker hour / minute fields
 *
 * You can easily configure the fields in the drop-down picker, to control the increment of the up/down step arrows:
 *
 * ```javascript
 * new TimeField({
 *     label     : 'Time field',
 *     appendTo  : document.body,
 *     picker    : {
 *         items : {
 *             minute : {
 *                 step : 5
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * This widget may be operated using the keyboard. `ArrowDown` opens the time picker, which itself
 * is keyboard navigable. `Shift+ArrowDown` activates the {@link #config-step} back trigger.
 * `Shift+ArrowUp` activates the {@link #config-step} forwards trigger.
 *
 * ```javascript
 * let field = new TimeField({
 *   format: 'HH'
 * });
 * ```
 *
 * {@inlineexample Core/widget/TimeField.js}
 *
 * @extends Core/widget/PickerField
 * @classtype timefield
 * @classtypealias time
 * @inputfield
 */
export default class TimeField extends PickerField {
    //region Config
    static $name = 'TimeField';
    static type = 'timefield';
    static alias = 'time';
    static configurable = {
        inputElementTag : 'bry-time',
        picker : {
            type         : 'timepicker',
            scrollAction : 'realign',
            align        : {
                align     : 't0-b0',
                minHeight : 200,
                axisLock  : true,
                matchSize : 'min'
            }
        },
        /**
         * Get/Set format for time displayed in field (see {@link Core.helper.DateHelper#function-format-static}
         * for formatting options).
         * @member {String} format
         */
        /**
         * Format for date displayed in field (see Core.helper.DateHelper#function-format-static for formatting
         * options).
         * @config {String}
         * @default
         */
        format : 'LT',
        /**
         * Widgets that trigger functionality upon click. Each trigger icon is a {@link Core.widget.Widget} instance
         * which may be hidden, shown and observed and styled just like any other widget.
         * @prp {Object<String,Core.widget.Widget>} triggers
         * @accepts {Object<String,FieldTriggerConfig>}
         * @property {FieldTriggerConfig} triggers.expand Expands the picker to select a time
         * @property {FieldTriggerConfig} triggers.back Subtracts the {@link #config-step} from the current time
         * @property {FieldTriggerConfig} triggers.forward Adds the {@link #config-step} to the current time
         * @property {FieldTriggerConfig} triggers.clear Clears the field value, only available if this field is
         * {@link #config-clearable}
         */
        triggers : {
            expand : {
                align   : 'end',
                handler : 'onTriggerClick',
                key     : ' ',
                compose : () => ({
                    children : [{
                        class : {
                            'b-icon-clock-live' : 1
                        }
                    }]
                })
            },
            back : {
                align   : 'start',
                cls     : 'b-icon b-icon-angle-left b-step-trigger',
                key     : 'Shift+ArrowDown',
                handler : 'onBackClick'
            },
            forward : {
                align   : 'end',
                cls     : 'b-icon b-icon-angle-right b-step-trigger',
                key     : 'Shift+ArrowUp',
                handler : 'onForwardClick'
            }
        },
        /**
         * Get/set min value, which can be a Date or a string. If a string is specified, it will be converted using
         * the specified {@link #config-format}.
         * @member {Date} min
         * @accepts {String|Date}
         */
        /**
         * Min time value
         * @config {String|Date}
         */
        min : null,
        /**
         * Get/set max value, which can be a Date or a string. If a string is specified, it will be converted using
         * the specified {@link #config-format}.
         * @member {Date} max
         * @accepts {String|Date}
         */
        /**
         * Max time value
         * @config {String|Date}
         */
        max : null,
        /**
         * The `step` property may be set in Object form specifying two properties, `magnitude`, a Number, and
         * `unit`, a String.
         *
         * If a Number is passed, the steps's current unit is used and just the magnitude is changed.
         *
         * If a String is passed, it is parsed by {@link Core.helper.DateHelper#function-parseDuration-static}, for
         * example `'5m'`, `'5 m'`, `'5 min'`, `'5 minutes'`.
         *
         * Upon read, the value is always returned in object form containing `magnitude` and `unit`.
         * @member {DurationConfig} step
         * @accepts {String|Number|DurationConfig}
         */
        /**
         * Time increment duration value. Defaults to 5 minutes.
         * The value is taken to be a string consisting of the numeric magnitude and the units.
         * The units may be a recognised unit abbreviation of this locale or the full local unit name.
         * For example `"10m"` or `"5min"` or `"2 hours"`
         * @config {String|Number|DurationConfig}
         */
        step : '5m',
        /**
         * Set to `false` to hide the forward and backward time step triggers.
         * @config {Boolean}
         * @default true
         */
        stepTriggers : {
            $config : null,
            default : true
        },
        /**
         * Get/set value, which can be a Date or a string. If a string is specified, it will be converted using the
         * specified {@link #config-format}.
         * @member {Date} value
         * @accepts {String|Date}
         */
        /**
         * Value, which can be a Date or a string. If a string is specified, it will be converted using the
         * specified {@link #config-format}
         * @config {String|Date}
         */
        value : {
            $config : {
                equal : 'date'
            },
            value : null
        },
        /**
         * Set to true to not clean up the date part of the passed value. Set to false to reset the date part to
         * January 1st
         * @prp {Boolean}
         * @default
         */
        keepDate : true
    };
    static delayable = {
        // Buffer the syncing of validity so that momentary invalid states
        // encountered during typing are suppressed if typing is at reasonable speed.
        syncInvalid : {
            type  : 'buffer',
            delay : 300
        }
    };
    //endregion
    get inputElement() {
        const domConfig = super.inputElement;
        domConfig.tag = 'bry-time';
        domConfig.label = this.label;
        domConfig.elementData = { owner : this };
        return domConfig;
    }
    //region Init & destroy
    changePicker(picker, oldPicker) {
        const
            me          = this,
            pickerWidth = me.pickerWidth || picker?.width;
        return TimePicker.reconfigure(oldPicker, picker, {
            owner    : me,
            defaults : {
                step       : me.step,
                value      : me.value,
                forElement : me.pickerAlignElement,
                owner      : me,
                is24Hour   : me.is24Hour,
                align      : {
                    matchSize : pickerWidth == null,
                    anchor    : me.overlayAnchor,
                    target    : me[me.pickerAlignElement]
                },
                width : pickerWidth,
                onTimeChange({ source, time }) {
                    me._isUserAction = true;
                    source._isUserAction && source.hide();
                    me.value = time;
                    // Ensure that the hour store is in sync with the is24Hour & AM/PM status
                    // If the min is 10:00 and we are a 12 hour time, then when in the PM
                    // hour values 0 to 9 musty be filtered *in*
                    if (me.min && !me.is24Hour) {
                        me._picker.widgetMap.hour.store.filter();
                    }
                    me._isUserAction = false;
                }
            }
        });
    }
    //endregion
    selectAll() {}
    syncInputFieldValue() {
        const me = this;
        // Thius flag prevents the input field from bouncing a change event back here
        // which would result in infinite recursion.
        me.sycingInputFieldValue = true;
        super.syncInputFieldValue(...arguments);
        me.sycingInputFieldValue = false;
        me.syncInvalid();
        if (me.pickerVisible) {
            me.picker.value = me.value;
        }
    }
    get inputValue() {
        // This field does not have to coerce its value to a string.
        // <bry-time> understands a Date, or null to mean cleared.
        return this._value;
    }
    // Override at this level. We set the attribute.
    // But the custom element itself decides on its editability by interrogating its owner.
    syncInputReadOnly() {
        this.getConfig('readOnly');  // make sure our config is initialized...
        // but since the readOnly getter conflates disabled into it, we ultimately have to look at _readOnly:
        this.inputReadOnly = this._readOnly;
    }
    onBackClick() {
        const
            me      = this,
            { min } = me;
        if (!me.readOnly && me.value) {
            const newValue = DH.add(me.value, -1 * me.step.magnitude, me.step.unit);
            if (!min || min.getTime() <= newValue) {
                me.value = newValue;
            }
        }
    }
    onForwardClick() {
        const
            me      = this,
            { max } = me;
        if (!me.readOnly && me.value) {
            const newValue = DH.add(me.value, me.step.magnitude, me.step.unit);
            if (!max || max.getTime() >= newValue) {
                me.value = newValue;
            }
        }
    }
    //endregion
    // region Validation
    get isValid() {
        const me  = this;
        me.clearError('L{minimumValueViolation}', true);
        me.clearError('L{maximumValueViolation}', true);
        let value = me.input.date;
        if (value) {
            value = DH.getTime(value);
            if (me._min && DH.getTime(me._min) > value) {
                me.setError('L{minimumValueViolation}', true);
                return false;
            }
            if (me._max && DH.getTime(me._max) < value) {
                me.setError('L{maximumValueViolation}', true);
                return false;
            }
        }
        return super.isValid;
    }
    internalOnChange(event) {
        const me = this;
        me.inputting = true;
        me._value = me.input.date;
        if (me.pickerVisible) {
            me.picker.value = me.input.date;
        }
        super.internalOnChange(...arguments);
        me.inputting = false;
    }
    internalOnKeyEvent(event) {
        if (this.pickerVisible) {
            if (event.key === 'Escape') {
                this.value = this.pickerValueOnShow;
            }
            if (event.key === 'Enter') {
                this.hidePicker();
                event.preventDefault();
                return;
            }
        }
        super.internalOnKeyEvent(event);
    }
    hasChanged(oldValue, newValue) {
        if (oldValue?.getTime && newValue?.getTime) {
            return this.keepDate ? oldValue - newValue !== 0 : !DH.isSameTime(oldValue, newValue);
        }
        return super.hasChanged(oldValue, newValue);
    }
    //endregion
    //region Toggle picker
    /**
     * Show picker
     */
    showPicker() {
        const
            me = this,
            {
                picker,
                value
            }  = me;
        if (me.readOnly) {
            return;
        }
        // When value is set below, the updater must always run
        picker.value = null;
        picker.format = me.format;
        picker.max = me.max;
        picker.min = me.min;
        super.showPicker();
        // Set value when visible, so that scrollIntoView will work.
        picker.value = me.pickerValueOnShow = value;
        // If we had no value initially.
        if (!value) {
            me.value = picker.value;
        }
    }
    //endregion
    //region Getters/setters
    transformTimeValue(value) {
        if (value != null) {
            if (typeof value === 'string') {
                value = DH.parse(value, this.format);
                if (this.keepDate && value && this.value) {
                    value = DH.copyTimeValues(new Date(this.value), value);
                }
            }
            else {
                value = new Date(value);
            }
            // We insist on a *valid* Time as the value
            if (DH.isValidDate(value)) {
                if (!this.keepDate) {
                    // Clear date part back to zero so that all we have is the time part of the epoch.
                    value = DH.getTime(value);
                }
            }
            else {
                value = null;
            }
        }
        const eventObject = { value };
        this.trigger('transformTimeValue', eventObject);
        value = eventObject.value;
        return value;
    }
    changeMin(value) {
        return this.transformTimeValue(value);
    }
    updateMin(value) {
        const { input } = this;
        if (input) {
            if (value == null) {
                input.removeAttribute('min');
            }
            else {
                input.setAttribute('min', value);
            }
        }
        this.syncInvalid();
    }
    changeMax(value) {
        return this.transformTimeValue(value);
    }
    updateMax(value) {
        const { input } = this;
        if (input) {
            if (value == null) {
                input.removeAttribute('max');
            }
            else {
                input.setAttribute('max', value);
            }
        }
        this.syncInvalid();
    }
    changeValue(value, was) {
        const
            me = this,
            newValue = me.transformTimeValue(value);
        // A value we could not parse
        if (value && !newValue || (me.isRequired && value === '')) {
            // setError uses localization
            me.setError('L{invalidTime}');
            return;
        }
        me.clearError('L{invalidTime}');
        // Reject non-change
        if (me.hasChanged(was, newValue)) {
            return super.changeValue(newValue, was);
        }
        // But we must fix up the display in case it was an unparseable string
        // and the value therefore did not change.
        if (!me.inputting) {
            me.syncInputFieldValue(true);
        }
    }
    updateValue(value, was) {
        const { expand } = this.triggers;
        this.syncInputFieldValue(true);
        // This makes to clock icon show correct time
        if (expand && value) {
            expand.element.firstElementChild.style.animationDelay =
                -((value.getHours() * 60 + value.getMinutes()) / 10) + 's';
        }
        super.updateValue(value, was);
    }
    changeStep(value, was) {
        const type = typeof value;
        if (!value) {
            return { magnitude : 5, unit : 'minute' };
        }
        if (type === 'number') {
            value = {
                magnitude : Math.abs(value),
                unit      : was ? was.unit : 'hour'
            };
        }
        else if (type === 'string') {
            value = DH.parseDuration(value);
        }
        if (value?.unit && value?.magnitude) {
            if (value.magnitude < 0) {
                value = {
                    magnitude : -value.magnitude,  // Math.abs
                    unit      : value.unit
                };
            }
            return value;
        }
    }
    updateStep(value) {
        const { _picker } = this;
        // type="time" field steps are in seconds
        this.input.step = value && DH.as('s', value.magnitude, value.unit);
        // Update the picker's step if it's reasonable to do so.
        // Gantt and Scheduler, depending on Presets can set large step values.
        _picker && DH.as('m', value) < 30 && (_picker.step = value);
        this.syncInvalid();
    }
    get is24Hour() {
        return DH.is24HourFormat(this.format);
    }
    updateFormat(format, oldFormat) {
        const me = this;
        if (!me.isConfiguring) {
            // Our input ingests its format from here.
            // It must not use the new format when calculating its value
            me._format = oldFormat;
            const
                { input } = me,
                { value } = input;
            me._format = format;
            me.sycingInputFieldValue = true;
            input.createStructure(format);
            input.updateValue(value, NaN);
            me.syncInputFieldValue(true);
            me.sycingInputFieldValue = false;
        }
    }
    //endregion
    //region Localization
    updateLocalization() {
        super.updateLocalization();
        if (this.input.isConnected) {
            this.input.createStructure();
        }
        this.syncInputFieldValue(true);
    }
    //endregion
}
// Register this widget type with its Factory
TimeField.initClass();
TimeField._$name = 'TimeField';