import CalendarEditorBaseTab from './CalendarEditorBaseTab.js';
import './CalendarEditorExceptionPanel.js';
/**
 * @module SchedulerPro/widget/calendareditor/CalendarEditorExceptionTab
 */
/**
 * The {@link SchedulerPro/widget/CalendarEditor calendar editor} "Exceptions" tab.
 * The tab contains a grid and a {@link SchedulerPro/widget/calendareditor/CalendarEditorExceptionPanel} instance.
 * @extends SchedulerPro/widget/calendareditor/CalendarEditorBaseTab
 * @classtype calendareditorexceptiontab
 * @internal
 * @widget
 */
export default class CalendarEditorExceptionTab extends CalendarEditorBaseTab {
    static $name = 'CalendarEditorExceptionTab';
    static type = 'calendareditorexceptiontab';
    static configurable = {
        defaultWorkingDayAvailability : null,
        items                         : {
            grid : {
                localeClass : this,
                emptyText   : 'L{noRows}',
                weight      : 100,
                bbar        : {
                    items : {
                        gridAddButton : {
                            tooltip : 'L{CalendarEditorExceptionTab.addException}'
                        },
                        gridRemoveButton : {
                            tooltip : 'L{CalendarEditorExceptionTab.removeException}'
                        }
                    }
                }
            },
            panel : {
                type          : 'calendareditorexceptionpanel',
                isolateFields : true,
                weight        : 200
            }
        }
    };
    updateDefaultWorkingDayAvailability(value) {
        this.widgetMap.panel.defaultWorkingDayAvailability = value;
    }
}
CalendarEditorExceptionTab.initClass();
CalendarEditorExceptionTab._$name = 'CalendarEditorExceptionTab';