//region Import
import GridBase from './GridBase.js';
// import default features (might be able to skip this when draft on dynamic import is implemented)
import '../feature/CellEdit.js';
import '../feature/CellMenu.js';
import '../feature/ColumnAutoWidth.js';
import '../feature/ColumnDragToolbar.js';
import '../feature/ColumnPicker.js';
import '../feature/ColumnReorder.js';
import '../feature/ColumnResize.js';
import '../feature/Filter.js';
import '../feature/FilterBar.js';
import '../feature/Group.js';
import '../feature/HeaderMenu.js';
import '../feature/RowCopyPaste.js';
import '../feature/Sort.js';
import '../feature/Stripe.js';
// To enable using checkbox selection mode, moved here to not be dragged into GridBase automatically
import '../column/CheckColumn.js';
//endregion
/**
 * @module Grid/view/Grid
 */
/**
 * The Grid component is a very powerful and performant UI component that shows tabular data (or tree data using the
 * {@link Grid.view.TreeGrid}).
 *
 * <h2>Intro</h2>
 * The Grid widget has a wide range of features and a large API to allow users to work with data efficiently in the
 * browser. The two most important configs are {@link #config-store} and {@link #config-columns}. With the store config,
 * you decide which data to load into the grid. You can work with both in-memory arrays or load data using ajax. See the
 * {@link Core.data.Store} class to learn more about loading data into stores.
 *
 * The columns config accepts an array of {@link Grid.column.Column Column} descriptors defining which fields that will
 * be displayed in the grid. The {@link Grid.column.Column#config-field} property in the column descriptor maps to a
 * field in your dataset. The simplest grid configured with inline data and two columns would look like this:
 *
 * {@frameworktabs}
 * {@js}
 *
 *  ```javascript
 *  const grid = new Grid({
 *       appendTo : document.body,
 *
 *       columns: [
 *           { field: 'name', text: 'Name' },
 *           { field: 'job', text: 'Job', renderer: ({value}) => value || 'Unemployed' }
 *       ],
 *
 *       data: [
 *           { name: 'Bill', job: 'Retired' },
 *           { name: 'Elon', job: 'Visionary' },
 *           { name: 'Me' }
 *       ]
 * });
 * ```
 *
 * {@endjs}
 * {@react}
 *
 * ```javascript
 * const App = props => {
 *     const [columns, setColumns] = useState([
 *          { field: 'name', text: 'Name' },
 *          { field: 'job', text: 'Job', renderer: ({value}) => value || 'Unemployed' }
 *     ]);
 *
 *     const [data, setData] = useState([
 *          { name: 'Bill', job: 'Retired' },
 *          { name: 'Elon', job: 'Visionary' },
 *          { name: 'Me' }
 *     ]);
 *
 *     return <BryntumGrid column={columns} data={data} />
 * }
 * ```
 *
 * {@endreact}
 * {@vue}
 *
 *  ```html
 * <bryntum-grid :columns="columns" :data="data" />
 * ```
 *
 * ```javascript
 * export default {
 *    setup() {
 *      return {
 *        columns : [
 *          { field: 'name', text: 'Name' },
 *          { field: 'job', text: 'Job', renderer: ({value}) => value || 'Unemployed' }
 *        ]
 *        data : reactive([
 *          { name: 'Bill', job: 'Retired' },
 *          { name: 'Elon', job: 'Visionary' },
 *          { name: 'Me' }
 *        ])
 *      };
 *    }
 * }
 * ```
 *
 * {@endvue}
 * {@angular}
 * ```html
 * <bryntum-grid [columns]="columns" [data]="data"></bryntum-grid>
 * ```
 *
 * ```typescript
 * export class AppComponent {
 *      columns = [
 *          { field: 'name', text: 'Name' },
 *          { field: 'job', text: 'Job', renderer: ({value}) => value || 'Unemployed' }
 *      ]
 *
 *      data = [
 *          { name: 'Bill', job: 'Retired' },
 *          { name: 'Elon', job: 'Visionary' },
 *          { name: 'Me' }
 *      ]
 *  }
 * ```
 *
 * {@endangular}
 * {@endframeworktabs}
 *
 * {@inlineexample Grid/view/Grid.js}
 *
 * <h2>Features</h2>
 * To avoid the Grid core being bloated, its main features are implemented in separate `feature` classes. These can be
 * turned on and off based on your requirements. To configure (or disable) a feature, use the {@link #config-features}
 * object to provide your desired configuration for the features you want to use. Each feature has an ´id´ that you use
 * as a key in the features object:
 *
 * ```javascript
 * const grid = new Grid({
 *     features : {
 *         cellEdit     : false,
 *         regionResize : true,
 *         cellTooltip  : {
 *             tooltipRenderer : (data) => {
 *             }
 *         },
 *         ...
 *     }
 * });
 * ```
 *
 * {@region Column configuration options}
 * A grid contains a number of columns stored in a {@link Grid/data/ColumnStore} that control how your data is rendered.
 * The simplest option is to point a Column to a field in your dataset, or define a custom {@link Grid.column.Column#config-renderer}.
 * The renderer function receives one object parameter containing rendering data for the current cell being rendered.
 *
 * ```javascript
 * const grid = new Grid({
 *     columns: [
 *         {
 *             field: 'task',
 *             text: 'Task',
 *             renderer(renderData) {
 *                 const record = renderData.record;
 *
 *                 if (record.percentDone === 100) {
 *                     renderData.cellElement.classList.add('taskDone');
 *                     renderData.cellElement.style.background = 'green';
 *                 }
 *
 *                 return renderData.value;
 *             }
 *         }
 *     ]
 * });
 * ```
 * You can modify columns programmatically easily:
 * ```javascript
 * // resize first column
 * grid.columns.first.width = 200;
 *
 * // Change text
 * grid.columns.first.text = 'New text';
 *
 * ```
 * To learn more about modifying individual columns, please see the docs for {@link Grid/data/ColumnStore}
 * and {@link Grid/column/Column}.
 *
 * {@endregion}
 * {@region Grid sections (aka "locked" or "frozen" columns)}
 * The grid can be divided horizontally into individually scrollable sections. This is great if you have lots of columns
 * that don't fit the available width of the screen. To enable this feature, simply mark the columns you want to `lock`.
 * Locked columns are then displayed in their own section to the left of the other columns:
 *
 * ```javascript
 * const grid = new Grid({
 *     width    : 500,
 *     subGridConfigs : {
 *         // set a fixed locked section width if desired
 *         locked : { width: 300 }
 *     },
 *     columns : [
 *         { field : 'name', text : 'Name', width : 200, locked : true },
 *         { field : 'firstName', text : 'First name', width : 100, locked : true },
 *         { field : 'surName', text : 'Last name', width : 100, locked : true },
 *         { field : 'city', text : 'City', width : 100 },
 *         { type : 'number', field : 'age', text : 'Age', width : 200 },
 *         { field : 'food', text : 'Food', width : 200 }
 *     ]
 * });
 * ```
 *
 * {@inlineexample Grid/view/LockedGrid.js}
 * You can also move columns between sections by using drag and drop, or use the built-in header context menu. If you
 * want to be able to resize the locked grid section, enable the {@link Grid.feature.RegionResize} feature.
 * {@endregion}
 * {@region Filtering}
 * One important requirement of a good Grid component is the ability to filter large datasets to quickly find what you
 * are looking for. To enable filtering (through the context menu), add the {@link Grid.feature.Filter} feature:
 *
 * ```javascript
 * const grid = new Grid({
 *     features: {
 *         filter: true
 *     }
 * });
 * ```
 *
 * Or activate a default filter at initial rendering:
 *
 * ```javascript
 * const grid = new Grid({
 *     features: {
 *         filter: { property : 'city', value : 'New York' }
 *     }
 * });
 * ```
 *
 * {@inlineexample Grid/feature/Filter.js}
 * {@endregion}
 * {@region Tooltips}
 * If you have a data models with many fields, and you want to show
 * additional data when hovering over a cell, use the {@link Grid.feature.CellTooltip} feature. To show a
 * tooltip for all cells:
 *
 * ```javascript
 * const grid = new Grid({
 *     features: {
 *         cellTooltip: ({value}) => value
 *     }
 * });
 * ```
 *
 * {@inlineexample Grid/feature/CellTooltip.js}
 * {@endregion}
 * {@region Inline Editing (default <strong>on</strong>)}
 * To enable inline cell editing in the grid, simply add the {@link Grid.feature.CellEdit} feature:
 *
 * ```javascript
 * const grid = new Grid({
 *     features : {
 *         cellEdit : true
 *     },
 *     columns: [
 *         {
 *             field: 'task',
 *             text: 'Task'
 *         }
 *     ]
 * });
 * ```
 *
 * {@inlineexample Grid/feature/CellEdit.js}
 * {@endregion}
 * {@region Context Menu}
 * Use {@link Grid.feature.CellMenu} and {@link Grid.feature.HeaderMenu} features if you want your users to be able to
 * interact with the data through the context menu:
 *
 * ```javascript
 * const grid = new Grid({
 *     features : {
 *         headerMenu : {
 *             items : {
 *                 showInfo : {
 *                     text   : 'Show info',
 *                     icon   : 'fa fa-info-circle',
 *                     weight : 200,
 *                     onItem : ({ item }) => console.log(item.text)
 *                 }
 *             }
 *         },
 *         cellMenu :  {
 *             items : {
 *                 showOptions : {
 *                     text   : 'Show options',
 *                     icon   : 'fa fa-gear',
 *                     weight : 200
 *                 }
 *             }
 *         }
 *     }
 * });
 * ```
 *
 * {@inlineexample Grid/feature/CellMenu.js}
 * {@endregion}
 * {@region Grouping}
 * To group rows by a field in your dataset, use the {@link Grid.feature.Group} feature.
 * {@inlineexample Grid/feature/Group.js}
 * {@endregion}
 * {@region Searching}
 * When working with lots of data, a quick alternative to filtering is the {@link Grid.feature.Search} feature.
 * It highlights matching values in the grid as you type.
 * {@inlineexample Grid/feature/Search2.js}
 * {@endregion}
 * {@region Loading and saving data}
 * The grid keeps all its data in a {@link Core.data.Store}, which is essentially an Array of {@link Core.data.Model}
 * items. You define your own Model representing your data entities and use the Model API to get and set values.
 *
 * ```javascript
 * class Person extends Model {}
 *
 * const person = new Person({
 *     name: 'Steve',
 *     age: 38
 * });
 *
 * person.name = 'Linda'; // person object is now `dirty`
 *
 * const store = new Store({
 *     data : [
 *         { name : 'Don', age : 40 }
 *     ]
 * });
 *
 * store.add(person);
 *
 * console.log(store.count()); // === 2
 *
 * store.remove(person); // Remove from store
 * ```
 *
 * When you update a record in a store, it's considered dirty, until you call {@link Core.data.mixin.StoreCRUD#function-commit}
 * on the containing Store. You can also configure your Store to commit automatically (like Google docs). If you use an
 * AjaxStore, it will send changes to your server when commit is called.
 *
 * Any changes you make to the Store or its records are immediately reflected in the Grid, so there is no need to tell
 * it to refresh manually.
 *
 * To create a custom load mask, subscribe to the grid's store events and {@link Core.widget.Widget#config-masked mask}
 * on {@link Core.data.AjaxStore#event-beforeRequest} and unmask on {@link Core.data.AjaxStore#event-afterRequest}. The
 * mask can also be used to display error messages if an {@link Core.data.AjaxStore#event-exception} occurs.
 *
 * ```javascript
 *  const grid = new Grid({
 *      loadMask : null
 *  });
 *
 *  grid.store.on({
 *      beforeRequest() {
 *          grid.masked = {
 *              text : 'Data is loading...'
 *          };
 *      },
 *      afterRequest() {
 *          grid.masked = null;
 *      },
 *      exception({ response }) {
 *          grid.masked.error = response.message || 'Load failed';
 *      }
 *  });
 *
 *  store.load();
 * ```
 *
 * To learn more about loading and saving data, please refer to [this guide](#Grid/guides/data/displayingdata.md).
 * {@endregion}
 * {@region Lazy loading (infinite scroll)}
 * Enabling lazy loading of Grid records makes it possible to load the dataset in chunks when records are scrolled into
 * view, instead of loading the complete dataset at once.
 *
 * Set the {@link Core.data.Store#config-lazyLoad} config on the Store to `true` to enable this behaviour. You will also
 * need something that initiates the first load, either use {@link Core.data.Store#config-autoLoad} or call the
 * {@link Core.data.Store#function-load} function manually.
 *
 * **Using an AjaxStore**
 * ```javascript
 * new Grid({
 *     store: {
 *         // This will create an AjaxStore
 *         readUrl: 'backend/read',
 *         // This will activate the lazy load functionality
 *         lazyLoad: true,
 *         // This will load the Store initially upon creation
 *         autoLoad: true
 *     }
 * });
 * ```
 *
 * **Using a regular Store**
 * ```javascript
 * class MyStore extends Store {
 *     static configurable = {
 *         lazyLoad: true,
 *         autoLoad: true
 *     };
 *
 *     async requestData({
 *         startIndex,
 *         count
 *     }) {
 *         const response = await fetchData({
 *             startIndex,
 *             count
 *         });
 *
 *         // The requestData function is expected to return an object
 *         // with a data property, whose value contains all the records
 *         return {
 *             data: response.data,
 *             // And optionally, but recommended, is to provide a total
 *             // count of all available records
 *             total: response.totalCount
 *         }
 *     }
 * }
 *
 * new Grid({
 *     store: new MyStore()
 * });
 * ```
 *
 * Please note that, when using a lazy loaded Store, there is a number of Grid features, functions and configs that are
 * either not supported at all or only works in a limited way. Such information is available in the corresponding
 * documentation.
 *
 * There is also a [guide](#Grid/guides/data/lazyloading.md) on how to use lazy loading in your Grid's store.
 * {@endregion}
 * {@region Default configs}
 * There is a myriad of configs and features available for Grid, some of them on by default and some of them requiring
 * extra configuration. The code below tries to illustrate the major things that are used by default:
 *
 * ```javascript
 * const grid = new Grid({
 *    // The following features are enabled by default:
 *    features : {
 *        cellEdit      : true,
 *        columnPicker  : true,
 *        columnReorder : true,
 *        columnResize  : true,
 *        cellMenu      : true,
 *        headerMenu    : true,
 *        group         : true,
 *        rowCopyPaste  : true, // Allow using [Ctrl/CMD + C/X] and [Ctrl/CMD + V] to copy/cut and paste rows
 *        sort          : true
 *    },
 *
 *    animateRemovingRows       : true,  // Rows will slide out on removal
 *    autoHeight                : false, // Grid needs to have a height supplied through CSS (strongly recommended) or by specifying `height`
 *    columnLines               : true,  // Themes might override it to hide lines anyway
 *    emptyText                 : 'No rows to display',
 *    enableTextSelection       : false, // Not allowed to select text in cells by default,
 *    fillLastColumn            : true,  // By default the last column is stretched to fill the grid
 *    fullRowRefresh            : true,  // Refreshes entire row when a cell value changes
 *    loadMask                  : 'Loading...',
 *    resizeToFitIncludesHeader : true,  // Also measure header when auto resizing columns
 *    responsiveLevels : {
 *      small : 400,
 *      medium : 600,
 *      large : '*'
 *    },
 *    rowHeight                  : null,  // Determined using CSS, it will measure rowHeight
 *    showDirty                  : false, // No indicator for changed cells
 * });
 * ```
 *
 * {@endregion}
 * {@region Keyboard shortcuts}
 * Grid has the following default keyboard shortcuts:
 * <div class="compact">
 *
 * | Keys                 | Action                 | Weight ¹ | Action description                                                                                 |
 * |----------------------|------------------------|:--------:|----------------------------------------------------------------------------------------------------|
 * | `ArrowUp`            | *navigateUp*           | 10       | Focuses the cell above currently focused cell.                                                     |
 * | `ArrowRight`         | *navigateRight*        | 10       | Focuses the cell to the right of currently focused cell                                            |
 * | `ArrowDown`          | *navigateDown*         | 10       | Focuses the cell below currently focused cell                                                      |
 * | `ArrowLeft`          | *navigateLeft*         | 10       | Focuses the cell to the left of currently focused cell                                             |
 * | `Shift`+`ArrowUp`    | *extendSelectionUp*    |          | Extends the selection one row up from currently focused cell                                       |
 * | `Shift`+`ArrowRight` | *extendSelectionRight* |          | Extends the selection one column to the right from currently focused cell                          |
 * | `Shift`+`ArrowDown`  | *extendSelectionDown*  |          | Extends the selection one row down from currently focused cell                                     |
 * | `Shift`+`ArrowLeft`  | *extendSelectionLeft*  |          | Extends the selection one column to the left from currently focused cell                           |
 * | `Space`              | *toggleSelection*      | 10       | Toggles selection of currently focused cell if selectionMode.selectOnKeyboardNavigation is `false` |
 * | `Ctrl`+`Home`        | *navigateFirstCell*    |          | Focuses the first cell at the first row (including header)                                         |
 * | `Home`               | *navigateFirstColumn*  |          | Focuses the first cell of current focused row                                                      |
 * | `Ctrl`+`End`         | *navigateLastCell*     |          | Focuses the last cell of the last row                                                              |
 * | `End`                | *navigateLastColumn*   |          | Focuses the last cell of current focused row                                                       |
 * | `PageUp`             | *navigatePrevPage*     |          | Displays previous page                                                                             |
 * | `PageDown`           | *navigateNextPage*     |          | Displays next page                                                                                 |
 * | `Enter`              | *activateHeader*       |          | Equals to a header click                                                                           |
 * | `Space`              | *clickCellByKey*       | 1000     | Equals to a cell click                                                                             |
 * | `Ctrl`+`Z`           | *undoRedoKeyPress*     |          | Undo/redo (when using {@link Core.data.stm.StateTrackingManager})                                  |
 * | `Ctrl`+`Shift`+`Z`   | *undoRedoKeyPress*     |          | Undo/redo (when using {@link Core.data.stm.StateTrackingManager})                                  |
 *
 * **¹** Customization of keyboard shortcuts that has a `weight` can affect other features that also uses that
 * particular keyboard shortcut. Read more in [our guide](#Grid/guides/customization/keymap.md).
 *
 *</div>
 *
 * <div class="note" style="font-size: 0.9em">Please note that <code>Ctrl</code> is the equivalent to <code>Command</code> and <code>Alt</code>
 * is the equivalent to <code>Option</code> for Mac users</div>
 *
 * The following Grid features has their own keyboard shortcuts. Follow the links for details.
 * * {@link Grid.feature.CellCopyPaste#keyboard-shortcuts CellCopyPaste}
 * * {@link Grid.feature.CellEdit#keyboard-shortcuts CellEdit}
 * * {@link Grid.feature.CellMenu#keyboard-shortcuts CellMenu}
 * * {@link Grid.feature.ColumnRename#keyboard-shortcuts ColumnRename}
 * * {@link Grid.feature.Filter#keyboard-shortcuts Filter}
 * * {@link Grid.feature.Group#keyboard-shortcuts Group}
 * * {@link Grid.feature.HeaderMenu#keyboard-shortcuts HeaderMenu}
 * * {@link Grid.feature.QuickFind#keyboard-shortcuts QuickFind}
 * * {@link Grid.feature.RowCopyPaste#keyboard-shortcuts RowCopyPaste}
 * * {@link Grid.feature.Search#keyboard-shortcuts Search}
 * * {@link Grid.feature.Tree#keyboard-shortcuts Tree}
 *
 * For more information on how to customize keyboard shortcuts, please see
 * [our guide](#Grid/guides/customization/keymap.md)
 *
 * {@endregion}
 * {@region Performance}
 * In general the Grid widget has very good performance and you can try loading any amount of data in the
 * <a target="_blank" href="../examples/bigdataset/">bigdataset</a> demo.
 * The overall rendering performance is naturally affected by many other things than the data volume. Other important
 * factors that can impact performance: number of columns, complex cell renderers, locked columns, the number of
 * features enabled and of course the browser (Chrome fastest).
 * {@endregion}
 * {@region Accessibility}
 * As far as possible, the grid is accessible to WAI-ARIA standards. Every cell, including column header cells is
 * visitable. The arrow keys navigate, and if a cell contains focusable content, navigating to that cell focuses the
 * content. `Escape` will exit from that and focus the encapsulating cell.
 *
 * When tabbing back into a grid that has previously been entered, focus moves to the last focused cell.
 *
 * The column menu is invoked using the `Space` key when focused on a column header.
 *
 * The cell menu is invoked using the `Space` key when focused on a data cell.
 * {@endregion}
 *
 * {@region Saving state}
 * The grid supports saving its UI {@link Grid.view.mixin.GridState state} to ensure end users will see the same column
 * sizes, sorting, grouping settings as they reload the page / app. Please refer to the {@link Grid.view.mixin.GridState}
 * and {@link Core.mixin.State} documentation for more information on state management.
 * {@endregion}
 *
 * @extends Grid/view/GridBase
 * @classtype grid
 * @widget
 */
export default class Grid extends GridBase {
    static $name = 'Grid';
    // Factoryable type name
    static type = 'grid';
}
// Register this widget type with its Factory
Grid.initClass();
Grid._$name = 'Grid';