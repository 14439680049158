var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { calculate } from '../../../../ChronoGraph/replica/Entity.js';
import DateHelper from '../../../../Core/helper/DateHelper.js';
import { dateConverter, model_field } from '../../../chrono/ModelFieldAtom.js';
import { Direction, TimeUnit } from '../../../scheduling/Types.js';
import { HasChildrenMixin } from '../scheduler_basic/HasChildrenMixin.js';
import { ConstrainedByDependenciesEventMixin } from "../scheduler_pro/ConstrainedByDependenciesEventMixin.js";
import { DateConstraintInterval } from "../scheduler_pro/HasDateConstraintMixin.js";
import { ConstrainedLateScheduleMixin } from "./ConstrainedLateScheduleMixin.js";
//---------------------------------------------------------------------------------------------------------------------
/**
 * This mixin provides the constraint-based as-late-as-possible scheduling. See the [[ConstrainedEarlyEventMixin]]
 * for the description of the ASAP constraints-based scheduling. See [[GanttProjectMixin]] for more details about
 * forward/backward, ASAP/ALAP scheduling.
 *
 * It also provides the facilities for calculating the event's [[totalSlack]] and the [[critical]] flag.
 *
 * The ALAP-specific constraints are accumulated in [[lateSchedule]], [[latePreSchedule]] fields.
 */
export class ConstrainedLateEventMixin extends Mixin([ConstrainedByDependenciesEventMixin, HasChildrenMixin], (base) => {
    const superProto = base.prototype;
    class ConstrainedLateEventMixin extends base {
        get earlyPreSchedule() {
            if (this._earlyPreSchedule !== undefined)
                return this._earlyPreSchedule;
            const schedule = super.earlyPreSchedule;
            schedule.phase = 'phase1';
            return this._earlyPreSchedule = schedule;
        }
        // is instantiated upon entering the graph only
        get earlySchedule() {
            if (this._earlySchedule !== undefined)
                return this._earlySchedule;
            const scheduleMixinClass = this.constructor.scheduleMixinClass;
            const schedule = scheduleMixinClass.new({
                // Assign id to avoid expensive id generation
                id: 'earlySchedule' 
            });
            schedule.event = this;
            schedule.phase = 'phase2';
            return this._earlySchedule = schedule;
        }
        /**
         * The [[ConstrainedLateScheduleMixin]] containing the 1st phase ALAP schedule
         */
        get latePreSchedule() {
            if (this._latePreSchedule !== undefined)
                return this._latePreSchedule;
            const scheduleMixinClass = this.constructor.scheduleMixinClass;
            const schedule = scheduleMixinClass.new({
                // Assign id to avoid expensive id generation
                id: 'latePreSchedule' 
            });
            schedule.event = this;
            schedule.direction = Direction.Backward;
            schedule.phase = 'phase1';
            return this._latePreSchedule = schedule;
        }
        /**
         * The [[ConstrainedLateScheduleMixin]] containing the 2nd phase ALAP schedule
         */
        get lateSchedule() {
            if (this._lateSchedule !== undefined)
                return this._lateSchedule;
            const scheduleMixinClass = this.constructor.scheduleMixinClass;
            const schedule = scheduleMixinClass.new({
                // Assign id to avoid expensive id generation
                id: 'lateSchedule' 
            });
            schedule.event = this;
            schedule.direction = Direction.Backward;
            schedule.phase = 'phase2';
            return this._lateSchedule = schedule;
        }
        enterGraph(replica) {
            super.enterGraph(replica);
            this.earlySchedule.enterGraph(replica);
            this.latePreSchedule.enterGraph(replica);
            this.lateSchedule.enterGraph(replica);
        }
        leaveGraph(replica) {
            this.lateSchedule.leaveGraph(replica);
            this.latePreSchedule.leaveGraph(replica);
            this.earlySchedule.leaveGraph(replica);
            super.leaveGraph(replica);
        }
        unlink() {
            this.lateSchedule.unlink();
            this.latePreSchedule.unlink();
            this.earlySchedule.unlink();
            super.unlink();
        }
        *calculateLateStartDate() {
            return yield this.lateSchedule.$.startDate;
        }
        *calculateLateEndDate() {
            return yield this.lateSchedule.$.endDate;
        }
        *calculateTotalSlack() {
            const earlyStartDate = yield this.earlySchedule.$.startDate;
            const lateStartDate = yield this.lateSchedule.$.startDate;
            const earlyEndDate = yield this.earlySchedule.$.endDate;
            const lateEndDate = yield this.lateSchedule.$.endDate;
            const slackUnit = yield this.$.slackUnit;
            let result;
            if (earlyStartDate && lateStartDate) {
                result = yield* this.calculateProjectedDuration(earlyStartDate, lateStartDate, slackUnit, undefined, true);
            }
            if (earlyEndDate && lateEndDate) {
                const endSlack = yield* this.calculateProjectedDuration(earlyEndDate, lateEndDate, slackUnit, undefined, true);
                if (endSlack < result)
                    result = endSlack;
            }
            return result;
        }
        *calculateCritical() {
            const totalSlack = yield this.$.totalSlack;
            return typeof totalSlack === 'number' && totalSlack <= 0;
        }
        *isConstrainedAlongDirection(direction) {
            return yield* this.pickDirectionSchedule(direction).isConstrained();
        }
        pickDirectionSchedule(ownDirection) {
            return ownDirection === Direction.Forward ? this.earlySchedule : this.lateSchedule;
        }
        *onEmptyEffectiveInterval(type, sourceSchedule) {
            // this condition indicates 2nd pass in the calculation ("late" for forward project, "early" for backward)
            // what happens here is the following - we assume, that if calculations have reached the 2nd pass
            // the conflicts on 1st pass have been resolved somehow
            // so when we have a conflict on the 2nd pass, we just take the value from the 1st pass
            // in other words, if we were able to position the event on 1st pass somehow, we should always be able
            // to position it on the 2nd pass as well (using data from the 1st pass)
            // this will make task critical (early date = late date) with slack 0, however in MSP,
            // slack is negative (if that make sense)
            // probably, also need to set some marker on the task, to indicate that there's a "hidden" conflict
            if (!(yield* sourceSchedule.isFirstPass())) {
                const project = this.getProject();
                if (yield project.$.ignoreConstraintsOnConflictDuringSecondPass) {
                    const effectiveInterval = type === 'start'
                        ? yield* sourceSchedule.doCalculateEffectiveStartDateInterval(true)
                        : yield* sourceSchedule.doCalculateEffectiveEndDateInterval(true);
                    let intervals = Array.from(effectiveInterval.intersectionOf);
                    const info = this.getOwnConstraintConflictInfo(intervals);
                    if (info.kind === 'dependency_with_own_constraint') {
                        const startDateConstraintIntervals = (yield sourceSchedule.$.startDateConstraintIntervals)
                            .concat(yield this.$.startDateConstraintIntervals)
                            .filter(interval => !(interval instanceof DateConstraintInterval));
                        const endDateConstraintIntervals = (yield sourceSchedule.$.endDateConstraintIntervals)
                            .concat(yield this.$.endDateConstraintIntervals)
                            .filter(interval => !(interval instanceof DateConstraintInterval));
                        const newEffectiveConstraintInterval = yield* sourceSchedule.calculateEffectiveConstraintInterval(type === 'start', startDateConstraintIntervals, endDateConstraintIntervals, false);
                        if (!newEffectiveConstraintInterval.isIntervalEmpty()) {
                            return { kind: 'resolved', value: newEffectiveConstraintInterval };
                        }
                    }
                }
                return {
                    kind: 'return',
                    value: yield sourceSchedule.pickOpposite(this).$[type === 'start' ? 'startDate' : 'endDate']
                };
            }
            else {
                return yield* super.onEmptyEffectiveInterval(type, sourceSchedule);
            }
        }
    }
    // @ts-ignore
    ConstrainedLateEventMixin.scheduleMixinClass = Mixin([base.scheduleMixinClass, ConstrainedLateScheduleMixin], base => base);
    __decorate([
        model_field({ type: 'date', persist: false, calculated: true }, { lazy: true, converter: dateConverter, persistent: false })
    ], ConstrainedLateEventMixin.prototype, "lateStartDate", void 0);
    __decorate([
        model_field({ type: 'date', persist: false, calculated: true }, { lazy: true, converter: dateConverter, persistent: false })
    ], ConstrainedLateEventMixin.prototype, "lateEndDate", void 0);
    __decorate([
        model_field({ type: 'number', persist: false, calculated: true }, { lazy: true, persistent: false })
    ], ConstrainedLateEventMixin.prototype, "totalSlack", void 0);
    __decorate([
        model_field({ type: 'string', defaultValue: TimeUnit.Day, persist: false }, { lazy: true, converter: DateHelper.normalizeUnit, persistent: false })
    ], ConstrainedLateEventMixin.prototype, "slackUnit", void 0);
    __decorate([
        model_field({ type: 'boolean', defaultValue: false, persist: false, calculated: true }, { persistent: false, lazy: true })
    ], ConstrainedLateEventMixin.prototype, "critical", void 0);
    __decorate([
        calculate('lateStartDate')
    ], ConstrainedLateEventMixin.prototype, "calculateLateStartDate", null);
    __decorate([
        calculate('lateEndDate')
    ], ConstrainedLateEventMixin.prototype, "calculateLateEndDate", null);
    __decorate([
        calculate('totalSlack')
    ], ConstrainedLateEventMixin.prototype, "calculateTotalSlack", null);
    __decorate([
        calculate('critical')
    ], ConstrainedLateEventMixin.prototype, "calculateCritical", null);
    return ConstrainedLateEventMixin;
}) {
}
