/**
 * @module Core/data/stm/Transaction
 */
import Base from '../../Base.js';
import { ACTION_TYPES } from './action/ActionBase.js';
import { ACTION_QUEUE_PROP } from './Props.js';
import { invertAction } from './action/AllActions.js';
export const TRANSACTION_TYPES = {
    DATA_CORRECTION     : 'DATA_CORRECTION',
    TEMPORARY           : 'TEMPORARY',
    CONFLICT_RESOLUTION : 'CONFLICT_RESOLUTION'
};
/**
 * STM transaction class, holds list of actions constituting a transaction.
 *
 * A transaction can be undone and redone. Upon undo all the actions being held
 * are undone in reverse order. Upon redo all the actions being held are redone
 * in forward order.
 */
export default class Transaction extends Base {
    static get configurable() {
        return {
            /**
             * Transaction title
             *
             * @config {String}
             */
            title : null,
            /**
             * Identifies if revision is temporary or not. Committed revisions get cleaned eventually, local
             * revisions should be committed.
             * @internal
             */
            committed : false,
            inputGeneration : 0
        };
    }
    static from(transactions, config = {}) {
        const result = new this({
            ...this.initialConfig,
            ...config,
            // In theory, here we can lose information about input generation. We are collecting latest generation of
            // actions from each transaction, and we set this value to the max. It means when we are collecting input,
            // we will skip actions with lower generation.
            inputGeneration : transactions.reduce((result, transaction) => {
                return Math.max(result, transaction.inputGeneration ?? 0);
            }, 0),
            committed : false
        });
        result[ACTION_QUEUE_PROP] = transactions.flatMap(t => t[ACTION_QUEUE_PROP].filter(a => {
            // From each transaction we need to collect actions with highest `isUserInput` value. Or every action, if
            // no input is marked.
            if (t.inputGeneration > 0) {
                if (config.conflictResolutionFor) {
                    return a.isUserInput === t.inputGeneration;
                }
                else {
                    return a.isUserInput;
                }
            }
            else {
                return a;
            }
        }));
        return result;
    }
    /**
     * Creates transaction with the lowest generation of actions
     * @param {Core.data.stm.Transaction} transaction
     * @returns {Core.data.stm.Transaction}
     * @internal
     */
    static createTransactionWithOriginalInput(transaction) {
        const result = new this({
            ...this.initialConfig,
            title     : transaction.title,
            committed : false
        });
        result[ACTION_QUEUE_PROP] = transaction[ACTION_QUEUE_PROP]
            // Original input is represented by action with lowest/non-existent `isUserInput` value
            .filter(a => a.isUserInput ? a.isUserInput === 1 : !a.isUserInput);
        return result;
    }
    construct(...args) {
        this[ACTION_QUEUE_PROP] = [];
        super.construct(...args);
    }
    /**
     * Gets transaction's actions queue
     *
     * @property {Core.data.stm.action.ActionBase[]}
     */
    get queue() {
        return this[ACTION_QUEUE_PROP].slice(0);
    }
    /**
     * Gets transaction's actions queue length
     *
     * @property {Number}
     */
    get length() {
        return this[ACTION_QUEUE_PROP].length;
    }
    get filterUserInput() {
        return this.inputGeneration > 0;
    }
    /**
     * Adds an action to the transaction.
     *
     * @param {Core.data.stm.action.ActionBase|Object} action
     */
    addAction(action) {
        this[ACTION_QUEUE_PROP].push(action);
    }
    /**
     * Undoes actions held
     */
    undo() {
        const queue = this[ACTION_QUEUE_PROP];
        for (let i = queue.length - 1; i >= 0; --i) {
            queue[i].undo();
        }
    }
    /**
     * Redoes actions held
     */
    redo() {
        const queue = this[ACTION_QUEUE_PROP];
        for (let i = 0, len = queue.length; i < len; ++i) {
            queue[i].redo();
        }
    }
    /**
     * Merges all update actions into one per model, keeping the oldest and the newest values
     */
    mergeUpdateModelActions() {
        const
            queue     = this[ACTION_QUEUE_PROP],
            recordMap = new Map(),
            keep      = [];
        for (const action of queue) {
            if (action.isUpdateAction && action.model?.isModel) {
                // `isUserInput` is an 1-based generation index of user input
                // might be undefined if action is not a user input
                // using it as an array index
                const index = action.isUserInput ?? 0;
                let entry = recordMap.get(action.model);
                if (!entry) {
                    entry = [];
                    recordMap.set(action.model, entry);
                }
                const mergedRecordAction = entry[index];
                if (!mergedRecordAction) {
                    entry[index] = action;
                    keep.push(action);
                }
                else {
                    for (const key in action.oldData) {
                        // Must exist in both old and new data, maybe it always does??
                        if (action.newData.hasOwnProperty(key)) {
                            // If it doesn't exist in the merged old data. Add it there
                            if (!mergedRecordAction.oldData.hasOwnProperty(key)) {
                                mergedRecordAction.oldData[key] = action.oldData[key];
                            }
                            // Always overwrite the merged newData
                            mergedRecordAction.newData[key] = action.newData[key];
                        }
                    }
                }
            }
            else {
                keep.push(action);
                // If a model is removed, remove it from the map. If it is added and updated again, it should have a new
                // update action for that
                if (action.isRemoveAction && action.modelList?.length) {
                    for (const model of action.modelList) {
                        // delete record from both maps
                        recordMap.delete(model);
                    }
                }
            }
        }
        this[ACTION_QUEUE_PROP] = keep;
        this.inputGeneration = Math.max(...keep.map(a => a.isUserInput ?? 0), 0);
    }
    // This is required to make task editor work with revisions and undo/redo. Some
    // models (dependency base model) contain code which prevents undo from adding record
    // back to the `added` store bag which does not allow revisions feature to be aware
    // of the change. To fix this we break the contract of the STM transaction and remove
    // update actions within one transaction which update added record.
    // The reasoning is that insert/add action will already contain reference to the actual
    // model, therefore there's no need to roll back field modifications. This assumption
    // seem safe.
    mergeAddUpdateModelActions() {
        const
            queue     = this[ACTION_QUEUE_PROP],
            addedRecords = new Set(),
            keep      = [];
        for (const action of queue) {
            if (action.isAddAction || action.isInsertAction) {
                action.modelList.forEach(r => addedRecords.add(r));
            }
            else if (action.isInsertChildAction) {
                action.childModels.forEach(r => addedRecords.add(r));
            }
            if (action.isUpdateAction || action.isEventUpdateAction) {
                // We only need to keep actions which update records already existing in the store, which are not added
                // in the same transaction. Action keeps reference to the record and record will have all the correct
                // values already
                if (!addedRecords.has(action.model)) {
                    keep.push(action);
                }
            }
            else {
                keep.push(action);
            }
        }
        this[ACTION_QUEUE_PROP] = keep;
        this.inputGeneration = Math.max(...keep.map(a => a.isUserInput ?? 0), 0);
    }
    invert() {
        const transaction = new this.constructor({ ...this.initialConfig, title : this.title });
        transaction[ACTION_QUEUE_PROP] = this[ACTION_QUEUE_PROP].map(action => invertAction(action));
        return transaction;
    }
    /**
     * Returns a map of actions grouped by `isUserInput` value
     * @returns {Map}
     * @internal
     */
    groupUserInput() {
        // Split user actions into a list based on the value of the `isUserInput` property. It has value `1` for
        // real user actions and `2` for conflict resolutions.
        return this[ACTION_QUEUE_PROP].reduce((result, action) => {
            const inputGroup = action.isUserInput ?? 'default';
            if (!result.has(inputGroup)) {
                result.set(inputGroup, []);
            }
            result.get(inputGroup).push(action);
            return result;
        }, new Map());
    }
    /**
     * Collects all updates from the transaction into a map with model as key and changed data as value.
     * @param {Number} [generation] If undefined, last generation is returned
     * @returns {Object|undefined}
     * @internal
     */
    getUserInput(generation) {
        const
            updated        = new Map(),
            changes        = {},
            // Split user actions into a list based on the value of the `isUserInput` property. It has value `1` for
            // real user actions and `2` for conflict resolutions.
            actions        = this.groupUserInput().get(generation ?? (this.inputGeneration || 'default')),
            getBagForStore = (name, storeId) => {
                if (!(storeId in changes)) {
                    changes[storeId] = { [name] : [] };
                }
                else if (!(name in changes[storeId])) {
                    changes[storeId][name] = [];
                }
                return changes[storeId][name];
            };
        for (const action of actions) {
            switch (action.type) {
                case ACTION_TYPES.ADD:
                case ACTION_TYPES.INSERT: {
                    if (action.inversed) {
                        const removed = getBagForStore('removed', action.store.id);
                        removed.push(...action.modelList);
                    }
                    else {
                        const added = getBagForStore('added', action.store.id);
                        added.push(...action.modelList);
                    }
                    break;
                }
                case ACTION_TYPES.REMOVE:
                case ACTION_TYPES.REMOVE_ALL: {
                    if (action.inversed) {
                        const added = getBagForStore('added', action.store.id);
                        added.push(...(action.modelList || action.allRecords));
                    }
                    else {
                        const removed = getBagForStore('removed', action.store.id);
                        removed.push(...(action.modelList || action.allRecords));
                    }
                    break;
                }
                case ACTION_TYPES.INSERT_CHILD: {
                    for (const store of action.stores) {
                        action.childModels.forEach(record => {
                            if (store.added.includes(record)) {
                                getBagForStore('added', store.id).push(record);
                            }
                            else if (store.removed.includes(record)) {
                                getBagForStore('removed', store.id).push(record);
                            }
                        });
                        // Insert child action does not keep any information about field updates. Only piece of
                        // information we can extract is id of the new parent node
                        store.modified.forEach(record => {
                            const treeChanges = record.hierarchyModificationDataToWrite;
                            if (treeChanges) {
                                if (updated.has(record)) {
                                    updated.set(record, { ...updated.get(record), ...treeChanges });
                                }
                                else {
                                    updated.set(record, treeChanges);
                                }
                            }
                        });
                    }
                    break;
                }
                case ACTION_TYPES.REMOVE_CHILD: {
                    for (const store of action.stores) {
                        if (action.inversed) {
                            const added = getBagForStore('added', store.id);
                            added.push(...action.childModels);
                        }
                        else {
                            const removed = getBagForStore('removed', store.id);
                            removed.push(...action.childModels);
                        }
                    }
                    break;
                }
                // Update action is store-agnostic. Therefore, we cannot tell here which store was modified.
                case ACTION_TYPES.UPDATE:
                case ACTION_TYPES.EVENT_UPDATE: {
                    // In case actions were not merged (autoRecord is false) we combine them here
                    if (updated.has(action.model)) {
                        updated.set(action.model, { ...updated.get(action.model), ...action.newData });
                    }
                    else {
                        updated.set(action.model, { ...action.newData });
                    }
                    break;
                }
                default: break;
            }
        }
        if (updated.size > 0) {
            changes.updated = updated;
        }
        if (Object.keys(changes).length > 0) {
            return changes;
        }
    }
    markCurrentTransactionContentUserInput() {
        const
            generation = this.inputGeneration + 1,
            queue      = this[ACTION_QUEUE_PROP];
        let generationAssigned = false;
        queue.forEach(action => {
            if (action.isUserInput === undefined && !action.isCalculated) {
                action.isUserInput = generation;
                generationAssigned = true;
            }
        });
        if (generationAssigned) {
            this.inputGeneration = generation;
        }
    }
    markCurrentTransactionContentCalculated() {
        const
            generation = this.inputGeneration,
            queue      = this[ACTION_QUEUE_PROP];
        queue.forEach(action => {
            if (action.isCalculated === undefined && !action.isUserInput) {
                action.isCalculated = generation;
            }
        });
    }
    normalizeUserInputGeneration() {
        this[ACTION_QUEUE_PROP].forEach(action => action.isUserInput = this.inputGeneration);
    }
}
Transaction._$name = 'Transaction';