import EditorTab from './EditorTab.js';
/**
 * @module SchedulerPro/widget/taskeditor/FormTab
 */
/**
 * Base class for tabs that **contain fields** (form-like tabs) in {@link SchedulerPro.widget.SchedulerTaskEditor scheduler task editor} or
 * {@link SchedulerPro.widget.GanttTaskEditor gantt task editor}, such as General or Notes.
 *
 * @extends SchedulerPro/widget/taskeditor/EditorTab
 */
export default class FormTab extends EditorTab {
    static $name = 'FormTab';
    static type = 'formtab';
    static get defaultConfig() {
        return {
            layoutStyle : {
                flexFlow     : 'row wrap',
                alignItems   : 'flex-start',
                alignContent : 'flex-start'
            },
            scrollable : {
                overflowY : true
            },
            autoUpdateRecord : true
        };
    }
    onFieldChange({ source, valid, userAction }) {
        if (userAction) {
            valid = valid !== undefined ? valid : (typeof source.isValid === 'function') ? source.isValid() : source.isValid;
            if (valid) {
                super.onFieldChange(...arguments);
            }
        }
    }
}
FormTab.initClass();
FormTab._$name = 'FormTab';