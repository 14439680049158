import GridTreeGroup from '../../Grid/feature/TreeGroup.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import WalkHelper from '../../Core/helper/WalkHelper.js';
import Delayable from '../../Core/mixin/Delayable.js';
import AttachToProjectMixin from '../../Scheduler/data/mixin/AttachToProjectMixin.js';
import { MAX_DATE, MIN_DATE } from '../../Engine/util/Constants.js';
/**
 * @module Gantt/feature/TreeGroup
 */
/**
 * Extends Grid's {@link Grid.feature.TreeGroup} (follow the link for more info) feature to enable using it with Gantt.
 * Allows generating a new task tree where parents are determined by the values of specified task fields/functions:
 *
 * {@inlineexample Gantt/feature/TreeGroup.js}
 *
 * ## Important information
 *
 * Using the TreeGroup feature comes with some caveats:
 *
 * * Grouping replaces the store Gantt uses to display tasks with a temporary "display store". The original task store
 *   is left intact, when grouping stops Gantt will revert to using it to display tasks.
 * * `gantt.taskStore` points to the original store when this feature is enabled. To apply sorting or filtering programmatically, you should instead interact with the "display store" directly, using `gantt.store`.
 * * Generated parents are read-only, they cannot be edited using the default UI.
 * * Leaves in the new tree are still editable as usual, and any changes to them survives the grouping operation.
 * * Moving tasks in the tree (rearranging rows) is not supported while it is grouped.
 *
 * This feature is <strong>disabled</strong> by default.
 *
 * @extends Grid/feature/TreeGroup
 *
 * @classtype treeGroup
 * @feature
 *
 * @typings Grid.feature.TreeGroup -> Grid.feature.GridTreeGroup
 */
export default class TreeGroup extends GridTreeGroup.mixin(AttachToProjectMixin, Delayable) {
    static $name = 'TreeGroup';
    // Generate dates etc. for parents during grouping
    processTransformedData(transformedData) {
        if (!transformedData.children?.length) {
            return;
        }
        const
            { project }       = this.client,
            { rootNode }      = project.taskStore,
            defaultEffortUnit = rootNode.getFieldDefinition('effortUnit').defaultValue;
        // Since generated parents are not part of the project we have to manually set their dates etc. Walk them all
        // (since they are generated we are guaranteed there is no mix of parents and leaves at any give level), and
        // determine those
        WalkHelper.postWalk(transformedData, task => !task.children?.[0].isLeaf && task.children, task => {
            const { children } = task;
            if (children?.length) {
                const taskEffortUnit = task.effortUnit || defaultEffortUnit;
                let
                    minStartDate = MAX_DATE,
                    maxEndDate   = MIN_DATE,
                    percentDone  = 0,
                    effort       = 0;
                for (const child of children) {
                    if (child.startDate && child.startDate < minStartDate) {
                        minStartDate = child.startDate;
                    }
                    if (child.endDate && child.endDate > maxEndDate) {
                        maxEndDate = child.endDate;
                    }
                    percentDone += child.percentDone;
                    // if the child has effort
                    if (child.effort) {
                        // convert it to the task effort units and roll up
                        effort += project.run('$convertDuration',
                            child.effort,
                            child.effortUnit || defaultEffortUnit,
                            taskEffortUnit
                        );
                    }
                }
                task.startDate = minStartDate.getTime() !== MAX_DATE.getTime() ? new Date(minStartDate) : null;
                task.endDate   = maxEndDate.getTime() !== MIN_DATE.getTime() ? new Date(maxEndDate) : null;
                task.duration  = task.endDate && task.startDate
                    ? rootNode.run('calculateProjectedDuration', task.startDate, task.endDate) : 0;
                task.percentDone = percentDone / children.length;
                task.effort = effort;
            }
        });
    }
    refresh() {
        this.client.refreshWithTransition();
    }
}
TreeGroup._$name = 'TreeGroup'; GridFeatureManager.registerFeature(TreeGroup, false, 'Gantt');
