import DomHelper from '../../Core/helper/DomHelper.js';
import ObjectHelper from '../../Core/helper/ObjectHelper.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
import GridFeatureManager from './GridFeatureManager.js';
import Split from './Split.js';
/**
 * @module Grid/feature/LockRows
 */
/**
 *
 * This feature allows records which satisfy a certain condition to be locked at the top of the grid.
 *
 * By default, the condition is that a certain named field have a truthy value. The field which decides this status
 * defaults to `'fixed'`, but that is configurable using the {@link #config-fieldName} property.
 *
 * When used with {@link #config-fieldName}, the {@link Grid/feature/CellMenu} context menu appears with an extra option
 * to toggle the value of that field in the contextual record.
 *
 * For more granular control, use the {@link #config-filterFn} to decide which records should be locked.
 *
 * {@note}Please note that this feature will not work with the {@link Grid/feature/Split} feature.{/@note}
 *
 * {@inlineexample Grid/feature/LockRows.js}
 *
 * * ## Caveats
 *
 * This features utilizes the {@link Grid.feature.Split} feature behinds the scenes to create a split view of the grid.
 * Each part of the view is a separate grid instance, which means that certain operations are limited to one part of the
 * grid at the time - for example drag selection and shift + click selection.
 *
 * The top view (locked rows) is the original grid instance, and the bottom view is a clone of the original grid
 * instance. During locking, both views use stores chained of the original store to filter out the records that should
 * be locked or not.
 *
 * {@note}To access the original store, use the {@link #property-originalStore} property of the grid instance.{/@note}
 *
 * When using {@link Grid/feature/RowCopyPaste}, cutting and pasting among locked rows is not allowed. The results of
 * those actions would be confusing, since for example cutting a locked row and pasting it among the normal rows would
 * return it to the locked rows again.
 *
 * Additionally, these features are currently not supported while using LockRows:
 *
 * * Summary feature
 * * RowReorder feature: Rows cannot be dragged between different sections
 * * PdfExport feature
 * * Export to Excel
 *
 * This feature is **disabled** by default.
 *
 * @extends Grid/feature/Split
 * @demo Grid/lock-rows
 * @classtype lockRows
 * @feature
 */
export default class LockRows extends Split {
    static $name = 'LockRows';
    static configurable = {
        /**
         * The field name whose truthy/falsy state decides whether a record is locked at the top of the grid.
         *
         * Is overridden if a {@link #config-filterFn} is provided.
         *
         * @config {String} fieldName
         * @default 'fixed'
         */
        fieldName : 'fixed',
        /**
         * A function, or the name of a function in the Grid's ownership hierarchy, that decides whether a record is
         * locked at the top of the grid.
         *
         * Overrides the {@link #config-fieldName} property.
         *
         * @config {Function|String} filterFn
         * @param {Core.data.Model} record The record to decide upon.
         * @returns {Boolean} Return `true` to lock the record at the top.
         */
        filterFn : null
    };
    // There's no merging of pluginConfigs
    static get pluginConfig() {
        const config = {};
        for (const prop in super.pluginConfig) {
            config[prop] = [...super.pluginConfig[prop]];
        }
        config.chain.push('selectAll', 'deselectAll', 'afterRenderRow');
        // Future-proofing
        if (!config.override) {
            config.override = [];
        }
        config.override.push('isAllRecordsSelected');
        return config;
    };
    /**
     * Locking rows leads to two chained stores that holds a subset of the records, one for the locked records and one
     * for the rest. This property holds the original store, if you need to access it.
     *
     * ```javascript
     * // With 100 rows, 5 of which are locked
     * console.log(grid.store.count); // 5
     * console.log(grid.originalStore.count); // 100
     * ```
     *
     * @member {Core.data.Store} originalStore
     * @on-owner
     */
    construct() {
        super.construct(...arguments);
        if (this.enabled) {
            this.refreshSuspended = true;
            this.client.suspendRefresh();
        }
    }
    onInternalPaint({ firstPaint }) {
        if (firstPaint) {
            if (this.refreshSuspended) {
                this.client.resumeRefresh();
            }
            this.lockRows();
        }
    }
    async lockRows(options = { fieldName : this.fieldName, filterFn : this.filterFn }) {
        const
            me         = this,
            { client } = me;
        // Can't split a split
        if (client.splitFrom || me.disabled || me.$isLocking) {
            return;
        }
        // Locking is async, prevent another lock until done
        me.$isLocking = true;
        if (me.isSplit) {
            await me.unsplit(true);
        }
        options = me.processOptions(options);
        const
            {
                store,
                scrollable
            }              = client,
            { fieldName }  = options,
            filterId       = `${client.id}-sticky-record-filter`,
            resetGrid      = ObjectHelper.copyProperties({}, client, [
                'flex',
                'minHeight',
                'maxHeight',
                'autoHeight',
                'hideHorizontalScrollbar'
            ]),
            splitContainer = me.createSplitContainer(options);
        let { filterFn } = options;
        if (resetGrid.maxHeight === 0) {
            resetGrid.maxHeight = null;
        }
        resetGrid.height = client._lastHeight || null;
        // Create cleaner function to restore client to original state.
        me.restorers.push(() => {
            client.setConfig(resetGrid);
            store.removeFilter(filterId);
            client.element.classList.remove('b-locked-rows');
            client.store = client.store.$master;
            client.isLockedRows = client.originalStore = null;
            client.isLockedRowsView = null;
        });
        // If we are sticking records with a specified field name, ensure the record has that
        // field defined so that mutations are signalled and tracked.
        if (fieldName && !store.modelClass.fieldMap[fieldName]) {
            store.modelClass.addField({
                name : fieldName,
                type : 'boolean'
            });
        }
        // Modify client to only show records which are not in the clone
        client.setConfig({
            height                  : null,
            flex                    : '0 0 auto',
            minHeight               : 0,
            maxHeight               : options.maxHeight || '50%',
            autoHeight              : true,
            hideHorizontalScrollbar : true
        });
        client.element.classList.add('b-locked-rows');
        const chainedFields = fieldName ? [fieldName] : '*';
        filterFn = filterFn || (record => record[fieldName]);
        // One clone needed to contain the records *not* stuck at top.
        // It must flex to use all space left by the autoHeight client grid which encapsulates stuck records.
        const [clone] = me.widgets = [
            me.cloneClient(splitContainer, 0, options, {
                flex         : '1 1 0%',
                hideHeaders  : true,
                usesLockRows : true
            })
        ];
        clone.element.classList.add('b-locked-rows-clone');
        me.subViews = [clone, client];
        // Store the original store, to give apps easier access to the full dataset
        client.originalStore = store;
        client.isLockedRows = true;
        client.isLockedRowsView = clone.isLockedRowsView = true;
        // Chain both stores, to avoid filtering the original store
        client.store = store.chain(record => filterFn(record), chainedFields, {
            chainFilters : true
        });
        clone.store = store.chain(record => !filterFn(record), chainedFields, {
            chainFilters : true
        });
        // If there's a vertical scrollbar, force empty top grid to have one to stay in sync
        if (DomHelper.scrollBarWidth && scrollable.hasOverflowY) {
            scrollable.overflowY = 'scroll';
        }
        // Sync scrolling (unless already partnered, which is the case in Scheduler)
        !client.scrollable.isPartneredWith(clone.scrollable) && client.scrollable.addPartner(clone.scrollable, {
            x : true
        });
        // Any row number columns in the bottom section need to be refreshed
        clone.columns.leaves.forEach(col => {
            if (col.isRowNumberColumn) {
                clone.refreshColumn(col);
            }
        });
        me._splitOptions = options;
        // Moving in DOM does not seem to trigger resize, do it manually
        const bounds = Rectangle.from(client.element);
        client.onInternalResize(client.element, bounds.width, bounds.height);
        client.eachSubGrid(subGrid => {
            const subGridBounds = Rectangle.from(subGrid.element);
            subGrid.onInternalResize(subGrid.element, subGridBounds.width, subGridBounds.height);
        });
        if (client.features.columnAutoWidth?.enabled) {
            client.features.columnAutoWidth.syncAutoWidthColumns();
        }
        // If scrolled, the original element gets out of sync when moved around in DOM
        client.scrollable.x += 0.5;
        client.scrollable.y += 0.5;
        me.startSyncingColumns();
        me.$isLocking = false;
        /**
         * Fires when row locking is enabled.
         * @event lockRows
         * @param {Grid.view.GridBase} clone The created clone
         * @on-owner
         */
        client.trigger('lockRows', { clone });
        return me.subViews;
    }
    processOptions(options) {
        const { fieldName } = options;
        options.direction = 'horizontal';
        if (!options.filterFn && !fieldName) {
            throw new Error('LockRows needs `fieldName` or `filterFn`');
        }
        if (typeof options.filterFn === 'string') {
            options.filterFn = this.resolveCallback(options.filterFn);
        }
        return options;
    }
    get numberOfLockedRows() {
        return this.isSplit ? this.subViews[1].store.count : 0;
    }
    //region Syncing selection
    // Overrides Grids default
    isAllRecordsSelected() {
        const otherGrid = this.isSplit && this.subViews[0];
        return this.overridden.isAllRecordsSelected() && (!otherGrid || otherGrid.isAllRecordsSelected());
    }
    // Runs after Grids default
    selectAll(silent) {
        if (this.isSplit) {
            this.subViews[0].selectAll(silent);
        }
    }
    // Runs after Grids default
    deselectAll(removeCurrentRecordsOnly, silent) {
        if (this.isSplit) {
            this.subViews[0].deselectAll(removeCurrentRecordsOnly, silent);
        }
    }
    afterRenderRow({ row, recordIndex }) {
        if (this.isSplit) {
            row.toggleCls('b-last', recordIndex === this.client.store.count - 1);
        }
    }
    //endregion
    //region Context menu
    populateCellMenu({ record, items }) {
        const
            me        = this,
            feature   = me.client.splitFrom?.features.lockRows || me,
            fieldName = feature._splitOptions?.fieldName;
        if (!me.disabled && fieldName && !feature.filterFn && !me.client.readOnly && !record.isSpecialRow) {
            items.toggleLocked = {
                text        : record[fieldName] ? 'L{unlockRow}' : 'L{lockRow}',
                localeClass : me,
                icon        : `b-icon ${record[fieldName] ? 'b-icon-unlock-row' : 'b-icon-lock-row'}`,
                weight      : 500,
                separator   : true,
                closeParent : true,
                onItem      : () => {
                    record[fieldName] = !record[fieldName];
                }
            };
        }
    }
    //endregion
    async unsplit(silent = false) {
        const
            me    = this,
            clone = me.subViews[0];
        me.client.trigger('beforeUnlockRows', { clone });
        await super.unsplit(silent);
        /**
         * Fires when row locking is disabled.
         * @event unlockRows
         * @param {Grid.view.GridBase} clone The locked clone that will be destroyed
         * @on-owner
         */
        !me.isDestroyed && me.client.trigger('unlockRows', { clone });
    }
    doDisable(disable) {
        if (!this.isConfiguring) {
            if (disable) {
                this.unsplit();
            }
            else  {
                this.lockRows();
            }
        }
    }
    //endregion
}
LockRows._$name = 'LockRows'; GridFeatureManager.registerFeature(LockRows, false);
