import Store from '../../../Core/data/Store.js';
import ArrayHelper from '../../../Core/helper/ArrayHelper.js';
import FunctionHelper from '../../../Core/helper/FunctionHelper.js';
import StringHelper from '../../../Core/helper/StringHelper.js';
import LocaleManager from '../../../Core/localization/LocaleManager.js';
import CalendarEditorExceptionModel from '../../model/calendareditor/CalendarEditorExceptionModel.js';
import CalendarEditorWeekModel from '../../model/calendareditor/CalendarEditorWeekModel.js';
/**
 * @module SchedulerPro/data/calendareditor/CalendarEditorStore
 */
const
    defaultExceptionStartDate = new Date(1900, 0, 1),
    defaultExceptionEndDate   = new Date(3000, 0, 1),
    getErrorText              = error => {
        // we want the log report to be in English
        const en = LocaleManager.locales.En;
        return en?.CalendarEditorWeekGrid[error] || en?.CalendarEditorExceptionPanel[error] || error;
    };
/**
 * A store providing data for the calendar editor widget.
 * It accepts a {@link #config-calendar} as its input and builds records
 * based on the calendar intervals in the form convenient for the calendar editor.
 *
 * This store is heterogeneous and can accept models of two classes -
 * {@link SchedulerPro/model/calendareditor/CalendarEditorExceptionModel} and
 * {@link SchedulerPro/model/calendareditor/CalendarEditorWeekModel}.
 *
 * @extends Core/data/Store
 * @internal
 */
export default class CalendarEditorStore extends Store {
    static $name = 'CalendarEditorStore';
    static configurable = {
        /**
         * Set to `true` to automatically apply changes of the store back
         * to the {@link #config-calendar}.
         * @config {Boolean}
         */
        autoPush : true,
        /**
         * Set to `true` to automatically rebuild the store records when {@link #config-calendar}
         * changes.
         * @config {Boolean}
         */
        autoPull : true,
        validateRecordsAfterPulling : false,
        stopPullingOnError : false,
        autoPushTimeout : 10,
        autoPullTimeout : 10,
        // the store contains two types of model weeks and exceptions
        // fill modelClass just to avoid warnings in console
        modelClass : CalendarEditorExceptionModel,
        /**
         * Class representing _week_ intervals.
         * @config {SchedulerPro.model.calendareditor.CalendarEditorWeekModel}
         */
        weekModelClass : CalendarEditorWeekModel,
        /**
         * Class representing _exception_ intervals.
         * @config {SchedulerPro.model.calendareditor.CalendarEditorExceptionModel}
         */
        exceptionModelClass : CalendarEditorExceptionModel,
        /**
         * Calendar the store processes to build own records.
         * @prp {SchedulerPro.model.CalendarModel}
         */
        calendar : null
    };
    static properties = {
        _isAutoPushSuspended : 0,
        _isAutoPullSuspended : 0,
        intervalCounter      : 0,
        exceptionCounter     : 1,
        weekCounter          : 1
    };
    static errors = {
        errorMultipleDefaultWeeks : 'errorMultipleDefaultWeeks',
        errorNoDefaultWeek        : 'errorNoDefaultWeek'
    };
    static intervalColors = [
        { text : 'red',         color : '#ff8787' },
        { text : 'pink',        color : '#f783ac' },
        { text : 'purple',      color : '#ea80dc' },
        { text : 'magenta',     color : '#ff4dff' },
        { text : 'violet',      color : '#9775fa' },
        { text : 'indigo',      color : '#748ffc' },
        { text : 'blue',        color : '#4dadf7' },
        { text : 'cyan',        color : '#3bc9db' },
        { text : 'teal',        color : '#38d9a9' },
        { text : 'green',       color : '#69db7c' },
        { text : 'gantt-green', color : '#a5d8a7' },
        { text : 'lime',        color : '#a9e34b' },
        { text : 'yellow',      color : '#fdd835' },
        { text : 'orange',      color : '#ffa94d' },
        { text : 'deep-orange', color : '#ff7043' },
        { text : 'gray',        color : '#a0a0a0' },
        { text : 'light-gray',  color : '#e0e0e7' },
        { text : 'black',       color : '#000000' }
    ];
    construct() {
        this._recordByRawInterval = new Map();
        this._recordByRawKey = new Map();
        super.construct(...arguments);
    }
    internalCalendarBumpVersion() {
        this.trigger('calendarBumpVersion', { calendar : this.calendar });
        if (this.autoPull) {
            this.scheduleAutoPull();
        }
    }
    updateCalendar(calendar) {
        const me = this;
        me._calendarBumpVersionDetacher?.();
        if (calendar) {
            // track calendar bumpVersion calls to pull up-to-date data from it
            me._calendarBumpVersionDetacher = FunctionHelper.after(calendar, 'bumpVersion',
                'internalCalendarBumpVersion',
                me
            );
            if (me.autoPull) {
                me.scheduleAutoPull();
            }
        }
        else {
            me.removeAll();
        }
        me.trigger('calendarChange', { calendar });
    }
    onChange({ record }) {
        // If autoPush is on we schedule pushing changes to the calendar
        // when a record is changed.
        // Bail out if the record is not valid. That happens for instance when user
        // starts entering a new availability range ..and not fully specified range
        // results incorrect intervals in the calendar.
        if (this.autoPush && this.calendar) {
            this.scheduleAutoPush();
        }
    }
    addNthNameRecord(data = {}, nameRegExp = null) {
        let { name } = data;
        nameRegExp = nameRegExp || new RegExp(`^${StringHelper.escapeRegExp(name)}(?:\\s*(\\d+))?$`);
        const postFixes = [0];
        // find entries named this way ("name", "name 1", "name 2" etc)
        for (const record of this.query(record => record.name?.match(nameRegExp))) {
            postFixes.push(parseInt(record.name.match(nameRegExp)[1] || 1, 10));
        }
        // get max postfix used
        const maxPostfix = Math.max(...postFixes);
        // append to the name max postfix+1
        if (maxPostfix) {
            name += ' ' + (maxPostfix + 1);
        }
        // add to the store
        const [added] = this.add({ ...data, name });
        return added;
    }
    add(records) {
        const { calendar } = this;
        records = ArrayHelper.asArray(records);
        if (!records?.length) {
            // Adding zero records, bail out
            return;
        }
        // if have a calendar - decorate added models with it
        if (calendar) {
            for (const record of records) {
                if (!record.calendar) {
                    record.calendar = calendar;
                }
            }
        }
        const added = super.add(...arguments);
        this.sanitizeInternalMaps();
        return added;
    }
    remove() {
        const removed = super.remove(...arguments);
        this.sanitizeInternalMaps();
        return removed;
    }
    sanitizeInternalMaps() {
        const { _recordByRawKey } = this;
        for (const [key, record] of new Map(_recordByRawKey)) {
            const { compositeCode } = record;
            // remove dead links
            if (!this.getById(record) || key !== compositeCode) {
                _recordByRawKey.delete(key);
            }
        }
        // if still not all records are registered - do that
        if (_recordByRawKey.size !== this.count) {
            for (const record of this) {
                _recordByRawKey.set(record.compositeCode, record);
            }
        }
    }
    updateAutoPull(value) {
        if (value && this._calendar) {
            this.scheduleAutoPull();
        }
    }
    get rawStore() {
        return this.calendar?.intervalStore;
    }
    // Picks a random interval color
    getRandomColor() {
        const
            allColors = this.constructor.intervalColors,
            color     = allColors[Math.round(Math.random() * (allColors.length - 1))].color;
        return color;
    }
    // Returns an unused random interval color
    getUnusedColor() {
        const
            me         = this,
            allColors  = me.constructor.intervalColors,
            usedColors = me.getDistinctValues('color');
        // get a random color
        let color = me.getRandomColor();
        // if used less colors that we have in the color pool
        if (usedColors.length < allColors.length) {
            // while the random color is used
            while (usedColors.includes(color)) {
                // get another one
                color = me.getRandomColor();
            };
        }
        return color;
    }
    createRecord(data, skipExpose = false, rawData = false) {
        const me = this;
        if (!data.cls) {
            data.cls = '';
        }
        // no color provided - pick next from the colors pool
        if (!data.color) {
            data.color = me.intervalCounter ? me.getUnusedColor() : '#4dadf7';
        }
        me.intervalCounter++;
        let modelClass;
        if (data.type === 'Week') {
            modelClass = me.weekModelClass;
            me.weekCounter++;
        }
        else {
            modelClass = me.exceptionModelClass;
            me.exceptionCounter++;
        }
        return new modelClass(data, me, null, skipExpose, false, rawData);
    }
    afterLoadData() {
        super.afterLoadData?.();
        // If no default week specified yet - find it (it's the first week w/o start/end dates
        if (!this.some(record => record.isDefaultWeek, true)) {
            for (const record of this.allRecords) {
                if (record.isWeek && !record.isOverride) {
                    record.isDefaultWeek = true;
                    break;
                }
            }
        }
    }
    processIntervalRecurrence(interval) {
        const
            result = {
                type          : interval.type || 'Exception',
                isTypeDefined : Boolean(interval.type),
                startDate     : interval.startDate,
                endDate       : interval.endDate
            },
            spec = interval.parseDateSchedule(interval.recurrentStartDate);
        // is recurring
        if (spec) {
            const { schedules, exceptions } = spec;
            // - composite schedules are not supported (we could break down such intervals into multiple)
            // - zero schedules also makes no sense
            // - exceptions are not supported
            if (schedules.length !== 1 || exceptions.length) {
                return false;
            }
            const [schedule] = schedules;
            if (!interval.type) {
                const
                    timeParams      = ['h', 'm', 's', 't'],
                    dayParams       = ['dw', 'd'],
                    timeUsageCoeff  = timeParams.reduce((acc, p) => acc + Number(Boolean(schedule[p])), 0),
                    dayUsageCoeff   = dayParams.reduce((acc, p) => acc + (schedule[p] ? schedule[p].length || 1 : 0), 0),
                    hasLargerUnits  = schedule.Y || schedule.M || schedule.dy || schedule.dc || schedule.D || schedule.wm ||
                        schedule.wy;
                // week - if days/times are configured and not configured months/years
                // otherwise call it exception
                result.type = (timeUsageCoeff || dayUsageCoeff) && !hasLargerUnits ? 'Week' : 'Exception';
            }
            else {
                result.type = interval.type;
            }
            // if laterjs "after {{date}}" is specifier copy {{date}} to startDate field
            if (schedule.fd_a && !result.startDate) {
                result.startDate = new Date(schedule.fd_a[0]);
            }
            // if laterjs "before {{date}}" is specifier copy {{date}} to endDate field
            if (schedule.fd_b && !result.endDate) {
                result.endDate = new Date(schedule.fd_b[0]);
            }
        }
        return result;
    }
    processRawInterval(interval) {
        const
            me = this,
            {
                name,
                isWorking,
                compositeCode,
                compositeIntervalCode
            }         = interval,
            calendar  = interval.getCalendar(),
            data      = me.processIntervalRecurrence(interval);
        // If interval specification cannot be handled by the store
        if (!data) {
            return false;
        }
        if (data.type === 'Exception') {
            // give exceptions some large start-end date range by default
            data.startDate = data.startDate || defaultExceptionStartDate;
            data.endDate   = data.endDate || defaultExceptionEndDate;
            data.name      = name || `Exception ${me.exceptionCounter}`;
        }
        else {
            data.name = name || (data.startDate || data.endDate ? `Week ${me.weekCounter}` : 'Default week');
        }
        // Get the record generated for the interval earlier
        // (in case one record represents multiple raw intervals)
        // If no record matching the key - making it
        const record = me._recordByRawKey.get(compositeIntervalCode) || me.createRecord({
            ...data,
            // a set w/ references to raw intervals the record is built based on
            intervals : new Set(),
            calendar,
            compositeCode
        });
        record.isWorking = record.isWorking || isWorking;
        record.intervals.add(interval);
        record.processRawInterval?.(interval);
        me._recordByRawInterval.set(interval.id, record);
        me._recordByRawKey.set(compositeIntervalCode, record);
        return record;
    }
    getRecordByRawInterval(record) {
        return this._recordByRawInterval.get(record.id);
    }
    get isAutoPullSuspended() {
        return this._isAutoPullSuspended > 0;
    }
    suspendAutoPull() {
        this._isAutoPullSuspended++;
    }
    resumeAutoPull() {
        if (this._isAutoPullSuspended) {
            this._isAutoPullSuspended--;
        }
    }
    get isAutoPushSuspended() {
        return this._isAutoPushSuspended > 0;
    }
    suspendAutoPush() {
        this._isAutoPushSuspended++;
    }
    resumeAutoPush() {
        if (this._isAutoPushSuspended) {
            this._isAutoPushSuspended--;
        }
    }
    scheduleAutoPush() {
        const me = this;
        // add deferred call if it's not scheduled yet
        if (!me.hasTimeout('autoPush') && !me.isAutoPushSuspended) {
            me.setTimeout({
                name  : 'autoPush',
                fn    : me.pushToCalendar,
                delay : me.autoPushTimeout
            });
        }
    }
    scheduleAutoPull() {
        const me = this;
        // add deferred call if it's not scheduled yet
        if (!me.hasTimeout('autoPull') && !me.isAutoPullSuspended) {
            me.setTimeout({
                name  : 'autoPull',
                fn    : me.pullFromCalendar,
                delay : me.autoPullTimeout
            });
        }
    }
    /**
     * Pulls intervals from the provided calendar (or from the configured {@link #config-calendar}
     * if not provided) and builds the store records based on the intervals.
     * @param [calendar] Calendar to pull data from (uses the configured {@link #config-calendar}
     * if not provided).
     * @returns {Promise} Promise that resolves when the calendar data is loaded into the store.
     * The resolve function is passed the loaded data if the operation succeeded and `false` otherwise
     * (if no source calendar or the store has faced an error while loading).
     * @internal
     */
    async pullFromCalendar(calendar = this.calendar, silent = false) {
        if (!calendar) {
            return false;
        }
        const
            me                = this,
            { intervalStore } = calendar,
            builtRecords      = new Set(),
            { stopPullingOnError } = me;
        if (calendar === me.calendar) {
            me.suspendAutoPush();
        }
        me.intervalCounter = 0;
        me.exceptionCounter = 1;
        me.weekCounter = 1;
        me._recordByRawInterval.clear();
        me._recordByRawKey.clear();
        // Sort records to ensure intervals are shown with the same color when saving & reopening the editor
        const records = intervalStore.allRecords.slice().sort((a, b) => a.internalId > b.internalId ? 1 : -1);
        for (const interval of records) {
            const record = me.processRawInterval(interval);
            if (record === false) {
                console.warn(
                    `CalendarEditorStore: The "${calendar.name}" calendar interval cannot be handled.`,
                    'Please copy its data and report to https://bryntum.com/forum :\n' +
                    JSON.stringify(interval.toJSON(), undefined, 2)
                );
                if (!silent) {
                    me.trigger('pullFromCalendarError', { calendar, interval });
                }
                if (stopPullingOnError) {
                    if (!silent) {
                        me.trigger('pullFromCalendarStop', { calendar, interval });
                    }
                    return false;
                }
            }
            else {
                builtRecords.add(record);
            }
        }
        const data = [...builtRecords];
        for (const record of data) {
            await record.afterRawIntervalsProcessed?.(me);
        }
        me.data = data;
        me.sanitizeInternalMaps();
        if (me.validateRecordsAfterPulling) {
            me.validateRecords(calendar);
        }
        if (!silent) {
            me.trigger('pullFromCalendar', { calendar });
        }
        if (calendar === me.calendar) {
            me.resumeAutoPush();
        }
        return data;
    }
    /**
     * Pushes the store data to the provided calendar (or to the configured {@link #config-calendar}
     * if not provided).
     * Processes the store records, builds intervals and loads them
     * back into the {@link #config-calendar}.
     * @param [calendar] Calendar to push data to (uses the configured {@link #config-calendar}
     * if not provided).
     * @returns {Promise} Promise that resolves when the store data is loaded into the calendar.
     * The resolve function is passed `true` if the operation succeeded and `false` otherwise
     * (if no target calendar or the store has invalid records).
     * @internal
     */
    async pushToCalendar(calendar = this.calendar, silent = false) {
        if (!calendar || !this.isValid) {
            return false;
        }
        const me = this;
        if (calendar === me.calendar) {
            // stop reacting on the calendar changes
            me.suspendAutoPull();
        }
        // Generate and set new intervals to the target calendar
        const rawIntervals = me.allRecords.flatMap(record => record.isValid ? record.buildRawIntervals() : []);
        await calendar.setIntervals(rawIntervals ?? []);
        if (calendar === me.calendar) {
            // update rawInterval -> interval map
            for (const rawInterval of calendar.intervalStore) {
                me._recordByRawInterval.set(rawInterval.id,
                    me._recordByRawKey.get(rawInterval.compositeCode)
                );
            }
            me.resumeAutoPull();
        }
        if (!silent) {
            me.trigger('pushToCalendar', { calendar });
        }
        return true;
    }
    /**
     * Validate the store records and reports errors to the browser console.
     * @param {SchedulerPro.model.CalendarModel} calendar Calendar to check.
     */
    validateRecords(calendar = this.calendar) {
        const
            prefix      = `CalendarEditorStore: The "${calendar.name}" calendar errors:`,
            errors      = [],
            storeErrors = this.getErrors();
        if (storeErrors) {
            errors.push(storeErrors.map(e => getErrorText(e)).join(', '));
        }
        for (const record of this.allRecords) {
            const recordErrors = record.getErrors();
            if (recordErrors) {
                errors.push(`record #${record.id}: ${recordErrors.map(e => getErrorText(e)).join(', ')}`);
            }
        }
        if (errors.length) {
            const data = this.toJSON();
            console.warn(
                [prefix, ...errors].join('\n • ') +
                (data.length ? '\n\nPlease copy the store data and report to https://bryntum.com/forum :\n' +
                    JSON.stringify(this.toJSON(), null, 2) : '')
            );
        }
    }
    get isValid() {
        return !this.getErrors(1);
    }
    getErrors(limit = 0) {
        const me = this;
        let
            defaultWeekFound = false,
            defaultWeekAvailabilityFound = false,
            errors = new Set();
        for (const record of me.allRecords) {
            const recordErrors = record.getErrors();
            if (recordErrors) {
                errors = new Set([...errors, ...recordErrors]);
            }
            if (record.isCalendarEditorWeekModel) {
                if (!record.isOverride) {
                    // Only allow one week without dates
                    if (defaultWeekFound) {
                        errors.add(me.constructor.errors.errorMultipleDefaultWeeks);
                    }
                    if (record.hasAvailability()) {
                        defaultWeekAvailabilityFound = true;
                    }
                    defaultWeekFound = true;
                }
            }
            if (limit && errors.size > limit) break;
        }
        // A calendar having all time range non-working by default is supposed to provide some availability
        if (me.calendar && !me.calendar.unspecifiedTimeIsWorking && !defaultWeekAvailabilityFound) {
            errors.add(me.constructor.errors.errorNoDefaultWeek);
        }
        return errors.size ? Array.from(errors) : null;
    }
}
CalendarEditorStore._$name = 'CalendarEditorStore';