import AjaxStoreLazyLoadPlugin from '../../../Core/data/plugin/AjaxStoreLazyLoadPlugin.js';
import Delayable from '../../../Core/mixin/Delayable.js';
import DateHelper from '../../../Core/helper/DateHelper.js';
/**
 * @module Scheduler/data/plugin/DateStoreLazyLoadPlugin
 */
// This class keeps track of date ranges.
class DateRanges {
    ranges   = [];
    promises = [];
    addPromise(promise) {
        if (promise?.then) {
            this.promises.push(promise);
            promise.then(() => {
                this.promises.splice(this.promises.indexOf(promise), 1);
            });
        }
    }
    addRange(startDate, endDate, promise) {
        this.addPromise(promise);
        const intersectStart = this.getRange(startDate);
        if (intersectStart?.endDate < endDate) {
            intersectStart.endDate.setTime(endDate.getTime());
            return;
        }
        const intersectEnd = this.getRange(endDate);
        if (intersectEnd?.startDate > startDate) {
            intersectEnd.startDate.setTime(startDate.getTime());
            return;
        }
        startDate = new Date(startDate.getTime());
        endDate   = new Date(endDate.getTime());
        this.ranges.push({ startDate, endDate });
    }
    containsRange(startDate, endDate) {
        return this.ranges.some(r => DateHelper.timeSpanContains(r.startDate, r.endDate, startDate, endDate));
    }
    getRange(date) {
        return this.ranges.filter(r => DateHelper.betweenLesserEqual(date, r.startDate, r.endDate))[0];
    }
}
/**
 * Plugin for Store that handles lazy loading of stores that is dependent on the view's visible time span.
 * @plugin
 * @internal
 * @extends Core/data/plugin/AjaxStoreLazyLoadPlugin
 */
export default class DateStoreLazyLoadPlugin extends AjaxStoreLazyLoadPlugin.mixin(Delayable) {
    static $name = 'DateStoreLazyLoadPlugin';
    // region Configs
    static get pluginConfig() {
        const config = {};
        for (const prop in super.pluginConfig) {
            config[prop] = [...super.pluginConfig[prop]];
        }
        config.assign.push('updateProject');
        // lazyGetAt has no practical functionality for these stores
        const lazyGetAt = config.assign.indexOf('lazyGetAt');
        if (lazyGetAt >= 0) {
            config.assign.splice(lazyGetAt, 1);
        }
        config.before = config.before ?? [];
        config.before.push('getEventsAsArray');
        return config;
    };
    static configurable = {
        bufferUnit            : null,
        bufferAmount          : null,
        dateFormat            : 'YYYY-MM-DD',
        loadFullDateRange     : false,
        loadFullResourceRange : false
    };
    /**
     * In an EventStore or ResourceTimeRangeStore which is configured with
     * {@link Core.data.Store#config-lazyLoad}, the function provided here is called when a combination of the visible
     * date range and the visible range of resources has not yet been loaded. If the ResourceStore is not configured
     * with {@link Core.data.Store#config-lazyLoad}, the resource range will include all the loaded resources. When
     * implementing this, it is expected that what is returned is an object with a `data` property containing the
     * records from `startDate` to `endDate` for a range of resources starting at `startIndex` and with the length
     * specified in the `count` param.
     *
     * Base implementation does nothing, either use AjaxStore which implements it, or create your own subclass with an
     * implementation.
     *
     * ````javascript
     * class MyEventStore extends EventStore {
     *    async requestData(params){
     *       const response = await fetch('https://api.bryntum.com/events/?' + new URLSearchParams(params));
     *       return await response.json();
     *    }
     * }
     * ````
     *
     * @function requestData
     * @param {Object} options
     * @param {Date} options.startDate The start date of the current timespan
     * @param {Date} options.endDate The end date of the current timespan
     * @param {Number} options.startIndex The resource start index
     * @param {Number} options.count The resource count
     * @returns {Promise}
     * @on-owner
     */
    /**
     * If set to `true`, or a config object, this makes the store load new records when needed. When a record that
     * is not already loaded is requested, the {@link #function-requestData} function is called. Please read the
     * [guide](#Grid/guides/data/lazyloading.md) to learn more on how to configure lazy loading.
     * @param {DurationUnit} bufferUnit Used together with bufferAmount to calculate the start and end dates of each load
     * request. The value is added to the current visible start or end date. Defaults to the visible time span length.
     * @param {Number} bufferAmount See `bufferUnit`
     * @param {String} dateFormat The format used to convert `startDate` and `endDate` parameters passed to the
     * load request querystring. Defaults to `YYYY-MM-DD` (e.g. **2024-03-11**). Only used if a
     * {@link Core.data.AjaxStore#config-readUrl} is configured on the store. See {@link Core.helper.DateHelper} for
     * details about formatting dates.
     * @param {Boolean} loadFullResourceRange If the ResourceStore is not lazy loaded, set this to `true` to load
     * events, assignments and/or resource time ranges for all resources with every load request.
     * @prp {Boolean|Object} lazyLoad
     * @on-owner
     */
    // endregion
    afterConstruct() {
        super.afterConstruct(...arguments);
        this.listenToResourceStore();
    }
    // region Store overrides and more
    get project() {
        return this.client.project;
    }
    get resourceStore() {
        return this.project?.resourceStore;
    }
    // Need to know when resource store is set or changed, to add some listeners
    updateProject(project) {
        this.$projectListenersDetacher?.();
        if (project) {
            this.$projectListenersDetacher = project.ion({
                resourceStoreChange : 'listenToResourceStore',
                thisObj             : this
            });
        }
        this.listenToResourceStore();
    }
    listenToResourceStore() {
        // If resource store is also lazyLoad, and is sorted or filtered, it will reload
        // Same should be done for EventStore and ResourceTimeRangeStore
        if (this.resourceStore?.lazyLoad && this.resourceStore.isResourceStore) {
            this.$resourceStoreListenersDetacher?.();
            this.$resourceStoreListenersDetacher = this.resourceStore.ion({
                sort    : 'clearLoaded',
                filter  : 'clearLoaded',
                thisObj : this
            });
        }
    }
    // Only affects stores that mixes GetEventsMixin
    getEventsAsArray({ visibleDateRange, startDate, endDate, fromRowHeight, resourceRecord }) {
        if (!fromRowHeight && visibleDateRange) {
            this.bufferedLazyLoad({
                startDate,
                endDate,
                visibleStart : visibleDateRange.startDate,
                visibleEnd   : visibleDateRange.endDate,
                resourceRecord
            });
        }
    }
    // Checks if there is any unresolved loading promises
    get isLoading() {
        return super.isLoading || Object.values(this.loadQueue).some(value => value.promises.length);
    }
    // In StoreLazyLoadPlugin this throws if store is loading
    // In a "DateStore" indexes don't matter, so no need for not allowing adds/remove while loading
    internalOnBeforeAdd() {}
    // In StoreLazyLoadPlugin this applies a load lock on uncommitted added/removed
    // Don't care about this here
    internalOnChange() {}
    // Same as above
    internalOnCommit() {}
    // endregion
    // region Range calculation
    calculateDateRange({ startDate, endDate, visibleStart, visibleEnd }, resourceIndex) {
        let resourceQueue = this.getResourceQueue(resourceIndex);
        if (visibleStart && visibleEnd && !this.loadFullDateRange) {
            const
                {
                    bufferAmount,
                    bufferUnit,
                    $bufferedResourceRecords,
                    resourceStore
                }        = this,
                bufferMs = bufferUnit && bufferAmount != null ? DateHelper.asMilliseconds(bufferAmount, bufferUnit)
                    : visibleEnd - visibleStart,
                calcDate = (date, ms) => {
                    const newDate = DateHelper.clearTime(new Date(date.getTime() + ms));
                    // Limit to start and end dates
                    if (newDate < startDate) {
                        newDate.setTime(startDate.getTime());
                    }
                    else if (newDate > endDate) {
                        newDate.setTime(endDate.getTime());
                    }
                    return newDate;
                },
                thresholdStart = calcDate(visibleStart, bufferMs / -2),
                thresholdEnd   = calcDate(visibleEnd, bufferMs / 2);
            // Signals to doLazyLoad to abort if incorrect dates
            if (thresholdStart > thresholdEnd) {
                return false;
            }
            // If provided resource contains range, find first not loaded from the buffer
            if (resourceQueue?.containsRange(thresholdStart, thresholdEnd)) {
                let foundNotLoaded = false;
                if ($bufferedResourceRecords?.length > 1) {
                    // i = 1 => 0 is checked a couple of lines above
                    for (let i = 1; i < $bufferedResourceRecords.length; i++) {
                        resourceIndex = resourceStore.indexOf($bufferedResourceRecords[i]);
                        resourceQueue = this.getResourceQueue(resourceIndex);
                        if (!resourceQueue?.containsRange(thresholdStart, thresholdEnd)) {
                            foundNotLoaded = true;
                            break;
                        }
                    }
                }
                if (!foundNotLoaded) {
                    // All resources from the current request has the date range loaded
                    return false;
                }
            }
            // Add buffer to start and end dates, clear time as we only care about dates
            startDate = calcDate(visibleStart, bufferMs * -1);
            endDate   = calcDate(visibleEnd, bufferMs);
        }
        else if (startDate > endDate) {
            return false;
        }
        else {
            startDate = new Date(startDate.getTime());
            endDate   = new Date(endDate.getTime());
        }
        // Narrow the range down to fetch missing (current resource) dates only
        if (resourceQueue) {
            const intersectStart = resourceQueue.getRange(startDate);
            if (intersectStart) {
                startDate.setTime(intersectStart.endDate.getTime());
            }
            else {
                const intersectEnd = resourceQueue.getRange(endDate);
                if (intersectEnd) {
                    endDate.setTime(intersectEnd.startDate.getTime());
                }
            }
        }
        if (startDate - endDate >= 0) {
            return false;
        }
        return { startDate, endDate, resourceIndex };
    }
    calculateResourceRange({ resourceIndex, startDate, endDate }) {
        const { resourceStore, $bufferedResourceRecords } = this;
        // If resources is not lazyLoad, we load events for all resources at once
        let startIndex = 0,
            endIndex   = resourceStore.count - 1;
        // Else we load events for 2 chunks with resources
        if (resourceStore.lazyLoad || (!this.loadFullResourceRange && resourceStore.count)) {
            const
                chunkSize = resourceStore.lazyLoad?.chunkSize ?? this.chunkSize;
            startIndex    = resourceIndex - chunkSize;
            endIndex      = resourceIndex + chunkSize;
            // Since the event (or similar) loading is buffered (RAF), we need to check the buffered requests so to be
            // sure that all resources that were asked for is included
            if ($bufferedResourceRecords?.length > chunkSize) {
                const bottomIndex = resourceStore.indexOf($bufferedResourceRecords.pop());
                if (bottomIndex > endIndex) {
                    endIndex = bottomIndex + chunkSize;
                }
            }
            startIndex = Math.max(startIndex, 0);
            // The range should only include available resources
            while (!resourceStore.records[startIndex] && startIndex < endIndex) {
                startIndex += 1;
            }
            while (!resourceStore.records[endIndex] && startIndex < endIndex) {
                endIndex -= 1;
            }
        }
        // Narrow the resources down by checking if startIndex is already loaded
        while (startIndex < resourceIndex && this.getResourceQueue(startIndex)?.containsRange(startDate, endDate)) {
            startIndex += 1;
        }
        // Narrow the resources down by checking if endIndex is already loaded
        while (endIndex > resourceIndex && this.getResourceQueue(endIndex)?.containsRange(startDate, endDate)) {
            endIndex -= 1;
        }
        return { startIndex, count : endIndex - startIndex + 1 };
    }
    // endregion
    // region LoadQueue
    getResourceQueue(resourceIndex) {
        if (resourceIndex != null) {
            const { resourceStore } = this;
            // If resourceStore is tree, we're queueing on id instead
            if (!this.client.isTimeRangeStore && resourceStore?.lazyLoad && resourceStore.isTree) {
                resourceIndex = resourceStore.records[resourceIndex].id;
            }
            return this.loadQueue[resourceIndex];
        }
    }
    addToQueue({ startDate, endDate, startIndex = 0, count = 1, promise, resourceIds }) {
        const
            { loadQueue } = this,
            add           = i => (loadQueue[i] || (loadQueue[i] = new DateRanges())).addRange(startDate, endDate, promise);
        // The queue is either based on resource indexes or resource ids (if ResourceStore is a TreeStore)
        if (resourceIds) {
            resourceIds.forEach(add);
        }
        else {
            for (let i = startIndex; i < startIndex + count; i++) {
                add(i);
            }
        }
    }
    // endregion
    // region Loading data
    // Executes lazyLoad after a certain time/event
    bufferedLazyLoad({ resourceRecord }) {
        const me = this;
        if (!resourceRecord) {
            me.doLazyLoad(...arguments);
        }
        if (me.$bufferedResourceRecords) {
            me.$bufferedResourceRecords.push(resourceRecord);
        }
        else {
            me.$bufferedResourceRecords = [resourceRecord];
            me.requestAnimationFrame(() => {
                me.doLazyLoad(...arguments);
                me.$bufferedResourceRecords = null;
            });
        }
    }
    async doLazyLoad(params) {
        const
            me                     = this,
            { client, dateFormat } = me,
            { resourceStore }      = me.project,
            resourceTree           = !client.isTimeRangeStore && resourceStore.lazyLoad && resourceStore.isTree,
            resourceIndex          = params.resourceIndex ??
                (params.resourceRecord ? resourceStore.indexOf(params.resourceRecord) : 0);
        // calculateDateRange will check if the dates asked for passes a calculated threshold
        // It will modify the startDate and endDate depending on what is already loaded and what the
        // buffer settings are. It will return false if already loaded or load in progress
        params = me.calculateDateRange(params, resourceIndex);
        // Abort if already loaded/in progress, or resourceStore is tree and is loading
        if (!client.requestData || params === false || (resourceTree && resourceStore.isLoading) || params.resourceIndex < 0) {
            return;
        }
        // TimeRangeStore does not care about resources
        if (!client.isTimeRangeStore) {
            const range = me.calculateResourceRange(params);
            // If resourceStore is tree and lazyLoaded, we provide resourceId instead of an index range
            if (resourceTree) {
                const
                    resources = resourceStore.getRange(range.startIndex, range.startIndex + range.count + 1),
                    toLoad    = resources.filter(r => !r.isPhantom && !me.loadQueue[r.id]?.containsRange(params.startDate, params.endDate));
                params.resourceIds = toLoad.map(r => r.id);
            }
            else  {
                Object.assign(params, range);
                // Pass along any filters from the ResourceStore
                if (resourceStore.filterParamName && resourceStore.isFiltered) {
                    // AjaxStore, need to encode the params with the default function
                    if (client.readUrl) {
                        let { filterParamName } = resourceStore;
                        // If the "DateStore" is configured with identical filterParamName as the ResourceStore, we need
                        // to use something else
                        if (client.filterParamName === filterParamName) {
                            filterParamName = 'resource' + filterParamName;
                        }
                        params[filterParamName] = resourceStore.encodeFilterParams(resourceStore.filters);
                    }
                    else {
                        // Not an AjaxStore, just pass the filters along
                        params.resourceFilters = resourceStore.filters.values;
                    }
                }
                // Pass along any sorters from the ResourceStore
                if (resourceStore.remoteSort && resourceStore.isSorted) {
                    const { sorters } = resourceStore;
                    // AjaxStore, need to encode the params with the default function
                    if (client.readUrl) {
                        let { sortParamName } = resourceStore;
                        // If the "DateStore" is configured with identical sortParamName as the ResourceStore, we need
                        // to use something else
                        if (client.sortParamName === sortParamName) {
                            sortParamName = 'resource' + sortParamName;
                        }
                        params[sortParamName] = resourceStore.encodeSorterParams(sorters);
                    }
                    else {
                        // Not an AjaxStore, just pass the sorters along
                        params.resourceFilters = sorters;
                    }
                }
            }
        }
        // Cleanup
        delete params.resourceIndex;
        // A lazyLoad AssignmentStore will be loaded with same params as EventStore
        // Same goes for TimeRangeStore
        if (client.isEventStore && !client.crudManager?.transport?.load?.url) {
            me.loadAssignmentStore(params);
            me.project?.timeRangeStore?.lazyLoad?.doLazyLoad(params);
        }
        me.triggerLazyLoadStart(params);
        // If there is a readUrl configured, it is likely we're using default AjaxStore loading mechanism. Then we need
        // to convert startDate and endDate to better formatted strings (AjaxStore simply concats everything with
        // toString.
        let requestParams = params;
        if (client.readUrl && dateFormat)  {
            requestParams = {
                ...params,
                startDate : DateHelper.format(params.startDate, dateFormat),
                endDate   : DateHelper.format(params.endDate, dateFormat)
            };
        }
        else if (!client.readUrl) {
            // Fetch any sorters or filters
            client.buildRemoteParams(requestParams);
        }
        const promise = client.requestData(requestParams);
        // Mark these ranges as fetched at this point
        // This will prevent additional loading request for same ranges
        me.addToQueue({ ...params, promise });
        // Wait for the events to load
        const { data } = await promise;
        me.addData(data, params);
        me.triggerLazyLoadEnd(params, data);
    }
    addData(data, { resourceIds, startDate, endDate } = {}) {
        if (data?.length && resourceIds) {
            // If server response contains events for resources not asked for, those will be marked as fetched as well
            const additionalResources = data.filter(e => !resourceIds.includes(e.resourceId));
            if (additionalResources.length) {
                this.addToQueue({ startDate, endDate, resourceIds : additionalResources.map(d => d.resourceId) });
            }
        }
        super.addData(data);
    }
    processAddedRecords() {
        const { client } = this;
        if (client.isEventStore && client.$processResourceIds) {
            client.processResourceIds();
        }
    }
    async loadAssignmentStore(params) {
        const
            { assignmentStore } = this.project,
            { lazyLoad }        = assignmentStore;
        if (lazyLoad) {
            lazyLoad.triggerLazyLoadStart();
            const promise = assignmentStore.requestData(params);
            lazyLoad.addToQueue({ ...params, promise });
            const { data } = await promise;
            lazyLoad.addData(data);
            lazyLoad.triggerLazyLoadEnd();
        }
    }
    load(params = {}) {
        // Hook for the view (LazyLoadView) to add params before programmatically calling store.load
        this.beforeLoadCall?.(params);
        this.clearLoaded();
        return this.doLazyLoad(params);
    }
    // endregion
    doDestroy() {
        this.$projectListenersDetacher?.();
        this.$resourceStoreListenersDetacher?.();
        super.doDestroy();
    }
};
DateStoreLazyLoadPlugin._$name = 'DateStoreLazyLoadPlugin';