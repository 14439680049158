import ColumnStore from '../../Grid/data/ColumnStore.js';
import ActionColumn from '../../Grid/column/ActionColumn.js';
/**
 * @module Gantt/column/TaskInfoColumn
 */
/**
 * A calculated column which contains various information about the task.
 *
 * Currently, the only information shown is a presence of the unresolved, "postponed" conflict, which can be created
 * by using the {@link Gantt.model.ProjectModel#field-allowPostponedConflicts} and {@link Gantt.model.ProjectModel#field-autoPostponedConflicts}
 * configuration options.
 *
 * For a task with such conflict, a warning icon will be rendered, clicking on which will initiate the conflict resolution.
 *
 * @extends Grid/column/Column
 * @classtype info
 * @column
 */
export default class TaskInfoColumn extends ActionColumn {
    static $name = 'TaskInfoColumn';
    static type = 'info';
    static isGanttColumn = true;
    static get defaults() {
        return {
            field   : 'hasPostponedOwnConstraintConflict',
            align   : 'center',
            text    : 'L{info}',
            width   : 50,
            editor  : null,
            actions : [
                {
                    cls     : 'b-postponed-conflict b-icon b-icon-warning',
                    visible : ({ record }) => record.hasPostponedOwnConstraintConflict,
                    tooltip : () => 'L{TaskInfoColumn.postponedConflictTooltip}',
                    onClick : ({ record }) => record.resolvePostponedConflict()
                }
            ]
        };
    }
    canFillValue = () => false;
}
ColumnStore.registerColumnType(TaskInfoColumn);
TaskInfoColumn._$name = 'TaskInfoColumn';