import VerticalLayout from '../../Scheduler/eventlayout/VerticalLayout.js';
/**
 * @module SchedulerPro/eventlayout/ProVerticalLayout
 */
/**
 * Assists with event layout in vertical mode, you can implement a custom layout using the {@link #config-layoutFn}.
 * Example usage:
 *
 * ```javascript
 * new SchedulerPro({
 *     mode : 'vertical',
 *     eventLayout : {
 *         layoutFn(items, resource, scheduler) {
 *             items.forEach(item => {
 *                 item.top = Math.round(Math.random() * 100);
 *                 item.height = Math.round(Math.random() * 100);
 *             });
 *
 *             return 150; // The resource column width
 *         }
 *     }
 * })
 * ```
 * @mixes Scheduler/eventlayout/VerticalLayout
 */
export default class ProVerticalLayout extends VerticalLayout {
    static get configurable() {
        return {
            /**
             * Type of vertical layout. Supported values are `mixed`, `pack` and `none`.
             * @config {'mixed'|'pack'|'none'}
             */
            type : null,
            /**
             * Supply a function to manually layout events. It accepts event layout data and should set `top`
             * and `height` for every provided data item (left and width are calculated according to the event start
             * date and duration). The function should return the total row height in pixels.
             *
             * If you need a reference to the SchedulerPro instance, you can get that from the function scope (arrow
             * function doesn't work here):
             *
             * ```javascript
             * new SchedulerPro({
             *     mode : 'vertical',
             *     eventLayout : {
             *         layoutFn(items, resource, scheduler) {
             *             items.forEach(item => {
             *                 item.left = Math.round(Math.random() * 100);
             *                 item.width = Math.round(Math.random() * 100);
             *             });
             *
             *             return 150;
             *         }
             *     }
             * })
             * ```
             *
             * @config {Function}
             * @param {EventRenderData[]} events Unordered array of event render data, sorting may be required
             * @param {SchedulerPro.model.ResourceModel} resource The resource for which the events are being laid out
             * @param {SchedulerPro.view.SchedulerPro} scheduler The SchedulerPro instance
             * @returns {Number} The resource column width
             */
            layoutFn : null
        };
    }
    // Custom layout
    applyLayout(events, resourceRecord, parentEventRecord) {
        const
            me            = this,
            { scheduler } = me,
            eventLayout   = scheduler.getEventLayout(resourceRecord, parentEventRecord);
        if (eventLayout.type === 'layoutFn') {
            const
                instanceMeta   = resourceRecord.instanceMeta(scheduler),
                newColumnWidth = eventLayout.layoutFn(events, resourceRecord, scheduler);
            if (newColumnWidth && newColumnWidth !== instanceMeta.columnWidth) {
                const oldWidth = instanceMeta.columnWidth ?? scheduler.getResourceWidth(resourceRecord);
                instanceMeta.columnWidth = newColumnWidth;
                // In order to update ColumnLines position
                scheduler.onVisibleResourceColumnChange(newColumnWidth, oldWidth);
            }
        }
        else {
            return super.applyLayout(events, resourceRecord, parentEventRecord);
        }
    }
}
ProVerticalLayout._$name = 'ProVerticalLayout';