/**
 * @module Grid/feature/RowReorder
 */
import GridFeatureManager from './GridFeatureManager.js';
import DragHelper from '../../Core/helper/DragHelper.js';
import InstancePlugin from '../../Core/mixin/InstancePlugin.js';
import DomHelper from '../../Core/helper/DomHelper.js';
import Delayable from '../../Core/mixin/Delayable.js';
import Rectangle from '../../Core/helper/util/Rectangle.js';
import GlobalEvents from '../../Core/GlobalEvents.js';
/**
 * Object with information about a tree position
 * @typedef {Object} RecordPositionContext
 * @property {Core.data.Model} record Tree node
 * @property {Number} parentIndex Index among parents children
 * @property {String|Number} parentId Parent node's id
 */
/**
 * Allows user to reorder rows by dragging them. To get notified about row reorder listen to `change` event
 * on the grid {@link Core.data.Store store}.
 *
 * This feature is **disabled** by default. For info on enabling it, see {@link Grid.view.mixin.GridFeatures}.
 * This feature is **enabled** by default for Gantt.
 *
 * {@inlineexample Grid/feature/RowReorder.js}
 *
 * If the grid is set to {@link Grid.view.Grid#config-readOnly}, reordering is disabled. Inside all event listeners you
 * have access a `context` object which has a `record` property (the dragged record).
 *
 * ## Usage when grouping
 * Note that row reordering is not possible in a grid which is grouped using the {@link Grid.feature.TreeGroup}
 * feature because when this is in use, all records presented to the UI are linked records, **not** the
 * real records.
 *
 * Row reordering is also disabled when the store is grouped using the {@link Grid.feature.Group} feature and
 * the "group by" field is an array value. In this case records may also be present in more than one group
 * and so some records will be linked records, **not** the real records.
 *
 * ## Validation
 * You can validate the drag drop flow by listening to the `gridrowdrag` event. Inside this listener you have access to
 * the `index` property which is the target drop position. For trees you get access to the `parent` record and `index`,
 * where index means the child index inside the parent.
 *
 * You can also have an async finalization step using the {@link #event-gridRowBeforeDropFinalize}, for showing a
 * confirmation dialog or making a network request to decide if drag operation is valid (see code snippet below)
 *
 * ```javascript
 * features : {
 *     rowReorder : {
 *         showGrip : true
 *     },
 *     listeners : {
 *        gridRowDrag : ({ context }) => {
 *           // Here you have access to context.insertBefore, and additionally context.parent for trees
 *        },
 *
 *        gridRowBeforeDropFinalize : async ({ context }) => {
 *           const result = await MessageDialog.confirm({
 *               title   : 'Please confirm',
 *               message : 'Did you want the row here?'
 *           });
 *
 *           // true to accept the drop or false to reject
 *           return result === MessageDialog.yesButton;
 *        }
 *    }
 * }
 * ```
 *
 * Note, that this feature uses the concept of "insert before" when choosing a drop point in the data. So the dropped
 * record's position is *before the visual next record's position*.
 *
 * This may look like a pointless distinction, but consider the case when a Store is filtered. The record *above* the
 * drop point may have several filtered out records below it. When unfiltered, the dropped record will be *below* these
 * because of the "insert before" behaviour.
 *
 * ## Behavior with multiple subgrids
 *
 * For grids with multiple subgrids, row reordering is only enabled for the first subgrid.
 *
 * ## Dragging rows between different grid
 *
 * You can enable dragging to different `Grid` instances by enabling the {@link #config-allowCrossGridDrag}. This lets
 * you both move and copy (using Ctrl/Meta key) records to other grids. You can also configure a special `transferData`
 * method to take full control over what happens on drop onto another grid.
 *
 * NOTE: This feature cannot be used simultaneously with the `enableTextSelection` config.
 *
 * {@note}When Store has {@link Core.data.Store#config-lazyLoad}, row reordering might cause data inconsistency and is not recommended.{/@note}
 *
 * @extends Core/mixin/InstancePlugin
 * @demo Grid/rowreordering
 * @classtype rowReorder
 * @feature
 */
export default class RowReorder extends Delayable(InstancePlugin) {
    //region Events
    /**
     * Fired before dragging starts, return false to prevent the drag operation.
     * @preventable
     * @event gridRowBeforeDragStart
     * @param {Grid.view.GridBase} source
     * @param {Object} context
     * @param {Core.data.Model[]} context.records The dragged row records
     * @param {MouseEvent|TouchEvent} event
     * @on-owner
     */
    /**
     * Fired when dragging starts.
     * @event gridRowDragStart
     * @param {Grid.view.GridBase} source
     * @param {Object} context
     * @param {Core.data.Model[]} context.records The dragged row records
     * @param {MouseEvent|TouchEvent} event
     * @on-owner
     */
    /**
     * Fired while the row is being dragged, in the listener function you have access to `context.insertBefore` a grid /
     * tree record, and additionally `context.parent` (a TreeNode) for trees. You can signal that the drop position is
     * valid or invalid by setting `context.valid = false;`
     * @event gridRowDrag
     * @param {Grid.view.GridBase} source
     * @param {Object} context
     * @param {Boolean} context.valid Set this to true or false to indicate whether the drop position is valid.
     * @param {Core.data.Model} context.insertBefore The record to insert before (`null` if inserting at last position of a parent node)
     * @param {Core.data.Model} context.parent The parent record of the current drop position (only applicable for trees)
     * @param {Core.data.Model[]} context.records The dragged row records
     * @param {MouseEvent} event
     * @on-owner
     */
    /**
     * Fired before the row drop operation is finalized. You can return false to abort the drop operation, or a
     * Promise yielding `true` / `false` which allows for asynchronous abort (e.g. first show user a confirmation dialog).
     * @event gridRowBeforeDropFinalize
     * @preventable
     * @async
     * @param {Grid.view.GridBase} source
     * @param {Object} context
     * @param {Boolean} context.valid Set this to true or false to indicate whether the drop position is valid
     * @param {Core.data.Model} context.insertBefore The record to insert before (`null` if inserting at last position of a parent node)
     * @param {Core.data.Model} context.parent The parent record of the current drop position (only applicable for trees)
     * @param {Core.data.Model[]} context.records The dragged row records
     * @param {RecordPositionContext[]} context.oldPositionContext An array of objects with information about the previous tree position.
     * Objects contain the `record`, and its original `parentIndex` and `parentId` values
     * @param {MouseEvent} event
     * @on-owner
     */
    /**
     * Fired after the row drop operation has completed, regardless of validity
     * @event gridRowDrop
     * @param {Grid.view.GridBase} source
     * @param {Object} context
     * @param {Boolean} context.valid true or false depending on whether the drop position was valid
     * @param {Core.data.Model} context.insertBefore The record to insert before (`null` if inserting at last position of a parent node)
     * @param {Core.data.Model} context.parent The parent record of the current drop position (only applicable for trees)
     * @param {Core.data.Model[]} context.records The dragged row records
     * @param {RecordPositionContext[]} context.oldPositionContext An array of objects with information about the previous tree position.
     * Objects contain the record, and its original `parentIndex` and `parentId` values
     * @param {MouseEvent} event
     * @on-owner
     */
    /**
     * Fired when a row drag operation is aborted
     * @event gridRowDragAbort
     * @param {Grid.view.GridBase} source
     * @param {Object} context
     * @param {Core.data.Model[]} context.records The dragged row records
     * @param {MouseEvent} event
     * @on-owner
     */
    //endregion
    //region Init
    static $name = 'RowReorder';
    static configurable = {
        /**
         * Set to `true` to show a grip icon on the left side of each row.
         * @config {Boolean}
         */
        showGrip : null,
        /**
         * Set to `true` to only allow reordering by the {@link #config-showGrip} config.
         * @config {Boolean}
         */
        gripOnly : null,
        /**
         * If hovering over a parent node for this period of a time in a tree, the node will expand.
         * @config {Number}
         */
        hoverExpandTimeout : 1000,
        /**
         * The amount of milliseconds to wait after a touchstart, before a drag gesture will be allowed to start.
         * @config {Number}
         * @default
         */
        touchStartDelay : 300,
        /**
         * Enables creation of parents by dragging a row and dropping it onto a leaf row. Only works in a Grid with
         * a tree store. This option is `true` by default in the Gantt product.
         * @config {Boolean}
         */
        dropOnLeaf : null,
        /**
         * The CSS class to add to the icon element indicating it is a copy operation.
         * @config {String}
         */
        copyIconCls : 'b-icon b-icon-add',
        /**
         * Enables dragging rows to other grid instances. By default, this will remove from the source grid `store` and
         * add the dragged record(s) to the target grid `store`. If you would like to implement another transfer of the
         * data (e.g. to copy instead of move), you can provide an object with a `transferData` method.
         * @config {Boolean|Object} allowCrossGridDrag
         * @property {Function} allowCrossGridDrag.transferData A function which decides what should happen to the
         * dragged records upon drop. It receives a single context object with information about the drag drop state.
         * @property {Grid.view.GridBase} allowCrossGridDrag.transferData.sourceGrid The source grid
         * @property {Grid.view.GridBase} allowCrossGridDrag.transferData.targetGrid the target grid
         * @property {Core.data.Model[]} allowCrossGridDrag.transferData.records The dragged records
         * @property {Core.data.Model} allowCrossGridDrag.transferData.insertBefore The record to insert before
         */
        allowCrossGridDrag : false,
        /**
         * An object used to configure the internal {@link Core.helper.DragHelper} class
         * @config {DragHelperConfig}
         */
        dragHelperConfig : null,
        /**
         * Set to `true` to preserve sorters after a drop operation, if that operation leads to the store still being
         * sorted.
         * @config {Boolean}
         * @default false
         */
        preserveSorters : null,
        // Can be used in tests to disable drop animation to speed them up
        animateDrop : true,
        targetGrid : null
    };
    construct(grid, config) {
        this.grid = grid;
        // dropOnLeaf is enabled by default in Gantt as of v6.0
        if (grid.isGanttBase && !('dropOnLeaf' in config)) {
            config.dropOnLeaf = true;
        }
        super.construct(...arguments);
    }
    doDestroy() {
        this.dragHelper?.destroy();
        super.doDestroy();
    }
    /**
     * Initialize drag & drop (called on first paint)
     * @private
     */
    init() {
        const
            me                           = this,
            { grid, allowCrossGridDrag } = me;
        me.dragHelper = DragHelper.new({
            name               : 'rowReorder',
            cloneTarget        : true,
            dragThreshold      : 10,
            proxyTopOffset     : 10,
            targetSelector     : '.b-grid-row',
            lockX              : !allowCrossGridDrag,
            allowDropOutside   : true,
            outerElement       : me.targetSubGridElement,
            touchStartDelay    : me.touchStartDelay,
            isElementDraggable : me.isElementDraggable.bind(me),
            dragWithin         : allowCrossGridDrag ? grid.floatRoot : grid.bodyContainer,
            scrollManager      : grid.scrollManager,
            positioning        : 'inset',
            monitoringConfig   : {
                scrollables : [
                    {
                        element   : grid.scrollable.element,
                        direction : 'vertical'
                    }
                ]
            },
            setXY(element, x, y) {
                const { context } = this;
                if (!context.started) {
                    const
                        elementRect       = Rectangle.from(context.element, this.dragWithin),
                        pointerDownOffset = context.startPageY - globalThis.pageYOffset - context.element.getBoundingClientRect().top;
                    this._elementHeight = elementRect.height;
                    // manually position the row a bit below the cursor
                    y = elementRect.top + pointerDownOffset + this.proxyTopOffset;
                    this._startScrollY = grid.scrollable.y;
                }
                // Never allow proxy element to go below scrollable element´s viewport (breaks ScrollManager)
                const newY = allowCrossGridDrag ? y : Math.min(grid.scrollable.element.scrollHeight - this._elementHeight - 1, y + grid.scrollable.y - this._startScrollY);
                DomHelper.setTopInsetInlineStart(element, newY, x);
            },
            // Since parent nodes can expand after hovering, meaning original drag start position now refers to a different point in the tree
            ignoreSamePositionDrop : false,
            createProxy(element) {
                const
                    clone     = element.cloneNode(true),
                    container = document.createElement('div'),
                    copyIcon  = document.createElement('i');
                container.classList.add('b-row-reorder-proxy');
                copyIcon.className = 'b-row-proxy-copy ' + me.copyIconCls;
                clone.removeAttribute('id');
                // The containing element will be positioned instead, and sized using CSS
                clone.style.transform = clone.style.insetInlineStart = clone.style.top = '';
                clone.style.width = element.offsetWidth + 'px';
                container.appendChild(copyIcon);
                container.appendChild(clone);
                if (grid.selectedRecords.length > 1) {
                    const clone2 = clone.cloneNode(true);
                    clone2.classList.add('b-row-dragging-multiple');
                    container.appendChild(clone2);
                }
                DomHelper.removeClsGlobally(container, 'b-selected', 'b-hover', 'b-focused');
                return container;
            },
            internalListeners : {
                beforedragstart : 'onBeforeDragStart',
                dragstart       : 'onDragStart',
                drag            : 'onDrag',
                drop            : 'onDrop',
                abort           : 'onAbort',
                reset           : 'onReset',
                prio            : 10000, // To ensure our listener is run before the relayed listeners (for the outside world)
                thisObj         : me
            }
        }, me.dragHelperConfig);
        grid.relayEvents(me.dragHelper, ['beforeDragStart', 'dragStart', 'drag'], 'gridRow', true);
        me.dropIndicator = DomHelper.createElement({
            className : 'b-row-drop-indicator'
        });
        me.dropOverTargetCls = ['b-row-reordering-target', 'b-hover'];
    }
    //endregion
    //region Plugin config
    static get pluginConfig() {
        return {
            chain : ['beforeRenderCell', 'onInternalPaint']
        };
    }
    get targetSubGridElement() {
        const targetSubGrid = this.grid.regions[0];
        return this.grid.subGrids[targetSubGrid].element;
    }
    beforeRenderCell({ cellElement, column, record, cellCls }) {
        const { grid } = this;
        cellCls['b-row-reorder-grip'] = this.showGrip && this.enabled && !record.isSpecialRow && column === grid.columns.visibleColumns[0];
    }
    //endregion
    //region Events (drop)
    isElementDraggable(el, event) {
        const hasRowResizeAndCloseToEdges = this.client.features.rowResize?.isEventOverResizeHandle(event);
        if (!hasRowResizeAndCloseToEdges && !el.closest('.b-grid-cell .b-widget')) {
            if (this.gripOnly) {
                const firstCell = el.closest('.b-grid-cell:first-child');
                // Event is in the first cell. Now check if it's on the handle
                if (firstCell) {
                    const
                        gripperStyle = getComputedStyle(firstCell, ':before'),
                        offsetX      = this.grid.rtl ? firstCell.getBoundingClientRect().width - event.borderOffsetX : event.borderOffsetX,
                        onGrip       = offsetX <= parseFloat(gripperStyle.width);
                    // Prevent drag select if mousedown on grip, would collide with reordering
                    // (reset by GridSelection)
                    if (onGrip) {
                        this.client.preventDragSelect = true;
                    }
                    return onGrip;
                }
            }
            else {
                return true;
            }
        }
    }
    onBeforeDragStart({ event, source, context }) {
        const
            me        = this,
            { grid }  = me,
            subGridEl = me.targetSubGridElement;
        // Only dragging enabled in the leftmost grid section
        if (event.target.classList.contains('b-rowexpander-shadowroot-container') || me.disabled || grid.readOnly || grid.isTreeGrouped || !subGridEl.contains(context.element)) {
            return false;
        }
        const startRecord = context.startRecord = grid.getRecordFromElement(context.element);
        // Don't allow starting drag on a readOnly record nor on special rows
        if (startRecord.readOnly || startRecord.isSpecialRow) {
            return false;
        }
        context.originalRowTop = grid.rowManager.getRowFor(startRecord).top;
        // Don't select row if checkboxOnly is set
        if (!grid.selectionMode.checkboxOnly) {
            if (source.startEvent.pointerType === 'touch') {
                // Touchstart doesn't focus/navigate on its own, so we do it at the last moment before drag start
                if (!grid.isSelected(startRecord)) {
                    grid.selectRow({
                        record         : startRecord,
                        addToSelection : false
                    });
                }
            }
            else if (!grid.isSelected(startRecord) && !event.shiftKey && !event.ctrlKey) {
                // If record is not selected and shift/ctrl is not pressed then select single row
                grid.selectRow({
                    record : startRecord
                });
            }
        }
        // Read-only records will not be moved
        const selectedRecords = grid.selectedRecords.filter(r => !r.readOnly);
        context.records       = [startRecord];
        // If clicked record is selected, move all selected records
        if (selectedRecords.includes(startRecord)) {
            context.records.push(...selectedRecords.filter(r => r !== startRecord));
            context.records.sort((r1, r2) => grid.store.indexOf(r1) - grid.store.indexOf(r2));
        }
        return true;
    }
    onDragStart({ context }) {
        const
            me                                 = this,
            { grid }                           = me,
            { cellEdit, cellMenu, headerMenu } = grid.features;
        me.targetGrid = grid;
        if (cellEdit) {
            me.cellEditDisabledState = cellEdit.disabled;
            cellEdit.disabled        = true; // prevent editing from being started through keystroke during row reordering
        }
        cellMenu?.hideContextMenu?.(false);
        headerMenu?.hideContextMenu?.(false);
        const focusedCell = context.element.querySelector('.b-focused');
        focusedCell?.classList.remove('b-focused');
        context.element.firstElementChild.classList.remove('b-selected', 'b-hover');
    }
    resolveGrid(event, context) {
        let { target } = event;
        // Can't detect target under a touch event
        if (/^touch/.test(event.type)) {
            const center = Rectangle.from(context.element, null, true).center;
            target = DomHelper.elementFromPoint(center.x, center.y);
        }
        return this.client.constructor.fromElement(target, w => w.isGridBase);
    }
    onDrag({ context, event }) {
        const
            me          = this,
            { clientY } = event;
        let grid = me.grid;
        if (me.allowCrossGridDrag) {
            grid = me.resolveGrid(event, context);
            me.targetGrid = grid;
            if (!grid) {
                context.valid = false;
                return;
            }
        }
        const { store, rowManager } = grid;
        let valid = true,
            row   = rowManager.getRowAt(clientY),
            overRecord,
            dataIndex,
            after,
            over,
            insertBefore;
        if (row) {
            const
                rowTop        = row.top + grid.scrollable.element.getBoundingClientRect().top - grid.scrollable.y,
                quarter       = row.height / 4,
                topQuarter    = rowTop + quarter,
                middleY       = rowTop + row.height / 2,
                bottomQuarter = rowTop + quarter * 3;
            dataIndex  = row.dataIndex;
            overRecord = store.storage.getAt(dataIndex);
            // If Tree and pointer is in quarter 2 and 3, add as child of hovered row
            if (store.isTree) {
                over = (overRecord.isParent || me.dropOnLeaf) && clientY > topQuarter && clientY < bottomQuarter;
            }
            else if (store.isGrouped) {
                over = overRecord.isGroupHeader && overRecord.meta.collapsed;
            }
            // Else, drop after row below if mouse is in bottom half of hovered row
            after = !over && event.clientY >= middleY;
        }
        // User dragged below last row or above the top row.
        else {
            if (event.pageY < grid._bodyRectangle.y) {
                dataIndex  = 0;
                overRecord = store.first;
                after      = false;
            }
            else {
                dataIndex  = store.count - 1;
                overRecord = store.last;
                after      = true;
            }
            row = rowManager.getRow(dataIndex);
        }
        if (overRecord === me.overRecord && me.after === after && me.over === over) {
            context.valid = me.reorderValid;
            // nothing's changed
            return;
        }
        if (me.overRecord !== overRecord) {
            rowManager.getRowById(me.overRecord)?.removeCls(me.dropOverTargetCls);
        }
        me.overRecord = overRecord;
        me.after      = after;
        me.over       = over;
        if (
            // Don't allow dropping on a readOnly grid
            me.targetGrid.readOnly ||
            // Hovering the dragged record. This is a no-op.
            // But still gather the contextual data.
            overRecord === context.startRecord ||
            // Not allowed to drop above topmost group header or below a collapsed header
            (!after && !over && dataIndex === 0 && store.isGrouped) ||
            // Not allowed to drop after last collapsed group
            (after && overRecord && overRecord.isGroupHeader && overRecord.meta.collapsed && store.indexOf(overRecord) === store.count - 1)
        ) {
            valid = false;
        }
        if (store.isTree) {
            if (overRecord) {
                insertBefore = after ? overRecord.nextSibling : overRecord;
                // For trees, prevent moving a parent into its own hierarchy
                if (context.records.some(rec => rec.contains(overRecord))) {
                    valid = false;
                }
                context.parent = valid && over ? overRecord : overRecord.parent;
                me.clearTimeout(me.hoverTimer);
                if (overRecord && overRecord.isParent && !overRecord.isExpanded(store)) {
                    me.hoverTimer = me.setTimeout(() => grid.expand(overRecord), me.hoverExpandTimeout);
                }
            }
            else {
                context.parent = grid.store.rootNode;
            }
        }
        else {
            insertBefore = after ? store.storage.getAt(dataIndex + 1) : overRecord;
        }
        if (row) {
            // Provide visual clue to user of the drop position
            // In FF (in tests) it might not have had time to redraw rows after scroll before getting here
            DomHelper.setTranslateY(me.dropIndicator, Math.max(row.top + (after ? row.element.getBoundingClientRect().height : 0), 1));
            row?.toggleCls(me.dropOverTargetCls, valid && over);
        }
        else if (grid.store.count === 0) {
            DomHelper.setTranslateY(me.dropIndicator, 0);
        }
        // If hovering results in same dataIndex, regardless of what row is hovered, and parent has not changed
        if (!over && dataIndex === store.indexOf(context.startRecord) + (after ? -1 : 1) &&
            context.parent && context.startRecord.parent === context.parent) {
            valid = false;
        }
        // Don't show dropIndicator if holding over a row
        me.dropIndicator.style.visibility = over ? 'hidden' : 'visible';
        me.dropIndicator.classList.toggle('b-drag-invalid', !valid);
        // Public property used for validation
        context.insertBefore = insertBefore;
        context.valid = me.reorderValid = valid;
    }
    /**
     * Handle drop
     * @private
     */
    async onDrop(event) {
        const
            me                   = this,
            { grid, targetGrid } = me,
            { context }          = event,
            { ctrlKeyDown }      = GlobalEvents;
        context.valid = context.valid && me.reorderValid;
        event.source = grid;
        if (context.valid) {
            context.async = true;
            if (targetGrid.store.isTree) {
                // For tree scenario, add context about previous positions of dragged tree nodes
                context.oldPositionContext = context.records.map((record) => ({
                    record,
                    parentId    : record.parent?.id,
                    parentIndex : record.parentIndex
                }));
            }
            // Outside world provided us one or more Promises to wait for
            if (await grid.trigger('gridRowBeforeDropFinalize', event) === false) {
                context.valid = false;
            }
            me.animateDrop && await me.dragHelper.animateProxyTo(me.dropIndicator, { align : 'l0-l0' });
            await me.finalizeReorder(context, ctrlKeyDown);
        }
        // already dropped the node, don't have to expand any node hovered anymore
        // (cancelling expand action after timeout)
        me.clearTimeout(me.hoverTimer);
        grid.trigger('gridRowDrop', event);
        me.overRecord = me.after = me.over = me.targetGrid = null;
    }
    onAbort(event) {
        event.source = this.client;
        this.client.trigger('gridRowDragAbort', event);
        this.targetGrid = null;
    }
    async finalizeReorder(context, isCopy) {
        const
            me                             = this,
            { client, allowCrossGridDrag } = me,
            targetGrid                     = context.targetGrid || client,
            { store, focusedCell }         = targetGrid,
            { parent }                     = context;
        let { records, insertBefore } = context;
        if (!allowCrossGridDrag && context.valid) {
            context.valid = !records.some(rec => !store.includes(rec));
        }
        if (context.valid) {
            const sorterFn = me.preserveSorters && store.isSorted && store.sorterFn;
            let result;
            if (isCopy) {
                records = records.map(rec => rec.copy());
            }
            // Make sure we insert in correct order if asked to preserve sorters
            if (sorterFn) {
                records.sort(sorterFn);
            }
            if (store.isTree) {
                // Remove any selected child records of parent nodes
                records = records.filter(record => !record.parent || record.bubbleWhile(parent => !records.includes(parent), true));
                result = await parent.tryInsertChild(records, me.over ? parent.children?.[0] : insertBefore);
                // remove reorder cls from preview parent element dropped
                targetGrid.rowManager.forEach(r => r.removeCls(me.dropOverTargetCls));
                // If parent wasn't expanded, expand it if it now has children
                if (!parent.isExpanded() && parent.children?.length) {
                    targetGrid.expand(parent);
                }
                context.valid = result !== false;
            }
            else if (store.isGrouped && me.over) {
                if (isCopy) {
                    store.insert(store.indexOf(insertBefore), records);
                }
                else {
                    store.move(records, store.getNext(insertBefore));
                }
            }
            else {
                // When dragging multiple rows, ensure the insertBefore reference is not one of the selected records
                if (records.length > 1) {
                    while (insertBefore && records.includes(insertBefore)) {
                        insertBefore = store.getNext(insertBefore, false, true);
                    }
                }
                if (store === client.store) {
                    if (isCopy) {
                        store.insert(store.indexOf(insertBefore), records);
                    }
                    else {
                        store.move(records, insertBefore);
                    }
                }
                else if (me.allowCrossGridDrag.transferData) {
                    await me.allowCrossGridDrag.transferData({
                        sourceGrid : client,
                        targetGrid,
                        records,
                        insertBefore
                    });
                    targetGrid.focusCell({
                        grid   : targetGrid,
                        record : records[0]
                    });
                }
                else {
                    if (!isCopy) {
                        client.store.remove(records);
                    }
                    store.insert(insertBefore ? store.indexOf(insertBefore) : store.count, records);
                }
            }
            if (focusedCell?._rowIndex >= 0 && targetGrid === client) {
                targetGrid._focusedCell = null;
                // Refresh focused cell
                targetGrid.focusCell({
                    grid     : client,
                    record   : focusedCell.record,
                    columnId : focusedCell.columnId
                });
            }
            let clearSorters = true;
            // Optionally determine if store is still sorted after drop, can happen when moving inside a group of sorted
            // records, or when sorting by WBS in Gantt. If not, clear sorters below
            if (context.valid && sorterFn) {
                const
                    firstRecord = records[0],
                    lastRecord  = records[records.length - 1],
                    beforeFirst = firstRecord.previousSibling,
                    afterLast   = lastRecord.nextSibling;
                if (
                    (!beforeFirst || sorterFn(beforeFirst, firstRecord) <= 0) &&
                    (!afterLast || sorterFn(lastRecord, afterLast) <= 0)
                ) {
                    clearSorters = false;
                }
            }
            if (context.valid && clearSorters) {
                store.clearSorters();
            }
        }
        context.finalize(context.valid);
        targetGrid.element.classList.remove('b-row-reordering');
    }
    /**
     * Clean up on reset
     * @private
     */
    onReset() {
        const
            me                   = this,
            { targetGrid, grid } = me,
            cellEdit             = grid.features.cellEdit;
        grid.element.classList.remove('b-row-reordering');
        if (cellEdit) {
            cellEdit.disabled = me.cellEditDisabledState;
        }
        DomHelper.removeClsGlobally(
            grid.element,
            ...me.dropOverTargetCls
        );
        grid.scrollManager.stopMonitoring();
        if (targetGrid && grid !== targetGrid) {
            targetGrid.element.classList.remove('b-row-reordering');
            targetGrid.scrollManager.stopMonitoring();
            DomHelper.removeClsGlobally(
                targetGrid.element,
                ...me.dropOverTargetCls
            );
        }
    }
    //endregion
    //region Render
    onInternalPaint({ firstPaint }) {
        // columns shown, hidden or reordered
        if (!this._initialized && this.grid.regions.length > 0) {
            this._initialized = true;
            this.init();
        }
    }
    //endregion
    updateShowGrip(show) {
        if (this.enabled) {
            this.grid.columns.visibleColumns[0].refreshCells();
        }
    }
    updateDisabled(disabled, was) {
        super.updateDisabled(disabled, was);
        if (this.dragHelper) {
            this.dragHelper.disabled = disabled;
        }
        if (this.showGrip) {
            this.grid.columns.visibleColumns[0].refreshCells();
        }
    }
    updateTargetGrid(targetGrid, was) {
        if (was) {
            was.element.classList.remove('b-row-reordering');
            was.scrollManager.stopMonitoring();
        }
        if (this.dragHelper.isDragging) {
            this.dragHelper.context.targetGrid = targetGrid;
        }
        if (targetGrid) {
            targetGrid.element.classList.add('b-row-reordering');
            targetGrid.bodyContainer.appendChild(this.dropIndicator);
            targetGrid.scrollManager.startMonitoring({
                scrollables : [
                    {
                        element   : targetGrid.scrollable.element,
                        direction : 'vertical'
                    }
                ]
            });
        }
        else {
            this.dropIndicator.remove();
        }
    }
    get isDragging() {
        return this.dragHelper.isDragging;
    }
}
RowReorder.featureClass = '';
RowReorder._$name = 'RowReorder'; GridFeatureManager.registerFeature(RowReorder, false);
