import Base from '../../../Core/Base.js';
import RecurrenceConfirmationPopup from '../recurrence/RecurrenceConfirmationPopup.js';
/**
 * @module Scheduler/view/mixin/RecurringEvents
 */
/**
 * A mixin that adds functionality related to recurring events to the Scheduler and Calendar widgets.
 *
 * The main purpose of the code in here is displaying a {@link Scheduler.view.recurrence.RecurrenceConfirmationPopup
 * special confirmation} when the user interacts with recurring events and their occurrences
 * (via drag-drop/resize/delete etc.). See this in action in the `recurrence` demo found in Calendar and Scheduler.
 *
 * ## Recurring event data structure
 *
 * Recurring timespans can have a {@link Scheduler/model/mixin/RecurringTimeSpan#field-recurrenceRule} specified defining the repetition pattern (based on
 * the [RFC-5545](https://tools.ietf.org/html/rfc5545#section-3.3.10). You can also provide {@link Scheduler/model/mixin/RecurringTimeSpan#field-exceptionDates}
 * to the data to exclude certain dates. See sample data below for an event record which repeats weekly on Tuesdays and
 * Thursdays at 12pm-1pm (except March 28, 2024).
 *
 * ```javascript
 * {
 *     id: 7,
 *     startDate: '2021-10-12T12:00:00',
 *     endDate: '2021-10-12T13:00:00',
 *     name: 'Lunch',
 *     resourceId: 123,
 *     recurrenceRule: 'FREQ=WEEKLY;BYDAY=TU,TH',
 *     exceptionDates: ['2024-03-28']
 * }
 * ```
 * To enable this functionality in the Scheduler, set {@link #config-enableRecurringEvents} to `true`.
 * @mixin
 * @demo Scheduler/recurrence
 */
export default Target => class RecurringEvents extends (Target || Base) {
    static $name = 'RecurringEvents';
    static configurable = {
        /**
         * Enables showing occurrences of recurring events across the scheduler's time axis. If you want to disable
         * the recurrence popup, you can choose set the `defaultAction` to `future` to affect all future
         * occurrences, or `single` to just affect the currently selected event.
         *
         * Enables extra recurrence UI fields in the system-provided event editor (not in Scheduler Pro's task editor).
         * @config {Boolean|Object}
         * @property {'single'|'future'} defaultAction
         * @default
         * @category Scheduled events
         */
        enableRecurringEvents : false,
        /**
         * The confirmation dialog shown when a recurring event is edited.
         *
         * The user is given the opportunity to either change just the occurrence being edited, or
         * all occurrences from that point onwards.
         *
         * The {Scheduler.view.recurrence.RecurrenceConfirmationPopup} may be reconfigured from its default
         * configuration by specifying a config object here.
         * @prp {Scheduler.view.recurrence.RecurrenceConfirmationPopup}
         * @accepts {RecurrenceConfirmationPopupConfig}
         */
        recurrenceConfirmationPopup : {
            $config : ['lazy'],
            value   : {
                type : 'recurrenceconfirmation'
            }
        }
    };
    construct(config) {
        super.construct(config);
        this.ion({
            beforeEventDropFinalize   : 'onRecurrableBeforeEventDropFinalize',
            beforeEventResizeFinalize : 'onRecurrableBeforeEventResizeFinalize',
            beforeAssignmentDelete    : 'onRecurrableAssignmentBeforeDelete'
        });
    }
    changeRecurrenceConfirmationPopup(recurrenceConfirmationPopup, oldRecurrenceConfirmationPopup) {
        // Widget.reconfigure reither reconfigures an existing instance, or creates a new one, or,
        // if the configuration is null, destroys the existing instance.
        if (recurrenceConfirmationPopup) {
            return RecurrenceConfirmationPopup.reconfigure(oldRecurrenceConfirmationPopup, RecurrenceConfirmationPopup.mergeConfigs({
                owner : this
            }, recurrenceConfirmationPopup), this);
        }
    }
    findRecurringEventToConfirmDelete(eventRecords) {
        // show confirmation if we deal with at least one recurring event (or its occurrence)
        // and if the record is not being edited by event editor (since event editor has its own confirmation)
        return eventRecords.find(eventRecord => eventRecord.supportsRecurring && (eventRecord.isRecurring || eventRecord.isOccurrence));
    }
    onRecurrableAssignmentBeforeDelete({ assignmentRecords, context }) {
        const
            eventRecords = assignmentRecords.map(as => as.event),
            eventRecord  = this.findRecurringEventToConfirmDelete(eventRecords);
        if (this.enableRecurringEvents && eventRecord) {
            this.recurrenceConfirmationPopup.confirm({
                actionType : 'delete',
                eventRecord,
                changerFn() {
                    context.finalize(true);
                },
                cancelFn() {
                    context.finalize(false);
                }
            });
            return false;
        }
    }
    onRecurrableBeforeEventDropFinalize({ context }) {
        if (this.enableRecurringEvents) {
            const
                { eventRecords } = context,
                recurringEvents = eventRecords.filter(eventRecord => eventRecord.supportsRecurring && (eventRecord.isRecurring || eventRecord.isOccurrence));
            if (recurringEvents.length) {
                context.async = true;
                this.recurrenceConfirmationPopup.confirm({
                    actionType  : 'update',
                    eventRecord : recurringEvents[0],
                    changerFn() {
                        context.finalize(true);
                    },
                    cancelFn() {
                        context.finalize(false);
                    }
                });
            }
        }
    }
    onRecurrableBeforeEventResizeFinalize({ context }) {
        if (this.enableRecurringEvents) {
            const
                { eventRecord } = context,
                isRecurring     = eventRecord.supportsRecurring && (eventRecord.isRecurring || eventRecord.isOccurrence);
            if (isRecurring) {
                context.async = true;
                this.recurrenceConfirmationPopup.confirm({
                    actionType : 'update',
                    eventRecord,
                    changerFn() {
                        context.finalize(true);
                    },
                    cancelFn() {
                        context.finalize(false);
                    }
                });
            }
        }
    }
    // Make sure occurrence cache is up-to-date when reassigning events
    onAssignmentChange({ action, records : assignments, changes }) {
        // Ignore changes coming from engine normalizing the eventId/resourceId => event/resource
        if (action === 'update' && changes.event && changes.resource && Object.keys(changes).length === 2) {
            return;
        }
        if (action !== 'dataset' && Array.isArray(assignments)) {
            for (const assignment of assignments) {
                if (assignment.event?.isRecurring && !assignment.event.isBatchUpdating) {
                    assignment.event.removeOccurrences(this.eventStore);
                }
            }
        }
    }
    /**
     * Returns occurrences of the provided recurring event across the date range of this Scheduler.
     * @param  {Scheduler.model.TimeSpan} recurringEvent Recurring event for which occurrences should be retrieved.
     * @returns {Scheduler.model.TimeSpan[]} Array of the provided timespans occurrences.
     *
     * __Empty if the passed event is not recurring, or has no occurrences in the date range.__
     *
     * __If the date range encompasses the start point, the recurring event itself will be the first entry.__
     * @category Data
     */
    getOccurrencesFor(recurringEvent) {
        return this.eventStore.getOccurrencesForTimeSpan(recurringEvent, this.timeAxis.startDate, this.timeAxis.endDate);
    }
    /**
     * Internal utility function to remove events. Used when pressing [DELETE] or [BACKSPACE] or when clicking the
     * delete button in the event editor. Triggers a preventable `beforeEventDelete` or `beforeAssignmentDelete` event.
     *
     * This function is asynchronous because it potentially has to show a {@link Scheduler.view.recurrence.RecurrenceConfirmationPopup}
     * which prompts the user to confirm the operation.
     *
     * In these cases, the Promise is resolved when the user clicks the desired outcome.
     * @param {Scheduler.model.EventModel[]|Scheduler.model.AssignmentModel[]} eventRecords Records to remove
     * @param {Function} [callback] Optional callback executed after triggering the event but before deletion
     * @returns {Boolean} Returns `false` if the operation was prevented, otherwise `true`
     * @internal
     * @fires beforeEventDelete
     * @fires beforeAssignmentDelete
     * @async
     */
    async removeEvents(eventRecords, callback = null, popupOwner = this) {
        const me = this;
        if (!me.readOnly && eventRecords.length) {
            const context = {
                finalize(removeRecord = true) {
                    if (callback) {
                        callback(removeRecord);
                    }
                    if (removeRecord !== false) {
                        if (eventRecords.some(record => record.isOccurrence || record.event?.isOccurrence)) {
                            eventRecords.forEach(record => record.isOccurrenceAssignment ? record.event.remove() : record.remove());
                        }
                        else {
                            const store = eventRecords[0].isAssignment ? me.assignmentStore : me.eventStore;
                            store.remove(eventRecords);
                        }
                    }
                }
            };
            let shouldFinalize;
            if (eventRecords[0].isAssignment) {
                /**
                 * Fires before an assignment is removed. Can be triggered by user pressing [DELETE] or [BACKSPACE] or
                 * by the event editor. Can for example be used to display a custom dialog to confirm deletion, in which
                 * case records should be "manually" removed after confirmation:
                 *
                 * ```javascript
                 * scheduler.on({
                 *     async beforeAssignmentDelete({
                 *         eventRecords,
                 *         context
                 *     }) {
                 *         const result = await MessageDialog.confirm({
                 *             title   : 'Please confirm',
                 *             message : 'Do you want to delete this assignment?'
                 *         });
                 *         context.finalize(result === MessageDialog.yesButton);
                 *         // Prevent default behaviour
                 *         return false;
                 *     }
                 * });
                 * ```
                 *
                 * @event beforeAssignmentDelete
                 * @param {Scheduler.view.Scheduler} source  The Scheduler instance
                 * @param {Scheduler.model.EventModel[]} eventRecords  The records about to be deleted
                 * @param {Object} context  Additional removal context:
                 * @param {Function} context.finalize  Function to call to finalize the removal.
                 *      Used to asynchronously decide to remove the records or not. Provide `false` to the function to
                 *      prevent the removal.
                 * @param {Boolean} [context.finalize.removeRecords = true]   Provide `false` to the function to prevent
                 *      the removal.
                 * @preventable
                 */
                shouldFinalize = me.trigger('beforeAssignmentDelete', { assignmentRecords : eventRecords, context });
            }
            else {
                /**
                 * Fires before an event is removed. Can be triggered by user pressing [DELETE] or [BACKSPACE] or by the
                 * event editor. Return `false` to immediately veto the removal (or a `Promise` yielding `true` or `false`
                 * for async vetoing).
                 *
                 * Can for example be used to display a custom dialog to confirm deletion, in which case
                 * records should be "manually" removed after confirmation:
                 *
                 * ```javascript
                 * scheduler.on({
                 *     async beforeEventDelete({
                 *         eventRecords,
                 *         context
                 *     }) {
                 *         const result = await MessageDialog.confirm({
                 *             title   : 'Please confirm',
                 *             message : 'Do you want to delete this task?'
                 *         });
                 *         context.finalize(result === MessageDialog.yesButton);
                 *         // Prevent default behaviour
                 *         return false;
                 *     }
                 * });
                 * ```
                 *
                 * @event beforeEventDelete
                 * @param {Scheduler.view.Scheduler} source The Scheduler instance
                 * @typings source -> {Scheduler.view.Scheduler|any}
                 * @param {Scheduler.model.EventModel[]} eventRecords  The records about to be deleted
                 * @param {Object} context  Additional removal context:
                 * @param {Function} context.finalize  Function to call to finalize the removal.
                 *      Used to asynchronously decide to remove the records or not. Provide `false` to the function to
                 *      prevent the removal.
                 * @param {Boolean} [context.finalize.removeRecords = true]  Provide `false` to the function to prevent
                 *      the removal.
                 * @preventable
                 * @async
                 */
                shouldFinalize = await me.trigger('beforeEventDelete', { eventRecords, context });
            }
            if (shouldFinalize !== false) {
                const recurringEventRecord = eventRecords.find(eventRecord => eventRecord.isRecurring || eventRecord.isOccurrence);
                if (recurringEventRecord) {
                    me.recurrenceConfirmationPopup.owner = popupOwner;
                    return (await me.recurrenceConfirmationPopup.confirm({
                        actionType  : 'delete',
                        eventRecord : recurringEventRecord,
                        changerFn() {
                            context.finalize(true);
                        },
                        cancelFn() {
                            context.finalize(false);
                        }
                    }));
                }
                else {
                    context.finalize(true);
                }
                return true;
            }
        }
        return false;
    }
    // This does not need a className on Widgets.
    // Each *Class* which doesn't need 'b-' + constructor.name.toLowerCase() automatically adding
    // to the Widget it's mixed in to should implement this.
    get widgetClass() {}
};
