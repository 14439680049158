import AjaxStore from '../../Core/data/AjaxStore.js';
import ResourceUtilizationModel from '../model/ResourceUtilizationModel.js';
import { AbstractPartOfProjectStoreMixin } from '../../Engine/quark/store/mixin/AbstractPartOfProjectStoreMixin.js';
/**
 * @module SchedulerPro/data/ResourceUtilizationStore
 */
/**
 * A store representing {@link SchedulerPro/view/ResourceUtilization} view records.
 * This store accepts a model class inheriting from {@link SchedulerPro/model/ResourceUtilizationModel}.
 *
 * The store is a tree of nodes representing resources on the root level with
 * sub-nodes representing corresponding resource assignments.
 * The store tracks changes made in the {@link #config-project} stores and rebuilds its content automatically.
 * Thus the project config is mandatory and has to be provided.
 *
 * @extends Core/data/AjaxStore
 */
export default class ResourceUtilizationStore extends AbstractPartOfProjectStoreMixin.derive(AjaxStore) {
    static $name = 'ResourceUtilizationStore';
    static configurable = {
        modelClass : ResourceUtilizationModel,
        /**
         * @hideconfigs lazyLoad
         */
        /**
         * @hideproperties lazyLoad
         */
        /**
         * @hideevents lazyLoadStarted, lazyLoadEnded
         */
        /**
         * Project instance to retrieve resources and assignments data from.
         * @config {SchedulerPro.model.ProjectModel} project
         */
        project : null,
        syncDataOnLoad : true,
        transformFlatData : true,
        tree : true,
        resourceValues : {},
        assignmentValues : {},
        reapplyFilterOnAdd : true
    };
    // Cannot use `static properties = {}`, new Map would pollute the prototype
    static get properties() {
        return {
            _modelByOrigin : new Map()
        };
    }
    updateProject(project) {
        this.setResourceStore(project?.resourceStore);
        this.setAssignmentStore(project?.assignmentStore);
        this.setEventStore(project?.eventStore);
        this.fillStoreFromProject();
    }
    setResourceStore(store) {
        this.detachListeners('resourceStore');
        store?.ion({
            name            : 'resourceStore',
            // we can start reacting on removal early
            removePreCommit : this.onResourceStoreDataChanged,
            change          : this.onResourceStoreDataChanged,
            thisObj         : this
        });
    }
    setEventStore(store) {
        this.detachListeners('eventStore');
        store?.ion({
            name    : 'eventStore',
            update  : this.onEventUpdate,
            change  : this.onEventChange,
            thisObj : this
        });
    }
    setAssignmentStore(store) {
        this.detachListeners('assignmentStore');
        store?.ion({
            name    : 'assignmentStore',
            change  : this.onAssignmentsChange,
            thisObj : this
        });
    }
    onResourceStoreDataChanged(event) {
        // 'move' action triggers a remove event first, we wait for the 'add' - no need to fill twice
        if (event.isMove && event.action === 'remove') {
            return;
        }
        this.fillStoreFromProject();
    }
    onAssignmentsChange() {
        this.fillStoreFromProject();
    }
    onEventChange({ action }) {
        if (action === 'removeall') {
            this.fillStoreFromProject();
        }
    }
    onEventUpdate({ record, changes }) {
        if ('name' in changes) {
            for (const assignment of record.assigned) {
                const assignmentWrapper = this.getModelByOrigin(assignment);
                assignmentWrapper.set('name', record.name);
            }
        }
    }
    readDataFromOrigin(origin) {
        let result;
        const { modelClass } = this;
        if (!origin.isSpecialRow) {
            if (origin.isResourceModel) {
                result = {
                    [modelClass.idField] : 'resource-' + origin.id,
                    ...(this.resourceValues || {})
                };
            }
            // use assignment only if it has a resource
            else if (origin.isAssignmentModel && origin.resource && origin.event) {
                result = {
                    [modelClass.idField]       : 'assignment-' + origin.id,
                    [modelClass.parentIdField] : 'resource-' + origin.resource.id,
                    ...(this.assignmentValues || {})
                };
            }
            if (result) {
                Object.assign(result, { origin }, modelClass.mapOriginValues(origin));
            }
        }
        return result;
    }
    readDataFromProject() {
        const
            { _project : project } = this,
            result                 = [];
        if (project) {
            const
                emptyArray = [],
                resourceWrappers = project.resourceStore.flatMap(resource => this.readDataFromOrigin(resource) || emptyArray),
                assignmentWrappers = project.assignmentStore.flatMap(assignment => this.readDataFromOrigin(assignment) || emptyArray);
            result.push(...resourceWrappers, ...assignmentWrappers);
        }
        return result;
    }
    fillStoreFromProject() {
        const me = this;
        me.data = me.readDataFromProject();
        /**
         * Fires when store completes synchronization with original (Event/Resource/Assignment) stores
         * @event fillFromProject
         * @internal
         */
        me.trigger('fillFromProject');
    }
    remove() {
        const removed = super.remove(...arguments);
        // sanitize internal origin->wrapper Map
        removed?.forEach(record => {
            this._modelByOrigin.delete(record.origin);
        });
        return removed;
    }
    removeAll() {
        super.removeAll(...arguments);
        this._modelByOrigin.clear();
    }
    getModelByOrigin(origin) {
        return this._modelByOrigin.get(origin);
    }
    setModelByOrigin(origin, model) {
        return this._modelByOrigin.set(origin, model);
    }
}
ResourceUtilizationStore._$name = 'ResourceUtilizationStore';