import GridCellEdit from '../../Grid/feature/CellEdit.js';
import GridFeatureManager from '../../Grid/feature/GridFeatureManager.js';
import TransactionalFeature from '../../Scheduler/feature/mixin/TransactionalFeature.js';
/**
 * @module SchedulerPro/feature/CellEdit
 */
/**
 * Extends the {@link Grid.feature.CellEdit} to encapsulate SchedulerPro functionality.
 *
 * This feature is **enabled** by default.
 *
 * @extends Grid/feature/CellEdit
 *
 * @classtype cellEdit
 * @feature
 *
 * @typings Grid.feature.CellEdit -> Grid.feature.GridCellEdit
 */
export default class CellEdit extends TransactionalFeature(GridCellEdit) {
    static $name = 'CellEdit';
    async startEditing(cellContext = {}) {
        const me = this;
        let result;
        // If any transactional feature is active at the moment, block editing start. Edit should be finished first.
        if (me.client.isTransactionalFeatureActive) {
            result = false;
        }
        else {
            // First wait for queue to finish to start new transaction for the current feature
            await me.startFeatureTransaction();
            result = await super.startEditing(cellContext);
            if (!result) {
                await me.rejectFeatureTransaction();
            }
        }
        return result;
    }
    async finishEditing() {
        const result = await super.finishEditing();
        if (result) {
            await this.finishFeatureTransaction?.();
        }
        return result;
    }
    cancelEditing(silent = false, triggeredByEvent) {
        this.rejectFeatureTransaction();
        super.cancelEditing(silent, triggeredByEvent);
    }
}
CellEdit._$name = 'CellEdit'; GridFeatureManager.registerFeature(CellEdit, true, 'SchedulerPro');
