var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ProposedOrPreviousValueOf, UnsafeProposedOrPreviousValueOf, Write } from "../../../../ChronoGraph/chrono/Effect.js";
import { Mixin } from '../../../../ChronoGraph/class/BetterMixin.js';
import { prototypeValue } from '../../../../ChronoGraph/util/Helpers.js';
import DateHelper from '../../../../Core/helper/DateHelper.js';
import Localizable from '../../../../Core/localization/Localizable.js';
import { ConflictResolution, ConstraintInterval, ConstraintIntervalDescription } from '../../../chrono/Conflict.js';
import { model_field } from '../../../chrono/ModelFieldAtom.js';
import "../../../localization/En.js";
import { ConstraintType, Direction } from "../../../scheduling/Types.js";
import { format } from '../../../util/Functions.js';
import { HasChildrenMixin } from "../scheduler_basic/HasChildrenMixin.js";
import { HasDependenciesMixin } from "../scheduler_basic/HasDependenciesMixin.js";
import { ConstrainedByDependenciesEventScheduleMixin } from "./ConstrainedByDependenciesEventScheduleMixin.js";
import { ConstrainedEarlyEventMixin } from './ConstrainedEarlyEventMixin.js';
import { DateConstraintInterval, HasDateConstraintMixin } from "./HasDateConstraintMixin.js";
//---------------------------------------------------------------------------------------------------------------------
/**
 * This mixin adds support for scheduling event ASAP, by dependencies. All it does is
 * create the constraint interval for every incoming dependency. See [[ConstrainedEarlyEventMixin]] for
 * more details about constraint-based scheduling.
 *
 * The supported dependency types are listed in this enum: [[DependencyType]]
 */
export class ConstrainedByDependenciesEventMixin extends Mixin([ConstrainedEarlyEventMixin, HasDependenciesMixin, HasChildrenMixin, HasDateConstraintMixin], (base) => {
    class ConstrainedByDependenciesEventMixin extends base {
        constructor() {
            super(...arguments);
            this.isInitialConstraintCalculation = 0b11;
        }
        /**
         * The method is used during forward (from start to end) scheduling. It defines whether the provided dependency
         * should constrain the successor or not. If the method returns `true` the dependency constrains the successor and does not do that when `false` returned.
         * By default the method returns `true` if the dependency is [[SchedulerProDependencyMixin.active|active]]
         * and if this event is [[inactive|active]] (or both this event and the successor are [[inactive]]).
         *
         * @param dependency Dependency to consider.
         * @returns `true` if the dependency should constrain successor, `false` if not.
         */
        *shouldPredecessorAffectScheduling(dependency) {
            return true;
        }
        /**
         * The method is used during backward (from end to start) scheduling. It defines whether the provided dependency
         * should constrain the predecessor or not. If the method returns `true` the dependency constrains the predecessor and does not do that when `false` returned.
         * By default the method returns `true` if the dependency is [[SchedulerProDependencyMixin.active|active]]
         * and if this event is [[inactive|active]] (or both this event and the predecessor are [[inactive]]).
         *
         * @param dependency Dependency to consider.
         * @returns `true` if the dependency should constrain successor, `false` if not.
         */
        *shouldSuccessorAffectScheduling(dependency) {
            return true;
        }
        *shouldAutoSetConstraintOnLoad() {
            return this.project.autoSetConstraints
                && (yield this.$.incomingDeps).size === 0
                && (yield this.$.childEvents).size === 0
                && !(yield this.$.manuallyScheduled);
        }
        *calculateConstraintType() {
            let constraintType;
            // if `autoSetConstraints` is enabled, we set a constraintType to pin tasks when data is being loaded
            if (this.isInitialConstraintCalculation & 0b01) {
                constraintType = yield UnsafeProposedOrPreviousValueOf(this.$.constraintType);
                if (constraintType == null && (yield* this.shouldAutoSetConstraintOnLoad())) {
                    constraintType = ConstraintType.StartNoEarlierThan;
                }
                else {
                    constraintType = yield* super.calculateConstraintType();
                }
            }
            else {
                constraintType = yield* super.calculateConstraintType();
            }
            // automatic constraint setting should happen only during initial load
            this.isInitialConstraintCalculation &= 0b10;
            return constraintType;
        }
        *calculateConstraintDate(Y) {
            let constraintDate;
            if (this.isInitialConstraintCalculation & 0b10) {
                constraintDate = yield UnsafeProposedOrPreviousValueOf(this.$.constraintDate);
                // If this is a new task, and autoSetConstraints is enabled, we set a constraintDate to pin tasks when
                // data is being loaded
                if (constraintDate == null && (yield* this.shouldAutoSetConstraintOnLoad())) {
                    constraintDate = yield UnsafeProposedOrPreviousValueOf(this.$.startDate);
                }
                else {
                    constraintDate = yield* super.calculateConstraintDate(Y);
                }
            }
            else {
                constraintDate = yield* super.calculateConstraintDate(Y);
            }
            // automatic constraint setting should happen only during initial load
            this.isInitialConstraintCalculation &= 0b01;
            return constraintDate;
        }
        // Only add duration 1 to the task if:
        // 1. It has duration as null
        // 2. Not a milestone
        // 3. _showUnscheduledTasks flag is a truthy
        *calculateDuration() {
            if (
            // @ts-ignore
            this.project._showUnscheduledTasks &&
                (yield ProposedOrPreviousValueOf(this.$.duration)) == null &&
                (!(yield ProposedOrPreviousValueOf(this.$.startDate)) || !(yield ProposedOrPreviousValueOf(this.$.endDate)))) {
                if ((yield this.$.incomingDeps).size > 0)
                    return 1;
            }
            return yield* super.calculateDuration();
        }
        changeDateConstraintIntervalOnConflict(interval, type, sourceSchedule) {
            // the 2nd condition (`interval.owner`) is supposedly always true, but still checking it for future-proofing
            if ((interval instanceof DateConstraintInterval) && interval.owner === this) {
                if (sourceSchedule.direction === Direction.Forward) {
                    if (type === 'start' && interval.owner.constraintType === ConstraintType.StartNoLaterThan) {
                        return interval.copyWith({
                            startDate: interval.endDate,
                            endDate: null
                        });
                    }
                    if (type === 'end' && interval.owner.constraintType === ConstraintType.FinishNoLaterThan) {
                        return interval.copyWith({
                            startDate: interval.endDate,
                            endDate: null
                        });
                    }
                }
                else {
                    if (type === 'start' && interval.owner.constraintType === ConstraintType.StartNoEarlierThan) {
                        return interval.copyWith({
                            startDate: null,
                            endDate: interval.startDate
                        });
                    }
                    if (type === 'end' && interval.owner.constraintType === ConstraintType.FinishNoEarlierThan) {
                        return interval.copyWith({
                            startDate: null,
                            endDate: interval.startDate
                        });
                    }
                }
            }
            return interval;
        }
        *onEmptyEffectiveInterval(type, sourceSchedule) {
            // re-calculate effective resulting interval gathering intersection history
            const effectiveInterval = type === 'start'
                ? yield* sourceSchedule.doCalculateEffectiveStartDateInterval(true)
                : yield* sourceSchedule.doCalculateEffectiveEndDateInterval(true);
            let intervals = Array.from(effectiveInterval.intersectionOf);
            const info = this.getOwnConstraintConflictInfo(intervals);
            if (info.kind === 'no_own_constraint_conflict') {
                return yield* super.onEmptyEffectiveInterval(type, sourceSchedule);
            }
            else {
                const hasPostponedOwnConstraintConflict = yield this.$.hasPostponedOwnConstraintConflict;
                const autoPostponedConflicts = yield this.getProject().$.autoPostponedConflicts;
                // the idea here is that we enter the auto-resolution code below only if we already have a marker
                // about auto-resolution, or if auto-resolution is enabled
                // but, unless the "force resolution" flag is enabled, which should trigger a conflict
                if ((hasPostponedOwnConstraintConflict || autoPostponedConflicts) && !this.forceResolutionOfPostponedConflict) {
                    const startDateConstraintIntervals = (yield sourceSchedule.$.startDateConstraintIntervals)
                        .concat(yield this.$.startDateConstraintIntervals)
                        .filter(interval => !(interval instanceof DependencyConstraintInterval))
                        .map(interval => this.changeDateConstraintIntervalOnConflict(interval, 'start', sourceSchedule));
                    const endDateConstraintIntervals = (yield sourceSchedule.$.endDateConstraintIntervals)
                        .concat(yield this.$.endDateConstraintIntervals)
                        .filter(interval => !(interval instanceof DependencyConstraintInterval))
                        .map(interval => this.changeDateConstraintIntervalOnConflict(interval, 'end', sourceSchedule));
                    const newEffectiveConstraintInterval = yield* sourceSchedule.calculateEffectiveConstraintInterval(type === 'start', startDateConstraintIntervals, endDateConstraintIntervals, false);
                    if (newEffectiveConstraintInterval.isIntervalEmpty()) {
                        return yield* super.onEmptyEffectiveInterval(type, sourceSchedule);
                    }
                    else {
                        // marking task as having an auto-resolved conflict
                        if (!hasPostponedOwnConstraintConflict) {
                            yield Write(this.$.hasPostponedOwnConstraintConflict, true);
                        }
                        return { kind: 'resolved', value: newEffectiveConstraintInterval };
                    }
                    return yield* super.onEmptyEffectiveInterval(type, sourceSchedule);
                }
                this.forceResolutionOfPostponedConflict = false;
                return yield* super.onEmptyEffectiveInterval(type, sourceSchedule);
            }
            return yield* super.onEmptyEffectiveInterval(type, sourceSchedule);
        }
        getOwnConstraintConflictInfo(intervals) {
            const ownDateConstraintIntervals = intervals.filter(interval => (interval instanceof DateConstraintInterval) && interval.owner === this);
            const dependencyIntervals = intervals.filter(interval => interval instanceof DependencyConstraintInterval);
            if (ownDateConstraintIntervals.length === 1 && dependencyIntervals.length > 0) {
                const ownConstraintInterval = ownDateConstraintIntervals[0];
                const hasConflict = dependencyIntervals.some(dependencyConstraintInterval => dependencyConstraintInterval
                    .intersect(ownConstraintInterval)
                    .isIntervalEmpty());
                if (hasConflict) {
                    return {
                        kind: 'dependency_with_own_constraint',
                        ownConstraintInterval,
                        dependencyIntervals
                    };
                }
            }
            return { kind: 'no_own_constraint_conflict' };
        }
    }
    // @ts-ignore
    ConstrainedByDependenciesEventMixin.scheduleMixinClass = Mixin([base.scheduleMixinClass, ConstrainedByDependenciesEventScheduleMixin], base => base);
    __decorate([
        model_field({ type: 'boolean' })
    ], ConstrainedByDependenciesEventMixin.prototype, "inactive", void 0);
    return ConstrainedByDependenciesEventMixin;
}) {
}
/**
 * Base class for dependency interval resolutions.
 */
export class BaseDependencyResolution extends Localizable(ConflictResolution) {
    getDescription() {
        const { dependency } = this, { type, fromEvent, toEvent } = dependency;
        return format(this.L('L{descriptionTpl}'), this.L('L{DependencyType.long}')[type], fromEvent.name || fromEvent.id, toEvent.name || toEvent.id);
    }
}
BaseDependencyResolution.$name = 'BaseDependencyResolution';
/**
 * Dependency resolution removing the dependency.
 */
export class RemoveDependencyResolution extends BaseDependencyResolution {
    /**
     * Resolves the conflict by removing the dependency.
     */
    resolve() {
        this.dependency.remove();
    }
}
RemoveDependencyResolution.$name = 'RemoveDependencyResolution';
/**
 * Dependency resolution deactivating the dependency.
 */
export class DeactivateDependencyResolution extends BaseDependencyResolution {
    /**
     * Resolves the conflict by deactivating the dependency.
     */
    resolve() {
        this.dependency.active = false;
    }
}
DeactivateDependencyResolution.$name = 'DeactivateDependencyResolution';
/**
 * Description builder for a [[DependencyConstraintInterval|dependency constraint interval]].
 */
export class DependencyConstraintIntervalDescription extends ConstraintIntervalDescription {
    static getDescriptionParameters(interval) {
        const dependency = interval.owner;
        return [
            DateHelper.format(interval.startDate, this.L('L{dateFormat}')),
            DateHelper.format(interval.endDate, this.L('L{dateFormat}')),
            this.L('L{DependencyType.long}')[dependency.type],
            dependency.fromEvent.name,
            dependency.toEvent.name
        ];
    }
}
DependencyConstraintIntervalDescription.$name = 'DependencyConstraintIntervalDescription';
/**
 * Constraint interval applied by a dependency.
 *
 * In case for a conflict the class [[getResolutions|suggests]] two resolution options:
 * either [[RemoveDependencyResolution|removing]] or [[DeactivateDependencyResolution|deactivating]] the dependency.
 */
export class DependencyConstraintInterval extends ConstraintInterval {
    get isDependencyConstraintInterval() {
        return true;
    }
    isAffectedByTransaction(transaction) {
        const dependency = this.owner;
        transaction = transaction || dependency.graph.activeTransaction;
        const { entries } = transaction, 
        // dependency identifiers to check
        { fromEvent, toEvent, lag, lagUnit, type } = dependency.$, fromEventQuark = entries.get(fromEvent), toEventQuark = entries.get(toEvent), lagQuark = entries.get(lag), lagUnitQuark = entries.get(lagUnit), typeQuark = entries.get(type);
        // new or modified dependency
        return !transaction.baseRevision.hasIdentifier(dependency.$$) ||
            fromEventQuark && !fromEventQuark.isShadow() ||
            toEventQuark && !toEventQuark.isShadow() ||
            lagQuark && !lagQuark.isShadow() ||
            lagUnitQuark && !lagUnitQuark.isShadow() ||
            typeQuark && !typeQuark.isShadow();
    }
    /**
     * Returns the interval resolution options.
     * There are two resolutions:
     * - [[RemoveDependencyResolution|removing the dependency]]
     * - [[DeactivateDependencyResolution|deactivating the dependency]].
     */
    getResolutions() {
        return this.resolutions || (this.resolutions = [
            this.deactivateDependencyConflictResolutionClass.new({ dependency: this.owner }),
            this.removeDependencyConflictResolutionClass.new({ dependency: this.owner })
        ]);
    }
}
__decorate([
    prototypeValue(RemoveDependencyResolution)
], DependencyConstraintInterval.prototype, "removeDependencyConflictResolutionClass", void 0);
__decorate([
    prototypeValue(DeactivateDependencyResolution)
], DependencyConstraintInterval.prototype, "deactivateDependencyConflictResolutionClass", void 0);
__decorate([
    prototypeValue(DependencyConstraintIntervalDescription)
], DependencyConstraintInterval.prototype, "descriptionBuilderClass", void 0);
